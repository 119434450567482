/* eslint-disable no-shadow */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { IntegrationService } from "../../services";
import { ApplicationFactory } from "../../factories/ApplicationFactory";
import { IIntegration } from "../../models";
import { PARAM_APPLICATION_ID, asrConfigState } from "../../app.enums";

import {
  ActivityIndicatorService,
  AppConfigService,
  debugError,
  ThemeService,
  debug,
  IHook,
  copyTextToClipboard,
} from "../../../core-ui";
import {
  IApplication,
  IApplicationConfig,
  IApplicationDTO,
  IGenesysApplicationConfig,
} from "../../../core-ui/models";
import {
  ApplicationTypeEnum,
} from "../../../core-ui/core-ui.enums";
import { ApplicationService } from "../../../core-ui/services/application.service";
import { IUser, IUserDetails } from "../../../core-ui/models/IUser";
import { UserService } from "../../../core-ui/services/user.service";
import { NotificationService } from "../../../core-ui/services/notification.service";

enum wizardSteps {
  recorder = "recorder",
  application = "application",
  configuration = "configuration",
  integration = "integration",
  interactionWidget = "interaction-widget",
  finish = "finish",
  schedule = "schedule",
  members = "members",
  snapshots = "snapshots",
  credentials = "credentials",
  disclaimer = "disclaimer",
  speechToText = "speechToText",
  SpeechTranslate = "speechTranslate",
  security = "security",
  videoQuality = "videoQuality",
}

enum wizardItemState {
  idle,
  active,
  visited,
}

interface IWizardStep {
  key: wizardSteps;
  state: wizardItemState;
}

@Component({
  selector: "app-page-setup",
  templateUrl: "./setup.component.html",
  styleUrls: ["./setup.component.scss"],
})
export class SetupPageComponent implements OnInit, OnDestroy {
  private _loading: boolean;
  installing: boolean;
  installationComplete: boolean;

  recorderError: string;
  apps: IIntegration[] = [];

  appToInstall: IIntegration;
  applicationType: ApplicationTypeEnum;

  userDetails: IUserDetails;
  user: IUser;

  applicationConfig: IApplicationConfig;

  steps: IWizardStep[] = [];

  // all integration specific components can register post setup scripts to be run after installation.
  postSetupHooks: IHook[];

  asrConfigState: asrConfigState = asrConfigState.input;
  isAdmin = false;

  constructor(
    private floatingService: ActivityIndicatorService,
    private router: Router,
    private config: AppConfigService,
    private applicationService: ApplicationService,
    private applicationFactory: ApplicationFactory,
    private alertService: NotificationService,
    private themeService: ThemeService,
    private integrationService: IntegrationService,
    private userService: UserService,
    private notification: NotificationService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.userDetails = this.userService.getUserDetails();
    this.user = this.userService.getActiveUser();
    this.isAdmin = this.userService.isAdmin;
    this.postSetupHooks = [];
    try {
      this.applicationConfig = this.config.getApplicationConfig();
      this.applicationType = this.application.getType();

      const allApps = await this.integrationService.getIntegrations();
      this.apps = allApps?.filter(
        (a) =>
          a.isEnabled() &&
          a.getIdentifier() ===
            this.integrationService.getIntegrationIdentifier()
      );

      this.appToInstall =
        (this.nonSetupInstances.length > 0 && this.nonSetupInstances[0]) ||
        (this.apps.length > 0 && this.apps[0]);

      // decide what app to configure
      if (
        (this.apps.length > 1 || !this.appToInstall) &&
        !this.isApplicationSetup
      ) {
        this.steps.push({
          key: wizardSteps.integration,
          state: wizardItemState.idle,
        });
      }

      // const state = this.isApplicationSetup
      //   ? wizardItemState.visited
      //   : wizardItemState.idle;
      /*     */
      // add recorder setup
      // if (this.config.featureEnabled(FEATURES.RECORDER)) {
      //   this.steps.push({ key: wizardSteps.recorder, state });
      // }

      // add configuration setup
      // this.steps.push({ key: wizardSteps.configuration, state });

      // if (
      //   this.integrationService.supportsInteractionDestination(
      //     ConversationDestinationEnum.INTERACTION_WIDGET
      //   )
      // ) {
      //   // add interation widget setup
      //   this.steps.push({
      //     key: wizardSteps.interactionWidget,
      //     state,
      //   });
      // }

      if (!this.isApplicationSetup) {
        this.steps.push({
          key: wizardSteps.finish,
          state: wizardItemState.idle,
        });
      }

      if (this.steps.length > 0) {
        this.steps[0].state = wizardItemState.active;
      } else {
        // no setup options available. send back to welcome.
        this.router.navigate(["/", "welcome"], {
          queryParamsHandling: "preserve",
        });
      }
      // }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      let errorMessage;
      switch (error.status) {
        case 403:
          errorMessage =
            "You are not authorized to setup the application. Please contact your administrator.";
          break;
        default:
          errorMessage = error.message || error;
          break;
      }
      this.alertService.error("Error", { body: errorMessage });
    }
  }

  ngOnDestroy() {
    this.themeService.parseApplicationOptions(this.application);
  }

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private get isApplicationSetup(): boolean {
    return !!this.application.getId();
  }

  get name() {
    return this.userDetails?.name;
  }

  get nonSetupInstances() {
    return this.apps?.filter(
      (a) => !this.integrationService.isInstalled(a) && a.isEnabled()
    );
  }

  async install() {
    this.installing = true;
    this.floatingService.loading(true, "installing...");
    try {
      await this.createUpdateApplication(this.applicationConfig);
      await this.integrationService.installIntegration(this.appToInstall);

      if (this.postSetupHooks.length > 0) {
        const promises = [];
        this.postSetupHooks.forEach((hook) => {
          promises.push(hook.run());
        });
        const results = await Promise.all(promises);
        debug(results);
      }

      this.installationComplete = true;
    } catch (ex) {
      if (!!ex.response) {
        switch (ex.response.status) {
          case 403:
            debugError("not allowed");
            break;
        }
      }
      const errorMessage = ex.message || (ex.body && ex.body.message) || ex;
      this.alertService.error("Error", { body: errorMessage });
    } finally {
      this.installing = false;
      this.floatingService.loading(false);
    }
  }

  public get loading(): boolean {
    return this._loading;
  }

  public set loading(value: boolean) {
    this._loading = value;
    this.floatingService.loading(value);
  }

  public get queryParams() {
    return !!this.appToInstall
      ? { [PARAM_APPLICATION_ID]: this.application.getId() }
      : null;
  }

  get isSettingsMode() {
    return this.isApplicationSetup;
  }

  get wizardItemStateIdle() {
    return wizardItemState.idle;
  }
  get wizardItemStateActive() {
    return wizardItemState.active;
  }
  get wizardItemStateVisited() {
    return wizardItemState.visited;
  }

  async createUpdateApplication(config: IApplicationConfig): Promise<string> {
    try {
      this.floatingService.loading(true);
      if (!this.isApplicationSetup) {
        switch (this.applicationType) {
          case ApplicationTypeEnum.Genesys:
            config = {
              ...config,
              integrationId: this.appToInstall.getId(),
            } as IGenesysApplicationConfig;
            break;
        }
        const response = await this.applicationService.create(
          this.applicationType,
          config
        );
        const applicationDto: IApplicationDTO =
          await this.applicationService.get(response.id);
        const application =
          this.applicationFactory.createAgentApplicationFromDto(applicationDto);
        this.applicationService.setActiveApplication(application);

        this.refreshApplicationOptions();

        return application.getId();
      } else {
        await this.applicationService.update(
          this.application.getId(),
          this.applicationType,
          config
        );
        this.refreshApplicationOptions();
        return this.application.getId();
      }
    } catch (ex) {
      debugError(ex);
      this.alertService.error("Error", {
        body: "Could not store configuration. Please try again.",
      });
      throw ex;
    } finally {
      this.floatingService.loading(false);
    }
  }

  /*
  applicationOptionsReady(options: IApplicationConfig, wizardIndex) {
    this.applicationConfig = {
      ...this.applicationConfig,
      ...options,
    };
    this.nextStep(wizardIndex);
  }

  async applicationOptionsUpdate(
    options: IApplicationConfig
  ): Promise<boolean> {
    const config = {
      ...this.applicationConfig,
      ...options,
    };
    try {
      this.floatingService.loading(true);
      await this.applicationService.update(
        this.application.getId(),
        this.applicationType,
        config
      );
      this.alertService.success("Saved");
      this.refreshApplicationOptions();
      return true;
    } catch (ex) {
      const errorMessage = ex.message || (ex.body && ex.body.message) || ex;
      this.alertService.error("Error", { body: errorMessage });
      return false;
    } finally {
      this.floatingService.loading(false);
    }
  }
*/

  integrationOptionsReady(wizardIndex) {
    if (!this.appToInstall) {
      return;
    }
    this.nextStep(wizardIndex);
  }

  nextStep(wizardIndex) {
    this.steps[wizardIndex].state = wizardItemState.visited;
    this.steps[wizardIndex + 1].state = wizardItemState.active;
  }

  backStep(wizardIndex) {
    this.steps[wizardIndex].state = wizardItemState.visited;
    this.steps[wizardIndex - 1].state = wizardItemState.active;
  }

  exit() {
    this.router.navigate(["/welcome"], { queryParamsHandling: "preserve" });
  }

  select(step: IWizardStep) {
    if (step.state === wizardItemState.idle) {
      return;
    }
    this.steps.forEach((s) => {
      if (s.state === wizardItemState.active && s.key !== step.key) {
        s.state = wizardItemState.visited;
      }
    });
    step.state =
      step.state === wizardItemState.active
        ? wizardItemState.visited
        : wizardItemState.active;
  }

  copyToClipboard() {
    copyTextToClipboard(this.applicationId);
    this.notification.success("Copied to clipboard");
  }

  private async refreshApplicationOptions() {
    // update application options
    const application = await this.applicationFactory.createAgentApplication(
      this.application.getId()
    );
    this.applicationService.setActiveApplication(application);
    this.themeService.parseApplicationOptions(application);
    this.applicationConfig = application.getConfig();
  }

  registerPostSetupHook(sender: IHook) {
    this.postSetupHooks.push(sender);
  }

  get applicationId() {
    return this.applicationService.getActiveApplication().getId();
  }

  get isApplicationAvailable() {
    return !!this.applicationService.getActiveApplication().getId();
  }
}
