<av-menu-item
  aria-label="Send a qr code to customer"
  icon="barcode"
  [disabled]="!thereIsACustomerWhichSupportsQRCodeLink"
  info="The customer must be connected and not on a mobile device."
  (select)="shareLink()"
  data-tid="conference/send-qr"
>
  <span translate>Send QR code</span>
</av-menu-item>
