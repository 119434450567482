import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { ISyncable } from "./ISyncable";
import { ErrorPageCode } from "../../app.enums";
import { ApplicationFactory } from "../../factories/ApplicationFactory";

import {
  GenericErrorHandler,
  ActivityIndicatorService,
  UserRoleEnum,
  ApplicationTypeEnum,
  IUserDetails,
  CLAIM_NAME,
  CLAIM_PICTURE,
  CLAIM_EMAIL,
  AppConfigService,
  PublicParam,
  DeviceService,
} from "../../../core-ui";
import { UserService } from "../../../core-ui/services/user.service";
import { AuthenticationService } from "../../../core-ui/services/authentication.service";
import { ApplicationService } from "../../../core-ui/services/application.service";

@Injectable()
export class AuthAgentGuard  implements ISyncable {
  constructor(
    private router: Router,
    private errorHandler: GenericErrorHandler,
    private floatingService: ActivityIndicatorService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private applicationService: ApplicationService,
    private applicationFactory: ApplicationFactory,
    private config: AppConfigService,
    private device: DeviceService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.userService.isAuthenticated()) {
      try {
        const message =
          this.config.publicParam(PublicParam.POPUP_AUTHENTICATION_ENABLED) &&
          !this.device.isPopUp
            ? "Authenticating... Please allow popups"
            : "authenticating";

        this.floatingService.loading(true, message, true);

        const user = await this.authenticationService.authenticate(route);

        if (!user.hasRole(UserRoleEnum.agent)) {
          throw new Error("user is not an agent");
        }

        this.userService.setActiveUser(user);

        // refresh application now that we have a jwt token
        this.applicationService.createApiResource();
        const application =
          await this.applicationFactory.createAgentApplication(
            this.applicationService.getActiveApplication().getId(),
            route
          );
        await this.applicationService.setActiveApplication(application);

        // todo: do it better. on genesys it is set on GenesysCloudApplication / afterAuth.
        if (application.getType() === ApplicationTypeEnum.StandaloneOpenID) {
          const details: IUserDetails = {
            id: user.getId(),
            name: user.getClaim(CLAIM_NAME),
            avatarUrl: user.getClaim(CLAIM_PICTURE),
            email: user.getClaim(CLAIM_EMAIL),
          };
          this.userService.setUserDetails(details);
        }
      } catch (error) {
        this.errorHandler.handleError(error);
        let message = error.message;
        if (error.status === 401) {
          message = "Please reload the page to try again.";
        }
        return this.router.createUrlTree(
          ["/error", ErrorPageCode.AUTHENTICATION_FAILURE],
          {
            queryParams: { message },
            queryParamsHandling: "merge",
          }
        );
      } finally {
        this.floatingService.loading(false);
      }
    }
    return true;
  }
}
