

<div class="tile-info-group" *ngIf="isGroupOneAvailable()">
  <div class="tile-info" *ngIf="isDebugRtc">
    <av-tooltip position="bottom">
      rtc
      <div class="debug" tooltip-message>
        <div>id: {{ stream.id }}</div>
        <div>username: {{ stream.originator.username }}</div>
        <div>my username: {{ myself.username }}</div>
        <div>endpoint: {{ stream.originator.endpoint }}</div>
        <div *ngIf="!!stream.mediaStream">
          media stream: {{ stream.mediaStream.id }}
        </div>
        <div>local: {{ isLocal }}</div>
        <div>type: {{ stream.type }}</div>
        <!-- <div>cam off: {{isMutedStream(stream, kindVideo)}}</div> -->
        <!-- <div>mic off: {{isMutedStream(stream, kindAudio)}}</div> -->
        <div>
          tag muted: {{ stream.originator.endpoint === myself.endpoint }}
        </div>
      </div>
    </av-tooltip>
  </div>

  <ng-container *ngIf="!isScreen">
    <div
      aria-label="Participant is agent"
      class="tile-info"
      [class.hidden]="isHidden"
      *ngIf="isRoleAgentVisible"
      data-tid="tile-info/role-agent"
    >
      <av-tooltip position="bottom" message="AGENT">
        <av-icon class="tile-icon" mode="light" name="headset"></av-icon>
      </av-tooltip>
    </div>

    <div
      class="tile-info"
      [class.hidden]="isHidden"
      *ngIf="isNetworkIndicatorVisible"
    >
      <app-network-indicator
        [endpoint]="stream.originator.endpoint"
      ></app-network-indicator>
    </div>

    <div
        class="tile-info"
        aria-label="Participant's microphone is muted"
        *ngIf="isMutedMic()"
        data-tid="tile-info/mic-muted"
      >
        <av-icon class="tile-icon" mode="light" name="mic-mute"></av-icon>
      </div>

    <div
      class="tile-info"
      aria-label="Participant has enabled closed captions"
      [class.hidden]="isHidden"
      *ngIf="isCaptionsOn"
      data-tid="tile-info/captions-on"
    >
      <av-icon class="tile-icon" mode="light" name="cc"></av-icon>
    </div>

    <div
      class="tile-info"
      [attr.aria-label]="'Participant\'s marker color is ' + color"
      *ngIf="color && isPointerActive"
      data-tid="tile-info/pointer-color"
    >
      <av-tooltip message="Pointer color" position="bottom">
        <div class="point point-color-{{ color }}"></div>
      </av-tooltip>
    </div>

    <div
      [attr.aria-label]="'Participant\'s language is ' + participantLanguage"
      class="tile-info tile-info-lang"
      [class.hidden]="isHidden"
      *ngIf="isParticipantLanguageEnabled"
      data-tid="tile-info/user-language"
    >
      <av-tooltip position="bottom">
        {{ participantLanguage }}
        <ng-container tooltip-message>
          {{ "Customer's language is" | translate }}
          {{ participantLanguage | translate }}
        </ng-container>
      </av-tooltip>
    </div>
  </ng-container>

  <div role="group" class="tile-info" *ngIf="isViewerDisabled">
    <av-tooltip position="bottom">
      <div class="fx-row fx-align-center">
        <div class="warning">!</div>
        <small
          class="warning-label tile-info-tiny-hidden tile-info-pointer-hidden"
          translate
          >Poor Connection</small
        >
      </div>
      <ng-container tooltip-message>
        <small [innerHTML]="'The video will resume automatically' | translate">
        </small>
      </ng-container>
    </av-tooltip>
  </div>
</div>
<!-- </ng-container> -->

<ng-container *ngIf="!isScreen && snapshotCount > 0">
  <div
    class="tile-info-group"
    [attr.aria-label]="'Number of snapshots taken is ' + snapshotCount"
    [class.hidden]="isHidden"
    data-tid="tile-info/snapshot-count"
  >
    <av-tooltip position="bottom">
      <div (click)="snapshotRequest()" class="tile-info tile-info-action">
        <!-- {{snapshotCount}} &times; -->
        <av-icon
          class="tile-icon tile-icon-snapshot"
          mode="light"
          name="camera"
        ></av-icon>
      </div>
      <!-- <ng-container tooltip-message> -->
      <ng-container tooltip-message *ngIf="snapshotCount === 1">
        {{ "snapshot taken" | translate }}
      </ng-container>
      <ng-container tooltip-message *ngIf="snapshotCount > 1">
        {{ "snapshots taken" | translate }}
      </ng-container>
      <!-- </ng-container> -->
    </av-tooltip>
  </div>
</ng-container>
