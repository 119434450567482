<av-tooltip [message]="tooltipLabel" [position]="tooltipPosition">
  <button
    aria-label="Copy download link to clipboard"
    avButton
    ofType="icon"
    color="link"
    [size]="size"
    (click)="copy()"
  >
    <av-icon name="copy-document-thin"></av-icon>
  </button>
</av-tooltip>
