import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IInteraction, NotificationService } from "../../../../core-ui";
import { InteractionService } from "../../../services";
import { GenericErrorHandler } from "../../../services/error-handlers.service";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsService } from "../../../../core-ui/services/analytics.service";
import { IShareLinkAction } from "../share-link.component";

@Component({
  selector: "app-share-link-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ShareLinkChatComponent implements OnInit, IShareLinkAction {
  @Input() interaction: IInteraction;
  @Input() link: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private interactionService: InteractionService,
    private analyticsService: AnalyticsService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {}

  shareLink() {
    try {
      this.interactionService.postMessageToInteraction(
        this.interaction,
        this.link
      );
      this.analyticsService.trackInvitationSent(this.interaction);
      this.notification.show("auvious", {
        body: this.translate.instant("Link sent to chat."),
      });
      // this.renewCustomerLink();
      this.completed.emit();

      // this.shareOpen = false;
      this.openChange.emit(false);
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }
}
