import { Injectable, NgZone } from "@angular/core";
import { Browsers } from "@auvious/compatibility";
import {
  IDevice,
  IMediaDevicesEvents,
  IMediaPipeEvents,
  ISinkEffectEvents,
  MediaDevices,
  MediaPipe,
} from "@auvious/media-tools";
import { TranslateService } from "@ngx-translate/core";
import { ReplaySubject, Subject } from "rxjs";
import { IPublishedStream, IStream, Metadata, StreamTypes } from "@auvious/rtc";
import { PublishStream } from "@auvious/rtc/dist/reduxImpl/conference/publish/index";

import { StreamErrorEnum, StreamTrackKindEnum } from "../core-ui.enums";
import { PublicParam } from "../models";
import {
  IStreamMetadata,
  IStreamMuteChangeEvent,
  IStreamMuteWillChangeEvent,
  ITrackMetadata,
} from "../models/interfaces";
import { AppConfigService } from "./app.config.service";
import { DeviceService } from "./device.service";
import { GenericErrorHandler } from "./error-handlers.service";
import { NotificationService } from "./notification.service";
import { AuviousRtcService } from "./rtc.service";
import { UserService } from "./user.service";
import { debug, debugError } from "./utils";
import {
  DeviceCaptureErrorAction,
  WindowEventService,
} from "./window.event.service";

export interface ILocalStream {
  stream: IPublishedStream;
  facingMode: VideoFacingModeEnum;
}

@Injectable()
export class LocalMediaService {
  public pipe: MediaPipe;

  /** control whether to change stream type when un/muting, unless necessary like in renegotiation */
  public freezeStreamType = false;

  // media devices setup
  public settingUp: Promise<void>;

  public mainStream: ILocalStream = {
    stream: null,
    facingMode: "user",
  };

  private previousPublishedType: StreamTypes;

  public screenStream: ILocalStream = {
    stream: null,
    facingMode: "environment",
  };

  private _localStreamReady = new Subject<IStream>();
  public localStreamReady$ = this._localStreamReady.asObservable();

  private _localStreamReplaced = new Subject<{
    previous: IPublishedStream;
    next: IPublishedStream;
  }>();
  public localStreamReplaced$ = this._localStreamReplaced.asObservable();

  private _streamRemoved = new Subject<IStream>();
  public streamRemoved$ = this._streamRemoved.asObservable();

  private _streamMuteWillChange = new Subject<IStreamMuteWillChangeEvent>();
  public streamMuteWillChange$ = this._streamMuteWillChange.asObservable();

  private _streamMuteChange = new Subject<IStreamMuteChangeEvent>();
  public streamMutedChange$ = this._streamMuteChange.asObservable();

  private _addedDevices = new ReplaySubject<IMediaDevicesEvents["added"]>(1);
  public addedDevices$ = this._addedDevices.asObservable();

  private _removedDevices = new Subject<IMediaDevicesEvents["removed"]>();
  public removedDevices$ = this._removedDevices.asObservable();

  private _updatedDevices = new Subject<IMediaDevicesEvents["updated"]>();
  public updatedDevices$ = this._updatedDevices.asObservable();

  private _permissions = new ReplaySubject<IMediaDevicesEvents["permissions"]>(
    1
  );
  public permissions$ = this._permissions.asObservable();

  private _mediaStream = new Subject<IMediaPipeEvents["output"]>();
  public mediaStream$ = this._mediaStream.asObservable();

  private _tracksChanged = new Subject<ISinkEffectEvents["tracks"]>();
  public tracksChanged$ = this._tracksChanged.asObservable();

  private _speakerChanged = new Subject();
  public speakerChanged$ = this._speakerChanged.asObservable();

  private _activeDeviceChanged = new Subject<MediaDeviceKind>();
  public activeDeviceChanged$ = this._activeDeviceChanged.asObservable();

  private _streamError = new Subject<{
    stream: IPublishedStream;
    error: Error;
  }>();
  public streamError$ = this._streamError.asObservable();

  constructor(
    private translate: TranslateService,
    private rtc: AuviousRtcService,
    private errorHandler: GenericErrorHandler,
    private alert: NotificationService,
    private windowEvents: WindowEventService,
    private config: AppConfigService,
    // private mediaRules: MediaRulesService,
    private user: UserService,
    private zone: NgZone
  ) {
    this.settingUp = MediaDevices.setup({
      added: (event) => this._addedDevices.next(event),
      removed: (event) => this._removedDevices.next(event),
      updated: (event) => this._updatedDevices.next(event),
      permissions: (event) => {
        // if camera or audio permission is removed, one or both tracks will end
        // if explicitly denied or there is a mismatch between input and uploaded tracks
        // then mute the standard way

        this._permissions.next(event);

        if (this.mainStream) {
          if (
            event.audioinput === false ||
            (this.isTrackMuted(this.pipe.streamIn?.getAudioTracks()[0]) &&
              !this.mainStream.stream.isMuted("audio"))
          ) {
            this.mute(StreamTrackKindEnum.audio);
          }

          if (
            event.videoinput === false ||
            (this.isTrackMuted(this.pipe.streamIn?.getVideoTracks()[0]) &&
              !this.mainStream.stream.isMuted("video"))
          ) {
            this.mute(StreamTrackKindEnum.video);
          }
        }
      },
    });

    this.reset();

    // @ts-expect-error
    window.devs = MediaDevices;
  }

  public hasLiveInput(track: StreamTrackKindEnum) {
    return track === StreamTrackKindEnum.audio
      ? this.pipe?.streamIn?.getAudioTracks()[0]?.readyState === "live"
      : this.pipe?.streamIn?.getVideoTracks()[0]?.readyState === "live";
  }

  public reset() {
    this.previousPublishedType = "";

    this.pipe?.reset();

    this.pipe = new MediaPipe();

    // @ts-expect-error
    window.pipe = this.pipe;

    this.pipe.events.subscribe({
      output: (stream) => {
        if (!(this.mainStream.stream instanceof PublishStream))
          this.mainStream.stream.mediaStream = stream;

        this._mediaStream.next(stream);
      },
    });

    this.pipe.sink.events.on("tracks", async (event) => {
      this._tracksChanged.next(event);
      // either one null is handled by un/mute
      if (event.previous && event.current) {
        try {
          await this.mainStream.stream?.replace(event.previous, event.current);

          if (
            MediaDevices.getByLabel(
              event.current.label,
              event.kind === "audio" ? "audioinput" : "videoinput"
            ) &&
            event.current.label !== event.previous.label
          ) {
            this.mainStream.stream?.updateMetadata({
              ...this.mainStream.stream.getMetadata(),
              ...this.getStreamMetadata(this.pipe.streamIn),
            });
          }
        } catch (ex) {
          if (
            ex instanceof DOMException &&
            ex.name === "InvalidModificationError"
          ) {
            this._streamError.next({
              stream: this.mainStream.stream,
              error: ex,
            });
          }
        }
      }
    });
  }

  /** replace dummy stream with published one */
  public replaceStream(stream: IPublishedStream): IPublishedStream {
    let previous: IPublishedStream;

    if (stream.type === StreamTypes.SCREEN) {
      previous = this.screenStream.stream;
      this.screenStream.stream = stream;
    } else {
      this.previousPublishedType = stream?.type;
      previous = this.mainStream.stream;
      this.mainStream.stream = stream;

      // try {
      //   const audio = this.pipe.streamIn.getAudioTracks()[0];
      //   const audioMuted = this.isTrackMuted(audio);

      //   if (audioMuted !== stream.isMuted("audio")) {
      //     audioMuted ? stream.mute("audio") : stream.unmute("audio", audio);
      //   }

      //   const video = this.pipe.streamIn.getVideoTracks()[0];
      //   const videoMuted = this.isTrackMuted(video);

      //   if (videoMuted !== stream.isMuted("video")) {
      //     videoMuted ? stream.mute("video") : stream.unmute("video", video);
      //   }
      // } catch (ex) {
      //   debugError(stream, previous);
      //   debugError(ex);
      // }
    }

    if (previous !== stream) {
      this._localStreamReplaced.next({
        previous,
        next: stream,
      });
    }

    return previous;
  }

  public async mute(kind: StreamTrackKindEnum) {
    const tracks =
      (kind === StreamTrackKindEnum.audio
        ? this.pipe.streamIn?.getAudioTracks()
        : this.pipe.streamIn?.getVideoTracks()) || [];

    if (this.isTrackMuted(tracks[0])) {
      this._streamMuteChange.next({
        stream: this.mainStream?.stream,
        trackKind: kind,
        muted: true,
      });

      return;
    }

    this._streamMuteWillChange.next({
      stream: this.mainStream?.stream,
      trackKind: kind,
      mute: true,
    });

    try {
      await this.mainStream?.stream.mute(kind);
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }

    tracks.forEach((t) => t.stop());

    this._streamMuteChange.next({
      stream: this.mainStream?.stream,
      trackKind: kind,
      muted: true,
    });
  }

  public async unmute(kind: StreamTrackKindEnum) {
    if (
      !this.isTrackMuted(
        this.pipe.streamIn?.getTracks().filter((t) => t.kind === kind)[0]
      )
    ) {
      return;
    }

    const sendMuteEvents = !!this.mainStream.stream;

    if (sendMuteEvents)
      this._streamMuteWillChange.next({
        stream: this.mainStream.stream,
        trackKind: kind,
        mute: false,
      });

    const lastDevices: MediaStreamConstraints = {};

    if (this.pipe.input.lastDevices.audioinput?.deviceId) {
      lastDevices.audio = {
        deviceId: this.pipe.input.lastDevices.audioinput.deviceId,
      };
    }

    if (this.pipe.input.lastDevices.videoinput?.deviceId) {
      lastDevices.video = {
        deviceId: this.pipe.input.lastDevices.videoinput.deviceId,
      };
    }

    let track: MediaStreamTrack;

    try {
      await this.openStream(
        true,
        { [kind]: true },
        this.getDeviceConstraints(
          this.pipe.input.lastDevices.audioinput,
          this.pipe.input.lastDevices.videoinput
        ),
        ...Object.values(MediaDevices.constraints),
        // DeviceService.isMobile
        //   ? {
        //       video: {
        //         facingMode:
        //           this.pipe.input.facingMode || this.mediaRules.defaultFacingMode,
        //       },
        //     }
        //   : {},
        MediaDevices.preferredConstraints,
        lastDevices
      );

      // streamOut might be null, if no sink element has yet to be set
      let mediaStream = this.pipe.streamOut;
      track = mediaStream?.getTracks().filter((t) => t.kind === kind)[0];

      if (!track) {
        mediaStream = this.pipe.streamIn;
        track = mediaStream.getTracks().filter((t) => t.kind === kind)[0];
      }

      if (track) {
        // webrtc "renegotation" by closing and opening new stream
        if (!this.freezeStreamType || this.shouldChangeStreamType(kind)) {
          this.closeMainStream(true);
          this.setMainStream(mediaStream);
        } else {
          await this.mainStream.stream.unmute(kind, track);
        }
      }
    } catch (error) {
      debugError(error);
      throw error;
    } finally {
      if (sendMuteEvents)
        this._streamMuteChange.next({
          stream: this.mainStream.stream,
          trackKind: kind,
          muted: !track,
        });
    }
  }

  private getFacingMode() {
    return this.pipe?.input.facingMode || "user";
  }

  private shouldChangeStreamType(kind: StreamTrackKindEnum) {
    return (
      !this.mainStream.stream ||
      (kind === StreamTrackKindEnum.audio &&
        this.mainStream.stream.type === StreamTypes.CAM) ||
      (kind === StreamTrackKindEnum.video &&
        this.mainStream.stream.type === StreamTypes.MIC)
    );
  }

  async openDeviceStream(kind: MediaDeviceKind, id: string) {
    if (kind === "audiooutput") {
      await this.setSpeaker(id);
    } else {
      const trackKind =
        kind === "audioinput"
          ? StreamTrackKindEnum.audio
          : StreamTrackKindEnum.video;

      await this.openStream(
        true,
        { [trackKind]: true },
        this.getDeviceConstraints(
          MediaDevices.getById(id, "audioinput"),
          MediaDevices.getById(id, "videoinput")
        ),
        ...Object.values(MediaDevices.constraints)
      );

      const track = this.pipe.streamIn
        .getTracks()
        .filter((t) => t.kind === trackKind)[0];

      if (track && this.shouldChangeStreamType(trackKind)) {
        this.closeMainStream(true);
        this.setMainStream(this.pipe.streamOut);
      }

      this.mainStream.facingMode = this.getFacingMode();
      this._activeDeviceChanged.next(kind);
    }
  }

  /** swith to a second camera or , if more available, pick one with a different facing mode */
  async switchCamera() {
    let switchConstraints: MediaStreamConstraints;

    // safari in iOS 12.x doesn't support deviceId.
    // if we have more than 2 (1 front, 1 back) cameras, we need to use facingMode
    if (
      MediaDevices.has.videoinput > 2 ||
      (DeviceService.isiOS &&
        DeviceService.info.browser.version.startsWith("12"))
    ) {
      switchConstraints = {
        video: {
          facingMode:
            this.pipe.input.facingMode === "user" ? "environment" : "user",
        },
      };
    } else if (MediaDevices.has.videoinput === 2) {
      const useDevice = MediaDevices.getDeviceList("videoinput").find(
        (device) => device.deviceId !== this.videoDevice?.deviceId
      );

      switchConstraints = {
        video: {
          deviceId: useDevice.deviceId,
        },
      };
    } else {
      throw new Error(StreamErrorEnum.NoSuitableCameraFound);
    }

    await this.openStream(
      true,
      { video: true },
      switchConstraints,
      ...Object.values(MediaDevices.constraints)
    ).catch((ex) => {
      // try to recover video track from last used device
      if (
        (this.mainStream.stream.type === StreamTypes.CAM ||
          this.mainStream.stream.type === StreamTypes.VIDEO) &&
        this.pipe.streamIn?.getVideoTracks()[0]?.readyState !== "live" &&
        this.pipe.input.lastDevices.videoinput
      ) {
        return this.openStream(
          true,
          { video: true },
          this.getDeviceConstraints(
            MediaDevices.getById(
              this.pipe.input.lastDevices.videoinput.deviceId,
              "videoinput"
            )
          ),
          ...Object.values(MediaDevices.constraints)
        );
      } else {
        throw ex;
      }
    });

    const track = this.pipe.streamIn.getVideoTracks()[0];

    if (track && this.shouldChangeStreamType(StreamTrackKindEnum.video)) {
      this.closeMainStream(true);
      this.setMainStream(this.pipe.streamOut);
    }

    this.mainStream.facingMode = this.getFacingMode();
    this._activeDeviceChanged.next("videoinput");
  }

  public async setSpeaker(id: string | null) {
    await MediaDevices.setSpeaker(id);
    this._speakerChanged.next(id);
    this._activeDeviceChanged.next("audiooutput");
  }

  public getStreamMetadata(mediaStream: MediaStream): IStreamMetadata {
    const videoTrack = mediaStream?.getVideoTracks()[0];
    const audioTrack = mediaStream?.getAudioTracks()[0];

    return {
      video: videoTrack ? this.getTrackMetadata(videoTrack) : null,
      audio: audioTrack ? this.getTrackMetadata(audioTrack) : null,
    };
  }

  public getTrackMetadata(track: MediaStreamTrack): ITrackMetadata {
    try {
      return {
        id: track.id,
        settings: track.getSettings?.(),
        capabilities: track.getCapabilities?.(),
        constraints: track.getConstraints?.(),
      };
    } catch (ex) {
      debugError(ex);
      return {
        id: "",
        settings: null,
        capabilities: null,
        constraints: null,
      };
    }
  }

  public async openStream(
    merge: boolean,
    ...constraints: MediaStreamConstraints[]
  ) {
    debug("openStream with", constraints);
    try {
      if (this.config.publicParam(PublicParam.HD_VIDEO_ENABLED) === false) {
        constraints = [
          ...constraints,
          {
            video: {
              width: undefined,
              height: undefined,
              frameRate: undefined,
            },
          },
        ];
      }
      return await this.zone.runOutsideAngular(() =>
        this.pipe.input.setInput({ merge }, ...constraints)
      );
    } catch (ex) {
      if (
        !(
          DeviceService.isSafari &&
          ex instanceof OverconstrainedError &&
          this.pipe.input.constraints.video
        )
      ) {
        if (
          ex.name === "NotFoundError" &&
          constraints[0].video === false &&
          constraints[0].audio === false
        ) {
          this.alert.error("need camera and mic to connect");
        }

        throw ex;
      }
    }

    // fallback to audio only
    if (constraints[0].video && constraints[0].audio) {
      this.alert.info(this.translate.instant("camera not found"));

      return this.zone.runOutsideAngular(() =>
        this.pipe.input.setInput({ merge }, { video: false, audio: true })
      );
    } else if (!constraints[0].video) {
      // audio not found, inform about error
      this.alert.error(
        this.translate.instant("need camera and mic to connect")
      );

      throw new DOMException("No audio input was found", "NotFoundError");
    }
  }

  public async shareScreen(): Promise<{
    success?: boolean;
    error?: boolean;
    reject?: boolean;
  }> {
    try {
      const stream = await this.zone.runOutsideAngular(() =>
        this.openDisplayStream({
          video: { frameRate: 10 },
        })
      );

      this.setScreenStream(stream);
      return { success: true };
    } catch (ex) {
      let message = ex.message;
      let alert = true;
      let log = false;
      const response = { error: false, reject: false };

      switch (ex?.name) {
        case "NotAllowedError":
          // same for chrome / edge
          if (message === "Permission denied by system") {
            message =
              "Please enable screen recording in your System Preferences";
            response.error = true;
          } else {
            alert = false;
            response.reject = true;
          }
          break;
        case "NotFoundError":
          // happens in Firefox / MacOS Catalina when the used hasn't given access in System Prerefences
          message = "Please enable screen recording in your System Preferences";
          response.error = true;
          break;
        case "InvalidStateError":
          // firefox: getDisplayMedia must be called from a user gesture handler.
          alert = false;
          log = true;
          response.error = true;
          break;
        default:
          log = true;
          response.error = true;
      }

      if (alert) {
        this.alert.error(this.translate.instant(message || ex), { ttl: 3000 });
      }

      if (log) {
        this.errorHandler.handleError(ex);
      }

      this.windowEvents.sendMessage(
        new DeviceCaptureErrorAction({ name: ex.name, message: ex.message })
      );

      return response;
    }
  }

  private async openDisplayStream(
    constraints: MediaStreamConstraints
  ): Promise<MediaStream> {
    try {
      if (DeviceService.isElectron) {
        const sourceId = await this.getSourceIdFromParent();

        return navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: "desktop",
              chromeMediaSourceId: sourceId,
            },
          } as MediaTrackConstraints,
        });
      } else if (navigator.mediaDevices.getDisplayMedia) {
        return navigator.mediaDevices.getDisplayMedia(constraints);
        // @ts-expect-error
      } else if (navigator.getDisplayMedia) {
        // @ts-expect-error
        return navigator.getDisplayMedia(constraints);
      } else {
        throw new Error("get-display-media-not-supported");
      }
    } catch (ex) {
      this.errorHandler.handleError(ex);
      throw ex;
    }
  }

  private getSourceIdFromParent(): Promise<string> {
    return new Promise((resolve, reject) => {
      const channel = new MessageChannel();

      channel.port1.onmessage = (event: MessageEvent<string>) => {
        if (event.data) {
          resolve(event.data);
        } else {
          reject(
            (event instanceof Error
              ? event
              : // @ts-expect-error
                event.error) || event
          );
        }
      };

      parent.postMessage("desktop-select-source-request", "*", [channel.port2]);
    });
  }

  public getDeviceConstraints(
    audioDevice?: IDevice,
    videoDevice?: IDevice
  ): MediaStreamConstraints {
    let audioConstraints: MediaStreamConstraints["audio"] = {};
    const videoConstraints: MediaStreamConstraints["video"] = {};

    switch (DeviceService.browser) {
      case Browsers.Firefox:
        audioConstraints = {
          echoCancellation: true,
          noiseSuppression: true,
        };

        if (audioDevice) {
          audioConstraints.deviceId = {
            exact: audioDevice.deviceId,
          };
        }

        if (videoDevice) {
          videoConstraints.deviceId = {
            exact: videoDevice.deviceId,
          };
        }

        break;
      case Browsers.Chromium:
      case Browsers.Safari:
        audioConstraints = {
          echoCancellation: true,
        };

        if (audioDevice) {
          audioConstraints.deviceId = audioDevice.deviceId;
        }

        if (videoDevice) {
          videoConstraints.deviceId = videoDevice.deviceId;
        }

        break;
    }

    return { audio: audioConstraints, video: videoConstraints };
  }

  public setMainStream(stream: MediaStream) {
    try {
      if (!stream) {
        return;
      }

      // [freezeStreamType] enforces the type of the output stream to be strictly dependent on the available media input
      // when it is false, we simply check whether the input is live
      const audio = stream.getAudioTracks()[0];
      const video = stream.getVideoTracks()[0];
      const enableVideo = this.freezeStreamType
        ? video?.readyState ===
          "live" /* || this.previousPublishedType === StreamTypes.VIDEO */
        : !this.isTrackMuted(video);
      const enableAudio = this.freezeStreamType
        ? audio?.readyState ===
          "live" /* || this.previousPublishedType === StreamTypes.MIC */
        : !this.isTrackMuted(audio);
      const type = enableVideo
        ? enableAudio
          ? StreamTypes.VIDEO
          : StreamTypes.CAM
        : StreamTypes.MIC;

      // enforce consistency
      if (!this.freezeStreamType) {
        if (type === StreamTypes.CAM && audio) {
          audio.stop();
          stream.removeTrack(audio);
        } else if (type === StreamTypes.MIC && video) {
          video.stop();
          stream.removeTrack(video);
        }
      }

      let abraMetadata: Metadata;
      const abra = this.config.publicParam(
        PublicParam.AUTOMATIC_BITRATE_ADAPTATION_PUBLISHER
      );

      if (abra) {
        abraMetadata = {
          "automatic-bandwidth-adaptation": abra.publisher
            ? "enabled"
            : "disabled",
          "automatic-bandwidth-adaptation.min-seconds-between-decrements": `${abra.minSecondsBetweenDecrements}`,
          "automatic-bandwidth-adaptation.min-seconds-between-increments": `${abra.minSecondsBetweenIncrements}`,
        };
      }

      const dummyStream: IPublishedStream = {
        type,
        id: stream.id,
        originator: {
          endpoint: this.rtc.endpoint(),
          username: this.rtc.identity(),
        },
        mediaStream: stream,
        mute: async (track: "audio" | "video") => {
          if (!this.freezeStreamType && !dummyStream.isMuted(track)) {
            this.pipe.streamIn
              ?.getTracks()
              .filter((t) => t.kind === track)
              .forEach((t) => t.stop());

            this.closeMainStream(true);
            this.setMainStream(this.pipe.streamIn);
          } else {
            this.pipe.streamIn
              ?.getTracks()
              .filter((t) => t.kind === track)
              .forEach((t) => (t.enabled = false));
          }
        },
        unmute: async (kind: "audio" | "video", track?: MediaStreamTrack) => {
          // handled by this.unmute by creating new and changing stream type
        },
        replace: async (previous, next) => {},
        updateMetadata: async () => {},
        updateVideoBitrate: async () => {},
        isMuted: (kind) =>
          (kind === StreamTrackKindEnum.video &&
            this.isTrackMuted(stream.getVideoTracks()[0])) ||
          (kind === StreamTrackKindEnum.audio &&
            this.isTrackMuted(stream.getAudioTracks()[0])),
        getMetadata: () => ({
          portraitMode:
            (type === "video" || type === "cam") &&
            this.config.publicParam(PublicParam.AGENT_PORTRAIT_MODE) &&
            this.user.isAgent,
          ...abraMetadata,
          ...this.getStreamMetadata(stream),
        }),
      };

      this.mainStream = {
        stream: dummyStream,
        facingMode: this.getFacingMode(),
      };

      this._localStreamReady.next(dummyStream);
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }

  public setScreenStream(stream: MediaStream) {
    try {
      const dummyStream: IPublishedStream = {
        type: StreamTypes.SCREEN,
        id: stream.id,
        originator: {
          endpoint: this.rtc.endpoint(),
          username: this.rtc.identity(),
        },
        mediaStream: stream,
        mute: async (track: "audio" | "video") => {},
        unmute: async (kind: "audio" | "video", track?: MediaStreamTrack) => {},
        replace: async () => {},
        updateMetadata: async () => {},
        updateVideoBitrate: async () => {},
        isMuted: (kind) =>
          kind === "audio"
            ? stream.getAudioTracks()[0]?.enabled
            : stream.getVideoTracks()[0]?.enabled,
        getMetadata: () => ({
          ...this.getStreamMetadata(stream),
          portraitMode: false,
          // used to lock screen share resource
          publishedAt: new Date().toISOString(),
        }),
      };

      this.screenStream = {
        stream: dummyStream,
        facingMode: "environment",
      };

      this.zone.runOutsideAngular(() =>
        stream.getVideoTracks()[0].addEventListener("ended", () => {
          debug("stream closed by user.");
          this.closeScreenStream();
        })
      );

      this._localStreamReady.next(dummyStream);
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }

  public closeMainStream(softClose = false) {
    if (this.mainStream.stream) {
      const stream = this.mainStream.stream;
      this.mainStream.stream = null;

      if (!softClose) {
        this.closeMediaStream(this.pipe.streamIn);
      }

      this._streamRemoved.next(stream);
    }
  }

  public closeScreenStream() {
    if (this.screenStream.stream) {
      const stream = this.screenStream.stream;
      this.screenStream.stream = null;

      this.closeMediaStream(stream.mediaStream);

      this._streamRemoved.next(stream);
    }
  }

  public closeMediaStream(stream: MediaStream) {
    stream?.getTracks().forEach((track) => {
      track.stop?.();
    });
  }

  public get audioDevice() {
    return this.pipe.input.activeDevices.audioinput;
  }

  public get speakerDevice() {
    return (
      MediaDevices.activeSpeaker || {
        label: "Default",
        kind: "audiooutput",
        deviceId:
          MediaDevices.getDeviceList("audiooutput").find((d) =>
            /default/i.test(d.label)
          )?.deviceId || null,
      }
    );
  }

  public get videoDevice() {
    return this.pipe.input.activeDevices.videoinput;
  }

  private isTrackMuted(track: MediaStreamTrack) {
    return !track || track.readyState === "ended" || !track.enabled;
  }
}
