import { Injectable } from "@angular/core";
import { IEndpoint } from "@auvious/rtc";
import {
  FileTransfer,
  FileTransferService as RTCFileTransferService,
  TransferOptions,
} from "@auvious/filetransfer";
import { FileTransferNotification, IInteraction } from "../models";
import { ApplicationService } from "./application.service";
import { NotificationService } from "./notification.service";
import { AuviousRtcService } from "./rtc.service";

@Injectable()
export class FileTransferService {
  constructor(
    private transferService: RTCFileTransferService,
    private applicationService: ApplicationService,
    private notificationService: NotificationService,
    private rtcService: AuviousRtcService
  ) {
    this.transferService.incoming().subscribe((file) => {
      const notification = new FileTransferNotification(file);
      this.notificationService.fileReceived(notification);
    });
  }

  async sendFile(
    blob: File,
    target: IEndpoint,
    interaction: IInteraction
  ): Promise<FileTransfer> {
    const options: TransferOptions = {
      blob,
      filename: blob.name,
      targetId: target.username,
      targetType: "user",
      applicationId: this.applicationService.getActiveApplication().getId(),
      conferenceId: interaction.getRoom(),
      interactionId: interaction.getId(),
    };

    return this.transferService.transfer(options);
  }

  async getFilesOfConference(
    conferenceId: string,
    notify = true
  ): Promise<FileTransfer[]> {
    const files = await this.transferService.findByConferenceId(conferenceId);
    if (notify) {
      this.notify(files);
    }
    return files;
  }

  async getFilesOfInteraction(
    interactionId: string,
    notify = true
  ): Promise<FileTransfer[]> {
    const files = await this.transferService.findByInteractionId(interactionId);
    if (notify) {
      this.notify(files);
    }
    return files;
  }

  async getFilesOfInteractionFiltered(
    interactionId: string,
    notify = true
  ): Promise<FileTransfer[]> {
    const files = await this.transferService.findByInteractionId(
      interactionId,
      true
    );
    if (notify) {
      this.notify(files);
    }
    return files;
  }

  private notify(files: FileTransfer[]) {
    files
      ?.filter((file) => file.targetId === this.rtcService.identity())
      .forEach((file) => {
        this.notificationService.fileReceived(
          new FileTransferNotification(file)
        );
      });
  }
}
