import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import {
  COBROWSER_PERMISSION,
  getSession,
  IReceiverControlAcceptedListener,
  IReceiverViewAcceptedListener,
  Receiver,
} from "@auvious/cobrowser";
import { IResizeEvent } from "@auvious/cobrowser/dist/domtools/events/resize";
import {
  IConferenceMetadata,
  IConferenceMetadataUpdatedEvent,
  IConferenceSession,
  IConferenceSessionEventHandlers,
  IEndpoint,
  IPublishOptions,
  IPublishedStream,
  IStream,
  StreamTypes,
} from "@auvious/rtc";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { AuviousRtcService } from "./rtc.service";
import { debug, debugError } from "./utils";
import {
  ConferenceMetadataKeyEnum,
  EndpointTypeEnum,
  InteractionMetricEnum,
  UserCapabilityEnum,
  UserRoleEnum,
  KEY_COBROWSE_CURRENT_SENDER_ENDPOINT,
  KEY_COBROWSE_OLD_SENDER_ENDPOINTS,
} from "../core-ui.enums";
import {
  ICobrowseReceiverEventHandlers,
  IArea,
  IInteraction,
  AgentParam,
  RecorderStateEnum,
  IRecorderInfo,
} from "../models";
import {
  BaseMetadata,
  ConferenceMetadataFactory,
  SketchMetadata,
} from "../models/Metadata";
import { AnalyticsService } from "./analytics.service";
import { AppConfigService } from "./app.config.service";
import { ApplicationService } from "./application.service";
import { NotificationService } from "./notification.service";
import { RecorderService } from "./recorder.service";
import { UserService } from "./user.service";
import { IEndpointMetadata } from "../models/IEndpointState";
import { BaseEvent } from "../models/IEvent";
import { createSentryLogger } from "../../app/app.utils";
import { SketchService } from "./sketch.service";
import { InvitationService } from "../../app/services/invitation.service";
import { TicketTypeEnum } from "../models/ITicket";
import { ProtectedTicketService } from "./ticket.service";
import { sessionStore } from "@auvious/utils";
import { LocaleService } from "../../app/services";

// eslint-disable-next-line no-shadow
enum CobrowseStateEnum {
  idle = "idle",
  joined = "joined",
  started = "started",
}

const sentryLog = createSentryLogger("cobrowse.service");

export class CobrowseDisplayCaptureRequestEvent extends BaseEvent {
  public static type = "CobrowseDisplayCaptureRequestEvent";
  constructor() {
    super(CobrowseDisplayCaptureRequestEvent.type);
  }
}

export class CobrowseDisplayCaptureDenyEvent extends BaseEvent {
  public static type = "CobrowseDisplayCaptureDenyEvent";
  constructor() {
    super(CobrowseDisplayCaptureDenyEvent.type);
  }
}

export class CobrowseDisplayCaptureAcceptEvent extends BaseEvent {
  public static type = "CobrowseDisplayCaptureAcceptEvent";
  constructor() {
    super(CobrowseDisplayCaptureAcceptEvent.type);
  }
}

export class CobrowseDisplayCaptureTerminateEvent extends BaseEvent {
  public static type = "CobrowseDisplayCaptureTerminateEvent";
  constructor() {
    super(CobrowseDisplayCaptureTerminateEvent.type);
  }
}

export class CobrowseJoinRequestEvent extends BaseEvent {
  public static type = "CobrowseJoinRequestEvent";
  constructor(
    public ticket: string,
    public originator: IEndpoint // originatorUsername: string, // originatorEndpoint: string
  ) {
    super(CobrowseJoinRequestEvent.type);
  }
}

@Injectable()
export class CobrowseService {
  // remote endpoint of type co-browse
  private _remoteCobrowseParticipant: IEndpoint;

  // remote endpoint of type user from whom we initially requested to start a co-browse session
  private _remoteStreamTarget: IEndpoint;

  private containerContext: "conference" | "standalone";
  private receiver: Receiver;
  private session: IConferenceSession;
  private handlers: ICobrowseReceiverEventHandlers;

  private _invitedSubject: Subject<IEndpoint<IEndpointMetadata>> =
    new Subject();
  public participantInvited$ = this._invitedSubject.asObservable();

  private _joinedSubject: Subject<IEndpoint<IEndpointMetadata>> = new Subject();
  public participantJoined$ = this._joinedSubject.asObservable();

  private _rejoinedSubject: Subject<IEndpoint<IEndpointMetadata>> =
    new Subject();
  public participantRejoined$ = this._rejoinedSubject.asObservable();
  public participantRejoined(participant: IEndpoint) {
    this._rejoinedSubject.next(participant);
  }

  private _startedSubject: Subject<IEndpoint> = new Subject();
  public started$ = this._startedSubject.asObservable();

  private _endRequestSubject: Subject<void> = new Subject();
  public endRequest$ = this._endRequestSubject.asObservable();

  private _endedSubject: Subject<IEndpoint> = new Subject();
  public ended$ = this._endedSubject.asObservable();

  private _sessionEndedSubject: Subject<void> = new Subject();
  public sessionEnded$ = this._sessionEndedSubject.asObservable();

  private _resizeSubject: Subject<IArea> = new Subject();
  public resized$ = this._resizeSubject.asObservable();

  private _conferenceMetadataSet = new Subject<IConferenceMetadata>();
  public conferenceMetadataSet$ = this._conferenceMetadataSet.asObservable();

  private _localStreamWillPublish = new Subject<IPublishOptions>();
  public localStreamWillPublish$ = this._localStreamWillPublish.asObservable();

  private _localStreamPublished = new Subject<IPublishedStream>();
  public localStreamPublished$ = this._localStreamPublished.asObservable();

  private _conferenceMetadataRemoved = new Subject<IConferenceMetadata>();
  public conferenceMetadataRemoved$ =
    this._conferenceMetadataRemoved.asObservable();

  private oldRemoteParticipantEndpoints: string[] = [];
  private participantsMap: Map<string, IEndpoint> = new Map();
  private state: CobrowseStateEnum;
  private recordingSession: {
    conversationId: string;
    recorderId: string;
    instanceId: string;
  } = null;
  private interaction: IInteraction;
  private interactionContext: "conference" | "standalone";
  private subscriptionRecorder: Subscription;
  private subscriptionRTC: Subscription;
  private renderer: HTMLIFrameElement;
  private isStoppingRecording = false;
  private conferenceMetadata: Map<string, IConferenceMetadata> = new Map();
  private joinRequestCb: (ticket: string, endpoint: IEndpoint) => void;
  // private conferenceMetadataQueue: Map<string, BaseMetadata> = new Map();
  private isPublishingStream = false;

  constructor(
    private rtcService: AuviousRtcService,
    private notification: NotificationService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private recorderService: RecorderService,
    private applicationService: ApplicationService,
    private config: AppConfigService,
    private errorHandler: ErrorHandler,
    private sketch: SketchService,
    private ticketService: ProtectedTicketService,
    private invitation: InvitationService,
    private zone: NgZone,
    private local: LocaleService
  ) {
    this.state = CobrowseStateEnum.idle;

    this.rtcService
      .getEventObservableAvailable()
      .pipe(take(1))
      .subscribe((eventObservable) => {
        this.subscriptionRTC = eventObservable.subscribe((data) => {
          switch (data?.payload?.type) {
            case CobrowseDisplayCaptureDenyEvent.type:
              this.notification.info(
                "The customer declined to share their screen."
              );
              this.handlers?.displayCaptureDenied?.();
              break;
            case CobrowseDisplayCaptureAcceptEvent.type:
              this.handlers?.displayCaptureAccepted?.();
              break;
            case CobrowseJoinRequestEvent.type:
              this.joinRequestCb?.(
                data.payload.ticket,
                data.payload.originator
              );
              break;
          }
        });
      });
  }

  private reset() {
    this._remoteCobrowseParticipant = null;
    sessionStore.removeItem(KEY_COBROWSE_CURRENT_SENDER_ENDPOINT);
    sessionStore.removeItem(KEY_COBROWSE_OLD_SENDER_ENDPOINTS);
    this.receiver?.cleanup();
    this.receiver = null;
    this.session = null;
    this.handlers = null;
    this.containerContext = null;
    this.joinRequestCb = null;
    this.oldRemoteParticipantEndpoints = [];
    this.state = CobrowseStateEnum.idle;
    this.participantsMap.clear();
  }

  subscribeToRecorder(waitToStart: boolean, recorderId): Promise<void> {
    let fallbackTimeout;
    return new Promise((resolve) => {
      this.subscriptionRecorder = this.recorderService.eventReceived$
        .pipe(filter((e) => e.recorderId === recorderId))
        .subscribe((data) => {
          switch (data.state) {
            case RecorderStateEnum.active:
              if (fallbackTimeout) {
                clearTimeout(fallbackTimeout);
                fallbackTimeout = null;
              }
              if (waitToStart) {
                resolve();
              }
              // this.setupReceiver(resolver);
              break;
            case RecorderStateEnum.stopped:
              this.subscriptionRecorder?.unsubscribe();
              break;
            case RecorderStateEnum.failed:
              this.notification.error("Co-browse recording failed");
              break;
            case RecorderStateEnum.aborted:
              this.notification.info("Co-browse recording aborted");
              break;
          }
        });
      if (waitToStart) {
        // in case we don't get the recorder started event, setup the receiver either way.
        fallbackTimeout = setTimeout((_) => {
          resolve();
        }, 3000);
      } else {
        resolve();
      }
    });
  }

  async join(conferenceId: string): Promise<IConferenceSession> {
    return new Promise((resolve, reject) => {
      if (this.session) {
        return;
      }

      this.rtcService.client.on(
        "newConferenceSession",
        (session) => (this.session = session)
      );
      this.rtcService.client.on("connectionError", (error) => {
        // this.alertService.alertError(`Could not initiate cobrowser. ${error.message}`, 3000);
        this.errorHandler.handleError(error);
        reject(error);
      });

      const eventHandlers: IConferenceSessionEventHandlers = {
        connecting: () =>
          debug(`cobrowse:connecting to conference ${conferenceId}`),
        accepted: (session: IConferenceSession) => {
          debug("cobrowse:accepted", session);
          resolve(session);
        },
        endpointJoined: (originator: IEndpoint) => {
          debug("cobrowse:endpoint joined", originator);

          // store participant to keep the metadata. endpointLeft does not carry them
          this.participantsMap.set(originator.endpoint, originator);

          const type = (originator.metadata as IEndpointMetadata).type;
          // we also have previous agents (of myself if I refresh) as joined participants. discard them
          if (type !== EndpointTypeEnum.coBrowse) {
            return;
          }
          let isRejoin = false;

          const previousSessionSenderEndpoint = this.getCurrentSenderEndpoint();

          if (this._remoteCobrowseParticipant) {
            // keep the previous participant to filter out events from the zombie originator
            this.addOldRemoteParticipant(this._remoteCobrowseParticipant);
            isRejoin = true;
          }

          if (
            (!!previousSessionSenderEndpoint &&
              previousSessionSenderEndpoint === originator.endpoint &&
              !this._remoteCobrowseParticipant) ||
            !previousSessionSenderEndpoint ||
            (!!previousSessionSenderEndpoint &&
              isRejoin &&
              !this.isOldSenderEndpoint(originator.endpoint))
          ) {
            this._remoteCobrowseParticipant = originator;
            this.setCurrentSenderEndpoint(originator);
          }

          if (!isRejoin) {
            this.setRemoteCobrowseParticipant(originator);
          } else {
            this._rejoinedSubject.next(originator);
          }
          // caused issues with agent refresh, reconnect came after this line so we created a new session by mistake
          // if (!this.receiver?.canView()) {
          //   this.requestPermission(COBROWSER_PERMISSION.VIEW);
          // }
        },
        endpointGotSick: (originator: IEndpoint) => {
          if (
            !this.oldRemoteParticipantEndpoints.includes(originator.endpoint) &&
            originator.endpoint === this._remoteCobrowseParticipant?.endpoint &&
            this.isCustomer(originator.endpoint)
          ) {
            debug("cobrowse:endpoint got sick", originator);
            this.handlers?.customerGotSick?.();
            // this.store.endpointStateChanged(
            //   originator.endpoint,
            //   EndpointStateEnum.Sick
            // );
          }
        },
        endpointRecovered: (originator: IEndpoint) => {
          if (
            !this.oldRemoteParticipantEndpoints.includes(originator.endpoint) &&
            originator.endpoint === this._remoteCobrowseParticipant?.endpoint &&
            this.isCustomer(originator.endpoint)
          ) {
            debug("cobrowse:endpoint recovered", originator);
            this.handlers?.customerRecovered?.();
            // this.store.endpointStateChanged(
            //   originator.endpoint,
            //   EndpointStateEnum.Joined
            // );
          }
        },
        endpointLeft: (originator: IEndpoint) => {
          if (
            !this.oldRemoteParticipantEndpoints.includes(originator.endpoint) &&
            (!this._remoteCobrowseParticipant ||
              originator.endpoint ===
                this._remoteCobrowseParticipant?.endpoint) &&
            this.isCustomer(originator.endpoint)
          ) {
            debug("cobrowse:endpoint left", originator);
            this.handlers?.customerLeft?.();
            // this.store.endpointStateChanged(
            //   originator.endpoint,
            //   EndpointStateEnum.Left
            // );
          }
        },
        streamPublished: (stream: IPublishedStream) => {
          this.isPublishingStream = false;
          this._localStreamPublished.next(stream);
        },
        streamAdded: (stream: IStream) => {
          if (stream.originator.endpoint !== this.rtcService.myself.endpoint) {
            this.handlers?.displayCaptureStreamAdded?.(stream);
          }
        },
        streamRemoved: (stream: IStream) => {
          // this.store.streamRemoved(stream);
          if (stream.originator.endpoint !== this.rtcService.myself.endpoint) {
            this.handlers?.displayCaptureStreamRemoved?.(stream);
          }
        },
        conferenceMetadataRemoved: (event: IConferenceMetadataUpdatedEvent) => {
          if (
            this.conferenceMetadata.has(event.key) &&
            event.userId !== this.rtcService.myself.username
          ) {
            sentryLog(["cobrowse.on.conferenceMetadataRemoved", event.key]);
            const meta = this.conferenceMetadata.get(event.key);
            // this.conferenceMetadataQueue.delete(event.key);
            this._conferenceMetadataRemoved.next(meta);
          }
        },
        conferenceMetadataSet: (event: IConferenceMetadataUpdatedEvent) => {
          const meta = ConferenceMetadataFactory.fromEvent(event);
          if (meta) {
            sentryLog(["cobrowse.on.conferenceMetadataSet", event.key, meta]);
            this.conferenceMetadata.set(event.key, meta);
            this.tryNotifyForMetadata(meta);
          }
        },
        failed: (error) => {
          debugError(error);
          reject(error);
        },
        ended: () => {
          debug("conference session ended");
          this.session = null;
          this._sessionEndedSubject.next();
        },
      };

      const metadata: IEndpointMetadata = {
        name:
          this.userService.getUserDetails()?.displayName ||
          this.userService.getUserDetails()?.name,
        type: EndpointTypeEnum.participant,
        roles: this.userService.getActiveUser().getRoles(),
      };

      try {
        this.rtcService.client.joinConference({
          id: conferenceId,
          eventHandlers,
          metadata,
        });
      } catch (ex) {
        reject(ex);
      }
    });
  }

  public unpublish(stream: IStream) {
    this.session.unpublish(stream);
  }

  public async publish(stream: IStream) {
    try {
      if (
        !stream ||
        this.isPublishingStream ||
        !this.session?.isEstablished()
      ) {
        return;
      }

      this.isPublishingStream = true;

      const streamToPublish: IPublishOptions = {
        mediaStream: new MediaStream(stream.mediaStream.getTracks()),
        type: stream.type,
        metadata: stream.getMetadata(),
      };

      this._localStreamWillPublish.next(streamToPublish);

      await this.zone.runOutsideAngular(() =>
        this.session.publish(streamToPublish)
      );

      // if (stream.type === StreamTypes.SCREEN) {
      //   this.windowEventService.sendMessage(new DeviceCaptureSuccessAction());
      // } else {
      //   this.setFacingModeForStream(stream, this.local.mainStream.facingMode);
      // }
    } catch (ex) {
      debugError(
        `error while trying to get ${stream.type} stream: ${ex.message}`
      );

      // if (stream.type === StreamTypes.SCREEN) {
      //   this.windowEventService.sendMessage(
      //     new DeviceCaptureErrorAction({ name: ex.name, message: ex.message })
      //   );
      // }

      this.errorHandler.handleError(ex);
      this.notification.error("Could not publish stream.", { ttl: 3000 });
    } finally {
      this.isPublishingStream = false;
    }
  }

  /**
   * Wait for a cobrowseJoinRequest event from the agent that holds the ticket while in a room.
   * The UI propagates this request to the widget. Widget authenticates, joins the customer and
   * the UI is notified of a new co-browse participant joined and sends a view-request.
   */
  public listen(cb: (ticket: string, endpoint: IEndpoint) => void) {
    this.joinRequestCb = cb;
  }

  public start(
    renderer: HTMLIFrameElement,
    handlers: ICobrowseReceiverEventHandlers,
    interaction: IInteraction,
    context: "conference" | "standalone"
  ): Promise<void> {
    this.handlers = handlers;
    this.containerContext = context;
    this.interaction = interaction;
    this.interactionContext = context;
    this.renderer = renderer;
    // this.terminating = false;

    return new Promise(async (resolve) => {
      const waitForRecordingToStart = await this.startRecording(interaction);

      if (this.recordingSession) {
        await this.subscribeToRecorder(
          waitForRecordingToStart,
          this.recordingSession.recorderId
        );
      }
      this.setupReceiver(resolve);
    });
  }

  private setupReceiver(resolver: () => void) {
    // in case timeout runs first and we still get the event, do not setup an already existing receiver
    if (!!this.receiver) {
      return;
    }

    this.receiver = new Receiver(
      this.rtcService.getAuviousCommonClient(),
      {
        userId: this.rtcService.client.identity(),
        userEndpointId: this.rtcService.client.endpoint(),
        userMetadata: {
          role: UserRoleEnum.agent,
          name:
            this.userService.getUserDetails()?.displayName ||
            this.userService.getUserDetails()?.name,
        },
        renderer: this.renderer,
        record: this.recordingSession,
      },
      {
        viewAccepted: (ev) => {
          sentryLog(["cobrowse.on.viewAccepted", ev]);
          this.onViewAccepted(ev);
        },
        controlAccepted: (ev) => {
          sentryLog(["cobrowse.on.controlAccepted", ev]);
          this.analytics.updateInteractionMetrics(this.interaction, {
            [InteractionMetricEnum.cobrowseControlAcceptedAt]:
              new Date().toISOString(),
          });
          this.onControlAccepted(ev);
        },
        controlRevoked: (ev) => {
          sentryLog(["cobrowse.on.controlRevoked", ev]);
          debug("cobrowser: controlRevoked", ev);
          this.analytics.updateInteractionMetrics(this.interaction, {
            [InteractionMetricEnum.cobrowseControlRevokedAt]:
              new Date().toISOString(),
          });
          this.handlers.controlRevoked?.(ev);
        },
        accessDenied: (ev) => {
          sentryLog(["cobrowse.on.accessDenied", ev]);
          debug("cobrowser: accessDenied", ev);
          this.analytics.updateInteractionMetrics(this.interaction, {
            [InteractionMetricEnum.cobrowseAccessDeniedAt]: [
              new Date().toISOString(),
              ev.permission,
            ].join("|"),
          });

          if (ev.permission === COBROWSER_PERMISSION.VIEW) {
            // check if permission is view and clear
            this.cancelRecording();
            this.terminate();
          }

          this.handlers.accessDenied?.(ev.permission);
        },
        terminated: async (ev) => {
          sentryLog(["cobrowse.on.terminated", ev]);
          debug("cobrowser: terminated", ev);
          this.session?.terminate();
          this.handlers?.terminated?.(ev);
          await this.stopRecording();
          this.reset();
          this._endedSubject.next(this._remoteCobrowseParticipant);
        },
        navigation: (ev) => {
          debug("cobrowser: navigation", ev);
          this.handlers.navigation?.(ev);
        },
        filtered: (ev) => {
          debug("cobrowser: filtered", ev);
          this.handlers.filtered?.(ev);
        },
        mousemove: (ev) => {
          // debug('cobrowser: mousemove', ev);
          this.handlers.mousemove?.(ev);
        },
        resize: (ev: IResizeEvent) => {
          this._resizeSubject.next(ev);
          this.handlers.resize?.(ev);
        },
      }
    );

    resolver();
  }

  private onViewAccepted(ev: IReceiverViewAcceptedListener) {
    debug("cobrowser: viewAccepted", ev);
    this.state = CobrowseStateEnum.started;
    this.receiver.enable("scroll");

    // track in analytics after the customer has joined and we have at least view permission
    if (this.recordingSession) {
      const recording: IRecorderInfo = {
        instanceId: this.recordingSession.instanceId,
        recorderId: this.recordingSession.recorderId,
        conversationId: this.recordingSession.conversationId,
        state: RecorderStateEnum.active,
      };
      this.analytics.trackRecordingStarted(
        recording,
        this.interaction,
        "co-browse"
      );
    }

    this._startedSubject.next(this._remoteCobrowseParticipant);
    this.handlers.viewAccepted?.(ev);
  }

  private onControlAccepted(ev: IReceiverControlAcceptedListener) {
    debug("cobrowser: controlAccepted", ev);
    this.handlers.controlAccepted?.(ev);
  }

  public async terminate(failedToReconnect = false) {
    let failedToTerminate = false;
    this._endRequestSubject.next();
    if (this.receiver) {
      try {
        await this.receiver.terminate();
      } catch (ex) {
        failedToTerminate = true;
        debugError("could not terminate cobrowse receiver", ex);
      }

      // if the remote sender has not accepted yet (either he denied or we closed before we get an acceptance)
      // we are in joined state, so we need to clean manually.
      if (
        this.state === CobrowseStateEnum.joined ||
        failedToTerminate ||
        failedToReconnect
      ) {
        this.handlers?.terminated?.();
        this.session?.terminate();
        this.reset();
        this._endedSubject.next(this._remoteCobrowseParticipant);
      }
    } else {
      // todo: maybe remove
      try {
        this.session?.terminate();
      } catch (ex) {
        debugError(ex);
      }
      this.handlers?.terminated?.();
      this.reset();
    }
    await this.stopRecording();
  }

  public async reconnect(sessionId: string) {
    try {
      sentryLog(["cobrowse.reconnect", sessionId]);

      await this.receiver.reconnect(sessionId);

      if (this.receiver.canView()) {
        this.state = CobrowseStateEnum.started;
        this._startedSubject.next(this._remoteCobrowseParticipant);
        this.handlers?.viewAccepted(null);
      }

      if (this.receiver.canControl()) {
        this.handlers?.controlAccepted(null);
      }
    } catch (ex) {
      this.errorHandler.handleError(ex);
      throw ex;
    }
  }

  public async requestPermission(permission: COBROWSER_PERMISSION) {
    try {
      await this.receiver?.requestAccess(permission, {
        userId: this._remoteCobrowseParticipant.username,
        userEndpointId: this._remoteCobrowseParticipant.endpoint,
      });
    } catch (ex) {
      debugError(ex);
      this.notification.error("Co-browse failed", { body: ex.message || ex });
      this.terminate();
    }
  }

  public requestDisplayCapture() {
    this.rtcService.sendEventMessage(
      this._remoteCobrowseParticipant.username,
      this._remoteCobrowseParticipant.endpoint,
      new CobrowseDisplayCaptureRequestEvent()
    );
  }

  public terminateDisplayCapture() {
    this.rtcService.sendEventMessage(
      this._remoteCobrowseParticipant.username,
      this._remoteCobrowseParticipant.endpoint,
      new CobrowseDisplayCaptureTerminateEvent()
    );
  }

  public setConferenceMetadata(data: BaseMetadata): Promise<void> {
    return this.session?.setConferenceMetadata(data.key, data.value);
  }

  public removeConferenceMetadata(
    key: ConferenceMetadataKeyEnum
  ): Promise<void> {
    return this.session?.removeConferenceMetadata(key);
  }

  public supported(endpoint: IEndpoint): boolean {
    const roles = endpoint?.metadata?.roles || [];
    const capabilities = endpoint?.metadata?.capabilities || [];

    const hasCustomerRole = roles.includes(UserRoleEnum.customer);
    const hasCapability = capabilities.includes(UserCapabilityEnum.coBrowse);

    return hasCustomerRole && hasCapability;
  }

  public setStreamTarget(originator: IEndpoint) {
    this._remoteStreamTarget = originator;
  }

  public getStreamTarget(): IEndpoint {
    return this._remoteStreamTarget;
  }

  public setRemoteCobrowseParticipant(originator: IEndpoint) {
    this._remoteCobrowseParticipant = originator;
    this.setCurrentSenderEndpoint(originator);
    // in case the customer refreshed the page and joined again
    if (this.state !== CobrowseStateEnum.started) {
      this.state = CobrowseStateEnum.joined;
    }
    this._joinedSubject.next(originator);
  }

  public addOldRemoteParticipant(originator: IEndpoint) {
    this.oldRemoteParticipantEndpoints.push(originator.endpoint);

    // keep in session
    let sessionSenders = [];
    try {
      const existngStr = sessionStore.getItem(
        KEY_COBROWSE_OLD_SENDER_ENDPOINTS
      );
      if (existngStr) {
        sessionSenders = JSON.parse(existngStr);
      }
      sessionSenders.push(originator.endpoint);
    } catch (ex) {
      sessionSenders = this.oldRemoteParticipantEndpoints;
    }
    sessionStore.setItem(
      KEY_COBROWSE_OLD_SENDER_ENDPOINTS,
      JSON.stringify(sessionSenders)
    );
  }

  private setCurrentSenderEndpoint(originator: IEndpoint) {
    sessionStore.setItem(
      KEY_COBROWSE_CURRENT_SENDER_ENDPOINT,
      originator.endpoint
    );
  }

  private getCurrentSenderEndpoint(): string {
    return sessionStore.getItem(KEY_COBROWSE_CURRENT_SENDER_ENDPOINT);
  }

  public async inviteParticipant(target: IEndpoint, interaction: IInteraction) {
    // prepare a new ticket for the participant
    const ticketRequest = this.invitation.prepareTicketRequest(
      TicketTypeEnum.MultiUseTicket,
      6,
      interaction.getRoom(),
      interaction.getCustomerId() || "cobrowsing_customer",
      interaction.getId()
    );
    const ticket = await this.ticketService.createTicket(ticketRequest);

    // send invitation to participant. Once they get it, they will propagate it to the widget
    this.rtcService.sendEventMessage(
      target.username,
      target.endpoint,
      new CobrowseJoinRequestEvent(ticket, {
        username: this.rtcService.myself.username,
        endpoint: this.rtcService.myself.endpoint,
      })
    );

    this._invitedSubject.next(target);
  }

  public getSession(id: string) {
    return getSession(this.rtcService.getAuviousCommonClient(), id);
  }

  private isCustomer(endpoint: string): boolean {
    return (
      (this.participantsMap.get(endpoint)?.metadata as IEndpointMetadata)
        ?.type === EndpointTypeEnum.coBrowse
    );
  }

  public isTarget(endpoint: string): boolean {
    return this.oldRemoteParticipantEndpoints.includes(endpoint);
  }

  private isOldSenderEndpoint(endpoint: string): boolean {
    let old = [];
    try {
      old = JSON.parse(sessionStore.getItem(KEY_COBROWSE_OLD_SENDER_ENDPOINTS));
    } catch (ex) {
      old = this.oldRemoteParticipantEndpoints;
    }
    return old.includes(endpoint);
  }

  public get sessionId() {
    return this.receiver?.sessionId;
  }

  public get isRemoteParticipantConnected(): boolean {
    return !!this._remoteCobrowseParticipant;
  }

  public get isStarted(): boolean {
    return this.state === CobrowseStateEnum.started;
  }

  public get isPendingApproval(): boolean {
    return this.state === CobrowseStateEnum.joined;
  }

  public get myself(): IEndpoint {
    return this.rtcService.myself;
  }

  /**
   * Remote endpoint of type co-browse. Same as getRemoteCobrowseParticipant()
   */
  public get target(): IEndpoint {
    return this._remoteCobrowseParticipant;
  }
  // alias method
  public getRemoteCobrowseParticipant(): IEndpoint {
    return this._remoteCobrowseParticipant;
  }

  public get room() {
    return this.session?.conference.name;
  }

  public get context(): "standalone" | "conference" {
    return this.containerContext;
  }

  // public denyPermission(permission: COBROWSER_PERMISSION) {
  //     this.receiver. (permission, {
  //         userId: this.remoteParticipant.username,
  //         userEndpointId: this.remoteParticipant.endpoint,
  //     });
  // }

  /** recording */

  /**
   * if cobrowse is set up, notify server that recording has been disabled
   */
  public async disableRecording() {
    if (this.receiver && (this.isPendingApproval || this.isStarted)) {
      await this.receiver.disable("record");
    }
  }

  /**
   * Recording should auto-start only if an active room recording exists.
   *
   * @param interaction current interaction
   * @returns boolean. "true" to wait for recording to start, "false" to start immediately
   */
  private async startRecording(interaction: IInteraction): Promise<boolean> {
    if (
      !!interaction &&
      this.config.agentParamEnabled(AgentParam.COBROWSE_RECORD)
    ) {
      try {
        return await this.setupRecording(interaction);
      } catch (error) {
        debugError("cobrowse.service", error);
        this.handlers.recordingFailed();
        return false;
      }
    } else {
      debug(
        "will not record cobrowse session, record param is ",
        this.config.agentParamEnabled(AgentParam.COBROWSE_RECORD)
      );
      return false;
    }
  }

  /**
   * Start a recording session if one does not already exist
   *
   * @returns boolean. "true" to wait for recording to start, "false" to start immediately
   */
  private async setupRecording(interaction: IInteraction): Promise<boolean> {
    const conversationId = interaction.getId();

    if (
      !!this.recordingSession &&
      conversationId === this.recordingSession.conversationId
    ) {
      return false;
    }

    let recording: IRecorderInfo;
    let waitForRecordingToStart = false;

    switch (this.interactionContext) {
      // create new or find an existing one
      case "standalone":
        try {
          recording = await this.findActiveRecording(interaction.getId());
          waitForRecordingToStart = false;
        } catch (ex) {
          // do nothing, start a new recording
        }
        if (!recording) {
          recording = await this.recorderService.start({
            conversationId,
            applicationId: this.applicationService
              .getActiveApplication()
              .getId(),
            conferenceId: interaction.getRoom(),
            audio: false,
            video: false,
          });
          waitForRecordingToStart = true;
        }
        break;

      // check for existing active recording. if none exists, don't auto-start
      case "conference":
        recording = await this.findActiveRecording(interaction.getId());
        waitForRecordingToStart = false;
        break;
    }

    sentryLog(["cobrowse.setupRecording", recording]);

    if (!recording) {
      return false;
    }

    // this.analytics.trackRecordingStarted(recording, this.interaction, "co-browse");

    this.recordingSession = {
      conversationId,
      instanceId: recording.instanceId,
      recorderId: recording.recorderId,
    };

    return waitForRecordingToStart;
  }

  /**
   * Cancels a recording in metrics in case the customer denied view
   *
   * @param interaction
   */
  private cancelRecording() {
    if (!!this.recordingSession) {
      this.analytics.updateInteractionMetrics(this.interaction, {
        [InteractionMetricEnum.cobrowseRecorderId]: null,
        [InteractionMetricEnum.cobrowseRecorderInstanceId]: null,
      });
    }
  }

  private async stopRecording() {
    if (
      this.recordingSession &&
      !this.isStoppingRecording &&
      this.interactionContext === "standalone"
    ) {
      this.isStoppingRecording = true;
      try {
        await this.recorderService.stop(
          this.recordingSession.recorderId,
          this.recordingSession.conversationId,
          this.recordingSession.instanceId
        );
      } catch (reason) {
        debugError("failed to stop cobrowse recording", reason);
        this.errorHandler.handleError(reason);
      } finally {
        await this.trackRecordingStopped();
        this.isStoppingRecording = false;
        this.subscriptionRecorder?.unsubscribe();
        this.recordingSession = null;
      }
    } else if (
      this.recordingSession &&
      this.interactionContext === "conference"
    ) {
      this.subscriptionRecorder?.unsubscribe();
      this.recordingSession = null;
    }
  }

  private async findActiveRecording(conversationId: string) {
    const recording = await this.recorderService.getStoredRecorderInfo(
      conversationId
    );
    return recording && recording.state === RecorderStateEnum.active
      ? recording
      : null;
  }

  private async trackRecordingStopped() {
    try {
      if (!this.interaction) {
        return;
      }
      const session = await this.recorderService.getSessionInfo(
        this.recordingSession.recorderId,
        this.recordingSession.conversationId,
        this.recordingSession.instanceId
      );
      if (!!session) {
        // since we are in a standalone recording (no interaction), do not log to implementation analytics
        this.analytics.trackRecordingStopped(session, this.interaction, false);
      }
    } catch (ex) {
      debugError("failed to stop cobrowse recording", ex);
    } finally {
      this.interaction = null;
    }
  }

  private tryNotifyForMetadata(data: BaseMetadata) {
    if (data instanceof SketchMetadata) {
      this.sketch.setConferenceMetadata(data);
    }

    this._conferenceMetadataSet.next(data);
  }
}
