import { Injectable } from "@angular/core";
import moment from "moment";
import { IPublishedStream } from "@auvious/rtc";
import { Device } from "@auvious/compatibility";

import { IUser } from "../models/IUser";
import { AppConfigService, FEATURES } from "./app.config.service";
import { AgentParam, CustomerParam, IStreamMetadata } from "../models";
import {
  ApplicationTypeEnum,
  KEY_DEFAULT_AUDIO_DEVICE_ID,
  KEY_DEFAULT_VIDEO_DEVICE_ID,
  KEY_DEVICES_CHECKED,
  UserRoleEnum,
} from "../core-ui.enums";
import { sessionStore } from "@auvious/utils";
import { MediaDevices } from "@auvious/media-tools";
import { ApplicationService } from "./application.service";

@Injectable()
export class DeviceService extends Device {
  private isRoomDevicesSetup = false;
  private _isPopUp = undefined;

  constructor(
    private config: AppConfigService,
    private application: ApplicationService
  ) {
    super();
  }

  static get isWebView() {
    return /webview|facebook/i.test(DeviceService.info.browser.name);
  }

  get isPictureInPictureEnabled() {
    // eslint-disable-next-line compat/compat
    return document.pictureInPictureEnabled;
  }

  get isDisplayMediaAvailable(): boolean {
    return (
      // @ts-expect-error
      !!navigator.getDisplayMedia || !!navigator.mediaDevices.getDisplayMedia
    );
  }

  get isDevicesSetup() {
    return this.isRoomDevicesSetup;
  }

  set isDevicesSetup(value) {
    this.isRoomDevicesSetup = value;
  }

  public isScreenShareFeatureSupported(): boolean {
    if (DeviceService.isMobile) {
      return false;
    } else if (!DeviceService.isIFrame) {
      return true;
    } else if (
      this.config.featureEnabled(FEATURES.PC_IFRAME_ALLOW_DISPLAY_CAPTURE)
    ) {
      return true;
    } else if (DeviceService.isChromium) {
      return Number(DeviceService.info.engine.version) < 94;
    } else {
      return false;
    }
  }

  /**
   * stores selection in local storage
   *
   * @param audioDeviceId
   * @param videoDeviceId
   */
  keepDeviceSetupUntil(
    audioDeviceId: string,
    videoDeviceId: string,
    until: string
  ) {
    sessionStore.setItem(KEY_DEVICES_CHECKED, until);
    sessionStore.setItem(KEY_DEFAULT_AUDIO_DEVICE_ID, audioDeviceId);
    sessionStore.setItem(KEY_DEFAULT_VIDEO_DEVICE_ID, videoDeviceId);
  }

  /**
   * We need user and config to avoid circular dependency
   *
   * @param user logged in user
   * @param config application config
   * @returns boolen
   */
  isDeviceSetupSkipped(user: IUser, config: AppConfigService): boolean {
    const isAgent = user.hasRole(UserRoleEnum.agent);
    const isCustomer = user.hasRole(UserRoleEnum.customer);

    const skipCheck =
      (isCustomer &&
        !config.customerParamEnabled(CustomerParam.DEVICE_SETUP_ENABLED)) ||
      (isAgent &&
        (!config.agentParamEnabled(AgentParam.DEVICE_SETUP_ENABLED) ||
          !config.agentParamEnabled(AgentParam.STREAM_CONTROLS_ENABLED)));

    const date = sessionStore.getItem(KEY_DEVICES_CHECKED);

    if (skipCheck) {
      return true;
    } else if (!date) {
      return false;
    } else if (moment(date).isSame(new Date(), "day")) {
      return true;
    } else {
      this.removeSkippedDeviceSetup();
      return false;
    }
  }

  removeSkippedDeviceSetup() {
    sessionStore.removeItem(KEY_DEVICES_CHECKED);
    sessionStore.removeItem(KEY_DEFAULT_AUDIO_DEVICE_ID);
    sessionStore.removeItem(KEY_DEFAULT_VIDEO_DEVICE_ID);
  }

  getDefaultDevices(): {
    audioDeviceId: string;
    videoDeviceId: string;
    speakerDeviceId: string;
  } {
    return {
      audioDeviceId: MediaDevices.preferred.audioinput?.deviceId,
      videoDeviceId: MediaDevices.preferred.videoinput?.deviceId,
      speakerDeviceId: MediaDevices.preferred.audiooutput?.deviceId,
    };
  }

  getMediaDisplaySurface(stream: IPublishedStream): MediaDisplaySurface {
    if (DeviceService.isChromium) {
      const metadata: IStreamMetadata = stream.getMetadata();
      return (
        (metadata?.video?.settings.displaySurface as MediaDisplaySurface) ||
        "unknown"
      );
    } else if (DeviceService.isSafari) {
      return "monitor";
    } else {
      return "unknown";
    }
  }

  public init() {
    this._isPopUp = this.getPopUp();
  }

  private getPopUp() {
    // on talkdesk app, opener is the talkdesk window (target=_blank also sets an opener)
    // so we need to check the menu bar as well
    switch (this.application.getActiveApplication().getType()) {
      case ApplicationTypeEnum.NiceOpenID:
        return !!window.opener && window.opener !== window;
      default:
        return (
          !!window.opener &&
          window.opener !== window &&
          !window.menubar?.visible
        );
    }
  }

  /**
   * If we are a pop up and the window.opener (the one who opened us) closes before we do then we do not identify as a popup.
   * window.opener is missing. So we need to keep it internally in a variable and return that variable.
   */
  get isPopUp() {
    return this._isPopUp;
  }
}
