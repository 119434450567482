import { Interaction, InteractionData } from "@auvious/interaction";
import moment, { Duration } from "moment";
import { IInteractionMetrics, IRecorderInfo, RecorderStateEnum } from ".";
import { InteractionMetricEnum, ConversationTypeEnum } from "../core-ui.enums";

export class InteractionMetrics implements IInteractionMetrics {
  _data: InteractionData = {};
  _id: string;
  _organizationId: string;
  _duration: Duration;
  _type: ConversationTypeEnum;
  _mediaRecorderInfo: IRecorderInfo;
  _cobrowseRecorderInfo: IRecorderInfo;
  _createdBy: string;
  _customerDisplayName: string;

  constructor(_interaction: Interaction) {
    const { id, organizationId, data, type, createdBy } = _interaction;
    this._id = id;
    this._organizationId = organizationId;
    this._data = data;
    this._createdBy = createdBy;
    this._type = type as ConversationTypeEnum;

    if (this.getCallStartAt() && this.getCallEndAt()) {
      this._duration = moment.duration(
        moment(this.getCallEndAt()).diff(moment(this.getCallStartAt()))
      );
    }
    if (this._data?.[InteractionMetricEnum.recorderInstanceId]) {
      this._mediaRecorderInfo = {
        instanceId: this._data[InteractionMetricEnum.recorderInstanceId],
        recorderId: this._data[InteractionMetricEnum.recorderId],
        conversationId: this._id,
        state: RecorderStateEnum.stopped,
      };
    }
    if (this._data?.[InteractionMetricEnum.cobrowseRecorderInstanceId]) {
      this._cobrowseRecorderInfo = {
        instanceId:
          this._data[InteractionMetricEnum.cobrowseRecorderInstanceId],
        recorderId: this._data[InteractionMetricEnum.cobrowseRecorderId],
        conversationId: this._id,
        state: RecorderStateEnum.stopped,
      };
    }
  }

  getId() {
    return this._id;
  }
  getCallType() {
    return this._type;
  }
  getCallOrigin() {
    return this._data?.[InteractionMetricEnum.callOrigin];
  }
  getCallDestination() {
    return this._data?.[InteractionMetricEnum.callDestination];
  }
  getCallOriginMode() {
    return this._data?.[InteractionMetricEnum.callOriginMode];
  }
  getCallStartAt() {
    return this._data?.[InteractionMetricEnum.callStartAt];
  }
  getCallEndAt() {
    return this._data?.[InteractionMetricEnum.callEndAt];
  }
  getCallDuration() {
    return this._duration;
  }
  getCallRecorderInfo(): IRecorderInfo {
    return this._mediaRecorderInfo;
  }
  getCobrowseRecorderInfo(): IRecorderInfo {
    return this._cobrowseRecorderInfo;
  }
  getOriginUrl(): string {
    return this._data?.[InteractionMetricEnum.originUrl];
  }
  getCreatedBy(): string {
    return this._createdBy;
  }
  getCustomerDisplayName(): string {
    return this._data?.[InteractionMetricEnum.customerDisplayName];
  }
  getCustomerMetadata(): { [key: string]: any } {
    return this._data?.[InteractionMetricEnum.customerMetadata];
  }
  getCustomerId(): string {
    return this._data?.[InteractionMetricEnum.customerId];
  }
  setMetric(key: InteractionMetricEnum, value: any) {
    this._data = {
      ...this._data,
      [key]: value,
    };
    // recalculate duration
    if (
      [
        InteractionMetricEnum.callEndAt,
        InteractionMetricEnum.callStartAt,
      ].includes(key)
    ) {
      this._duration = moment.duration(
        moment(this.getCallEndAt()).diff(moment(this.getCallStartAt()))
      );
    }
  }

  getMetric(key: InteractionMetricEnum) {
    return this._data[key];
  }

  getMetrics(): InteractionData {
    return this._data;
  }
  getTermsAcceptedAt(): Date {
    return !!this._data?.[InteractionMetricEnum.termsAcceptedAt]
      ? new Date(this._data[InteractionMetricEnum.termsAcceptedAt])
      : null;
  }
  getTermsAcceptedSnapshot(): string {
    return this._data?.[InteractionMetricEnum.termsAcceptedSnapshot];
  }

  getIntegrationInteractionAvailable(): boolean {
    return (
      this._data?.[InteractionMetricEnum.integrationInteractionAvailable] ??
      true
    );
  }
}
