<av-card
  class="notification"
  [class.notification-conversation]="isConversation"
  [class.notification-toast]="isToast"
  (click)="clicked()"
  [type]="color"
>
  <av-card-body type="thin">
    <!-- <div class="notification-header fx-row fx-align-center fx-justify-space-between">
        <div class="fx-row">
            <av-icon name="document-download"></av-icon>
            <small>DOCUMENT</small>
        </div>
        <div>
            <small class="text-muted">10:41 AM</small>
        </div>
    </div> -->
    <div class="fx-row fx-align-stretch fx-justify-space-between">
      <div class="fx-row fx-align-start fx-gap-10">
        <av-icon name="info" size="sm" *ngIf="isToast && color === 'container'"></av-icon>

        <div class="notification-content">
          <div
            role="heading"
            class="notification-title"
            [class.notification-title-bold]="!!body"
            translate
          >
            {{ title }}
          </div>
          <div
            *ngIf="!!body"
            class="notification-body"
            [class.notification-body-file]="isFileTransfer"
            translate
          >
            {{ body }}
          </div>
        </div>
      </div>
      <div *ngIf="isFileTransfer" class="fx-row fx-align-center">
        <av-copy-clipboard size="xs" [url]="url | async"></av-copy-clipboard>

        <av-tooltip
          message="{{ 'Download' | translate }}"
          class="fx-row fx-align-center fx-justify-center margin-left-10"
        >
          <a
            aria-label="Download file"
            avButton
            ofType="icon"
            color="link"
            size="xs"
            [download]="body"
            target="_blank"
            [href]="url | async"
            translate
          >
            <av-icon name="download"></av-icon>
          </a>
        </av-tooltip>
      </div>
    </div>
  </av-card-body>
  <av-card-footer
    *ngIf="isConfirm"
    class="fx-row fx-align-center fx-justify-space-between"
  >
    <button
      aria-label="Cancel"
      data-tid="notification/confirm-cancel"
      avButton
      ofType="basic"
      (click)="deny()"
      translate
    >
      Cancel
    </button>
    <button
      aria-label="Accept"
      data-tid="notification/confirm-approve"
      avButton
      color="accent"
      (click)="approve()"
      translate
    >
      Yes
    </button>
  </av-card-footer>
</av-card>
<button
  *ngIf="!isToast"
  aria-label="Dismiss notification"
  data-tid="notification/dismiss"
  (click)="remove()"
  avButton
  class="close"
  color="basic"
  ofType="icon"
  size="xxs"
  raised="true"
>
  <av-icon class="close-icon" name="close-big"></av-icon>
</button>
