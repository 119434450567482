import { ConversationDestinationEnum } from "../../core-ui";
import { IInteraction } from "../../core-ui/models/IInteraction";
import { IConversationInfo } from "./IConversationInfo";
import {
  ConversationMetadataEnum,
  ConversationChannelEnum,
  OriginModeEnum,
  ApplicationTypeEnum,
  ConversationTypeEnum,
} from "../../core-ui/core-ui.enums";
import { mapToJSON } from "../app.utils";
import {
  DEFAULT_CUSTOMER_ID,
  KEY_ROOM_NAME,
  KEY_TICKET_ID,
  PARAM_CALL_TYPE,
} from "../app.enums";
export class GenesysInteraction implements IInteraction {
  private _info: IConversationInfo;
  private _room: string;
  private _parentFrameUrl: string;
  private _destination: ConversationDestinationEnum;
  private _meta: Map<ConversationMetadataEnum, any>;

  constructor(data: IConversationInfo) {
    this._info = { ...data };
    if (!data) {
      return;
    }
    if (!!data.room) {
      this._room = data.room;
    }
    this._destination = ConversationDestinationEnum.EMBEDDED;
    this._meta = new Map();
    // @ts-expect-error
    if (data.metadata) {
      // @ts-expect-error
      Object.keys(data.metadata).forEach((key) =>
        // @ts-expect-error
        this._meta.set(key, data.metadata[key])
      );
    }

    if (data.customerMetadata) {
      Object.keys(data.customerMetadata)
        .filter((key) => ![KEY_ROOM_NAME, KEY_TICKET_ID].includes(key))
        .forEach((key) =>
          this._meta.set(
            key as ConversationMetadataEnum,
            data.customerMetadata[key]
          )
        );
    }
  }

  getId() {
    return this._info.conversationId;
  }

  getIntegrationId(): string {
    return this._info.conversationId;
  }

  hasRoom() {
    return !!this._room;
  }

  getRoom() {
    return this._room;
  }

  setRoom(room: string) {
    this._room = room;
  }

  getOrigin() {
    return this._info.origin;
  }

  setOrigin(value) {
    if (!value) {
      return;
    }
    this._info.origin = value;
  }

  getType() {
    return this._info.type;
  }

  setType(value) {
    if (!value) {
      return;
    }
    this._info.type = value;
  }

  getCustomerId() {
    return this._info.customerId;
  }

  setCustomerId(value: string) {
    this._info.customerId = value;
  }

  getAgentId() {
    return this._info.agentId;
  }

  isCustomerInvited() {
    return this._info.customerInvited;
  }

  getCommunicationId() {
    return this._info.communicationId;
  }

  getCustomerEmail() {
    return this._info.customerEmail;
  }

  getCustomerPhoneNumber() {
    return this._info.customerPhone;
  }

  getCustomerName() {
    return this._info.customerName;
  }

  getQueueId() {
    return this._info.queueId;
  }

  setParentFrameUrl(value: string) {
    this._parentFrameUrl = value;
  }

  getParentFrameUrl() {
    return this._parentFrameUrl;
  }

  supportsMessages() {
    return !this._info.legacy;
  }

  getOriginMode() {
    return this._info.originMode;
  }

  setOriginMode(value: OriginModeEnum) {
    this._info.originMode = value;
  }

  supportsEmails() {
    return true;
  }

  supportsScheduledCalls() {
    return true;
  }

  supportsSMS() {
    return true;
  }

  getDestination() {
    return this._destination;
  }

  setDestination(value: ConversationDestinationEnum) {
    this._destination = value;
  }

  setMetadataItem(key: ConversationMetadataEnum, value: any) {
    this._meta.set(key, value);
  }

  getMetadataItem(key: ConversationMetadataEnum): any {
    return this._meta.get(key);
  }

  getMetadata() {
    return mapToJSON(this._meta);
  }

  getChannel(): ConversationChannelEnum {
    return this._info.channel;
  }

  setChannel(value: ConversationChannelEnum) {
    this._info.channel = value;
  }

  isTransfer(): boolean {
    return this._info.transferred;
  }

  getAppointmentId(): string {
    return this._info.appointmentId;
  }

  setAppointmentId(value: string) {
    if (!this._info.appointmentId) {
      this._info.appointmentId = value;
    }
  }

  isIntegrationInteractionAvailable(): boolean {
    return this._info.customerId !== DEFAULT_CUSTOMER_ID;
  }

  // this is now available in the interaction details
  // isTranscriptAvailable(): boolean {
  //   return this._info.type !== ConversationTypeEnum.callback;
  // }

  toString() {
    return JSON.stringify({
      id: this.getId(),
      room: this.getRoom(),
      origin: this.getOrigin(),
      type: this.getType(),
      communicationId: this.getCommunicationId(),
      customerId: this.getCustomerId(),
      customerEmail: this.getCustomerEmail(),
      customerName: this.getCustomerName(),
      customerPhoneNumber: this.getCustomerPhoneNumber(),
      parentFrameUrl: this.getParentFrameUrl(),
      originMode: this.getOriginMode(),
      destination: this.getDestination(),
      channel: this.getChannel(),
      appointmentId: this.getAppointmentId(),
      metadata: mapToJSON(this._meta),
      integrationData: this._info,
      integrationType: ApplicationTypeEnum.Genesys,
    });
  }

  getCustomerLink(ticketUrl: string): string {
    switch (this._info?.type) {
      case ConversationTypeEnum.voiceCall:
        const l = new URL(ticketUrl);
        // we need to state call type because on audio calls, we hide the cam button
        l.searchParams.set(PARAM_CALL_TYPE, ConversationTypeEnum.voiceCall);
        return l.toString();
      default:
        return ticketUrl;
    }
  }
}
