import { Component, OnInit } from "@angular/core";
import { InteractionService } from "../../services/interaction.service";
import { IInteraction } from "../../../core-ui/models/IInteraction";
import { ConversationTypeEnum } from "../../../core-ui/core-ui.enums";
import { AnalyticsService } from "../../../core-ui/services/analytics.service";
import { ApplicationService } from "../../../core-ui/services/application.service";
import { Router } from "@angular/router";
import { sessionStore } from "@auvious/utils";
import { CobrowseService } from "../../../core-ui/services/cobrowse.service";
import { KEY_COBROWSE_INTERACTION } from "../../app.enums";
import { IConversationEventHandlers } from "../../../core-ui/models/IConversationEventHandlers";
import { SketchService } from "../../../core-ui/services/sketch.service";
import { GenericErrorHandler } from "../../services/error-handlers.service";
import { BasePage } from "../../../core-ui/models/BasePage";

@Component({
  selector: "app-page-cobrowse",
  templateUrl: "./cobrowse.component.html",
  styleUrls: ["./cobrowse.component.scss"],
})
export class CobrowsePageComponent extends BasePage implements OnInit {
  interaction: IInteraction;
  isTalkdeskApp = false;
  private sketchId: string;

  constructor(
    private interactionService: InteractionService,
    private analyticsService: AnalyticsService,
    private applicationService: ApplicationService,
    private cobrowseService: CobrowseService,
    private sketchService: SketchService,
    private router: Router,
    private errorHandler: GenericErrorHandler
  ) {
    super();
    this.isTalkdeskApp = this.applicationService.isTalkdeskApp;
  }

  ngOnInit(): void {
    this.interaction = this.interactionService.getActiveInteraction();
    this.registerConversationEventHandlers();
  }

  async cobrowseEnded() {
    // todo: check if interaction is something else ?
    switch (this.interaction?.getType()) {
      case ConversationTypeEnum.cobrowse:
        // todo: if we have a genesys interaction active as a co-browse, set 'false' to 'true' (track in genesys)
        this.analyticsService.trackCallEnded(this.interaction, false);
        this.interactionService.clearActiveInteraction();
        break;
      case ConversationTypeEnum.callback:
      case ConversationTypeEnum.chat:
        // clear room
        this.interaction.setRoom(null);
        this.interactionService.setActiveInteraction(this.interaction);
        try {
          this.activity.loading(true, "Ending co-browse...");
          await this.analyticsService.trackConferenceRoomEnded(
            this.interaction
          );
        } catch (ex) {
        } finally {
          this.activity.loading(false);
        }
        break;
    }
    this.leave();
  }

  leave() {
    sessionStore.removeItem(KEY_COBROWSE_INTERACTION);
    this.router.navigate(["/", "welcome"], { queryParamsHandling: "preserve" });
  }

  registerConversationEventHandlers() {
    const handlers: IConversationEventHandlers = {
      ending: (_) => this.end(),
      transferring: (_) => this.end(),
    };
    this.interactionService.registerConversationEventHandlers(handlers);
  }

  sketchConnected(connectionId: string) {
    this.sketchId = connectionId;
  }

  private reset() {
    this.interaction = null;
    this.interactionService.clearActiveInteraction();
  }

  private end() {
    try {
      // terminate cobrowse, will send a terminate request that sketch will get to clean up.
      this.cobrowseService.terminate();
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
    try {
      this.sketchService.terminate(this.sketchId);
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
    switch (this.interaction.getType()) {
      case ConversationTypeEnum.cobrowse:
        // wait for a cobrowseEnded event to track that it ended
        break;
      case ConversationTypeEnum.callback:
      case ConversationTypeEnum.chat:
        this.analyticsService.trackCallEnded(this.interaction, true);
        this.reset();
    }
    this.activity.loading(true, "Ending co-browse...");
  }
}
