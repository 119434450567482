import { Injectable } from "@angular/core";
import { VAD } from "@auvious/media-tools";
import { IStream, StreamTypes } from "@auvious/rtc";
import { merge, Subject } from "rxjs";
import { StreamTrackKindEnum } from "../core-ui.enums";

import { IVolume } from "../models";
import { ConferenceService } from "./conference.service";
import { LocalMediaService } from "./local.media.service";
import { AuviousRtcService } from "./rtc.service";

@Injectable()
export class VoiceDetectionService {
  private _vadStreamState = new Subject<{ id: string; enabled: boolean }>();
  public vadStreamState$ = this._vadStreamState.asObservable();

  private _volume = new Subject<IVolume>();
  public volumeLevel$ = this._volume.asObservable();

  private vads = new Map<MediaStreamTrack, VAD>();

  constructor(
    private rtc: AuviousRtcService,
    private conference: ConferenceService,
    private local: LocalMediaService
  ) {
    this.local.localStreamReady$.subscribe((stream) => {
      if (
        stream.type === StreamTypes.MIC ||
        stream.type === StreamTypes.VIDEO
      ) {
        this.attach(stream);
      }
    });

    this.conference.streamAdded$.subscribe((stream) => {
      if (
        stream.originator.endpoint !== this.rtc.endpoint() &&
        (stream.type === StreamTypes.MIC || stream.type === StreamTypes.VIDEO)
      ) {
        this.attach(stream);
      }
    });

    this.local.streamRemoved$.subscribe((stream) => {
      // stream is removed but the audio track might be used in the next one
      if (!this.local.hasLiveInput(StreamTrackKindEnum.audio)) {
        this.detach(stream);
      }
    });

    this.conference.streamRemoved$.subscribe((stream) => {
      if (stream.originator.endpoint !== this.rtc.endpoint()) {
        this.detach(stream);
      }
    });

    merge(
      this.local.streamMutedChange$,
      this.conference.streamMutedChange$
    ).subscribe((event) => {
      if (event.trackKind === StreamTrackKindEnum.video) {
        return;
      }

      if (event.muted) {
        this.pause(event.stream);
      } else {
        this.resume(event.stream);
      }
    });
  }

  public volumeChanged(speaking: boolean, level: number, streamId: string) {
    this._volume.next({ level: level * 0.6, streamId, speaking });
  }

  public attach(stream: IStream) {
    const audio = stream.mediaStream?.getAudioTracks()[0];

    if (this.vads.has(audio)) {
      return;
    }

    const vad = new VAD();
    this.vads.set(audio, vad);

    if (stream.originator.endpoint === this.rtc.endpoint()) {
      vad.events.on("activity", (activity) =>
        this.volumeChanged(
          activity.speech,
          activity.volume,
          this.local.mainStream.stream?.id
        )
      );

      this.local.pipe.addEffect(vad);
    } else {
      vad.events.on("activity", (activity) =>
        this.volumeChanged(activity.speech, activity.volume, stream.id)
      );

      vad.setup();
      vad.setInput(stream.mediaStream);
      stream.mediaStream
        .getAudioTracks()[0]
        ?.addEventListener("ended", () => this.pause(stream));
    }

    this._vadStreamState.next({ id: stream.id, enabled: true });
  }

  public detach(stream: IStream) {
    const audio = stream.mediaStream?.getAudioTracks()[0];
    const vad = this.vads.get(audio);

    if (vad) {
      if (stream.originator.endpoint === this.rtc.endpoint()) {
        this.local.pipe.removeEffect(vad);
      }

      this.volumeChanged(false, 0, stream.id);

      vad.dispose();
      this.vads.delete(audio);

      this._vadStreamState.next({ id: stream.id, enabled: false });
    }
  }

  public pause(stream: IStream) {
    this.vads.get(stream.mediaStream?.getAudioTracks()[0])?.stop();
  }

  public resume(stream: IStream) {
    this.vads.get(stream.mediaStream?.getAudioTracks()[0])?.setup();
  }

  public isEnabled(stream: IStream) {
    return this.vads.has(stream.mediaStream?.getAudioTracks()[0]);
  }
}
