import { base64UrlToBase64, debugError } from "../services";
import { ITokenResponse } from "./ITokenResponse";

export class OAuth2TokenHelper {
  public static saveToken(key, token) {
    try {
      sessionStorage.setItem(key, JSON.stringify(token));
    } catch {}
  }

  public static getToken(key): ITokenResponse {
    let token: ITokenResponse;
    try {
      token = JSON.parse(sessionStorage.getItem(key));
    } catch {
      token = null;
    }
    return token;
  }

  public static isTokenExpired(token: ITokenResponse): boolean {
    try {
      const base64 = base64UrlToBase64(token.access_token.split(".")[1]);
      if (!base64) {
        return true;
      }
      const accessTokenObj = JSON.parse(atob(base64)) as any;

      // if exp is missing then we have a non-expired token
      if (!accessTokenObj.exp) {
        debugError("non-expiring token detected!");
        return false;
      }

      const exp = new Date(accessTokenObj.exp * 1000);

      return Date.now() > 1000 * accessTokenObj.exp;
    } catch (error) {
      debugError("error while checking token expiration: token", token, error);
    }

    return true;
  }

  public static isRefreshTokenExpired(token: ITokenResponse): boolean {
    try {
      // refresh token is now a short code and not base64, so we just check if it exists
      return !token.refresh_token;
    } catch (error) {
      debugError("error while checking token expiration", error);
    }

    return true;
  }
}
