import { IInteraction } from "../../../core-ui/models/IInteraction";
import { IInteractionStrategy } from "../../../core-ui/models/strategies";

export class DefaultInteractionStrategy implements IInteractionStrategy {
  private interaction: IInteraction;

  constructor() {}

  isActiveInteractionAvailable(): boolean {
    return !!this.interaction;
  }

  getActiveInteraction(): IInteraction {
    return this.interaction;
  }

  setActiveInteraction(interaction: IInteraction) {
    this.interaction = interaction;
  }

  clearActiveInteraction() {
    this.interaction = null;
  }

  discoverActiveInteraction() {
    return null;
  }

  retrieveInteractionData() {
    return null;
  }

  registerConversationEventHandlers(handlers) {}

  prepareInteractionScheduleRequest() {
    return null;
  }

  scheduleInteraction() {
    return null;
  }

  createEmailInteraction() {
    return null;
  }

  createSMSInteraction() {
    return null;
  }

  invite() {
    return null;
  }

  postMessageToInteraction() {
    return null;
  }

  getChatTranscript() {
    return null;
  }

  getInteractionDetails() {
    return null;
  }
}
