import { Injectable } from "@angular/core";

import { IApplication } from "../../core-ui/models/IApplication";
import { IInteractionStrategy } from "../../core-ui/models/strategies";
import {
  IInteraction,
  IInteractionMessageOptions,
  IInteractionScheduleRequest,
} from "../../core-ui/models/IInteraction";
import {
  AppConfigService,
  ApplicationService,
  CLAIM_TERMS_ACCEPTED,
  CLAIM_TERMS_ACCEPTED_AT,
  ConversationChannelEnum,
  CustomerParam,
  IConversationEventHandlers,
  OriginModeEnum,
  UserService,
} from "../../core-ui";
import { KEY_TERMS, KEY_TERMS_ACCEPTED_AT } from "../app.enums";
import { sessionStore } from "@auvious/utils";
import { ConversationTypeEnum } from "@auvious/integrations";

@Injectable()
export class InteractionService implements IInteractionStrategy {
  constructor(
    private applicationService: ApplicationService,
    private config: AppConfigService,
    private user: UserService
  ) {}

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private get impl(): IInteractionStrategy {
    return this.application.interactionStrategy();
  }

  isActiveInteractionAvailable(): boolean {
    return this.impl.isActiveInteractionAvailable();
  }

  getActiveInteraction(): IInteraction {
    return this.impl.getActiveInteraction();
  }

  setActiveInteraction(interaction: IInteraction) {
    this.impl.setActiveInteraction(interaction);
  }

  clearActiveInteraction() {
    this.impl.clearActiveInteraction();
  }

  retrieveInteractionData(
    id: string,
    channel?: ConversationChannelEnum
  ): Promise<IInteraction> {
    return this.impl.retrieveInteractionData(id, channel);
  }

  discoverActiveInteraction(): Promise<IInteraction> {
    return this.impl.discoverActiveInteraction();
  }

  registerConversationEventHandlers(handlers: IConversationEventHandlers) {
    this.impl.registerConversationEventHandlers(handlers);
  }

  prepareInteractionScheduleRequest(
    queueId: string,
    agentId: string,
    name: string,
    phoneNumber: string,
    scheduledAt: string,
    metadata: { [key: string]: string }
  ) {
    return this.impl.prepareInteractionScheduleRequest(
      queueId,
      agentId,
      name,
      phoneNumber,
      scheduledAt,
      metadata
    );
  }
  scheduleInteraction(request: IInteractionScheduleRequest) {
    return this.impl.scheduleInteraction(request);
  }

  createEmailInteraction(
    queueId: string,
    toAddress: string,
    message: string,
    messageHTML?: string,
    subject?: string,
    options?: IInteractionMessageOptions
  ): Promise<void> {
    return this.impl.createEmailInteraction(
      queueId,
      toAddress,
      message,
      messageHTML,
      subject,
      options
    );
  }

  createSMSInteraction(queueId: string, toNumber: string): Promise<any> {
    return this.impl.createSMSInteraction(queueId, toNumber);
  }

  invite(
    toConversationType: ConversationTypeEnum,
    interaction: IInteraction,
    message: string,
    options?: IInteractionMessageOptions
  ) {
    return this.impl.invite(toConversationType, interaction, message, options);
  }

  postMessageToInteraction(
    interaction: IInteraction,
    message: string,
    options?: IInteractionMessageOptions
  ): Promise<void> {
    return this.impl.postMessageToInteraction(interaction, message, options);
  }

  getChatTranscript(
    interactionId: string,
    channel: ConversationChannelEnum,
    fromStorageProvider?: boolean
  ) {
    return this.impl.getChatTranscript(
      interactionId,
      channel,
      fromStorageProvider
    );
  }

  getInteractionDetails(interactionId: string) {
    return this.impl.getInteractionDetails(interactionId);
  }

  hasToAcceptTerms(): boolean {
    return this.config.customerParamEnabled(CustomerParam.DISCLAIMER_ENABLED);
  }

  hasAcceptedTerms(): boolean {
    const acceptedSessionVersion = Number(sessionStore.getItem(KEY_TERMS) ?? 0);

    return (
      !this.config.customerParamEnabled(CustomerParam.DISCLAIMER_ENABLED) ||
      acceptedSessionVersion ===
        Number(this.config.customerParam(CustomerParam.DISCLAIMER_VERSION))
    );
  }

  acceptTerms(): void {
    if (
      this.impl.getActiveInteraction()?.getOriginMode() !== OriginModeEnum.kiosk
    ) {
      sessionStore.setItem(
        KEY_TERMS,
        this.config.customerParam(CustomerParam.DISCLAIMER_VERSION)
      );
      sessionStore.setItem(KEY_TERMS_ACCEPTED_AT, new Date().toISOString());
    }
  }

  saveAcceptedTerms(termsSnapshot: string) {
    const user = this.user.getActiveUser();
    user.setClaim(CLAIM_TERMS_ACCEPTED, termsSnapshot);
    const acceptedAt =
      sessionStore.getItem(KEY_TERMS_ACCEPTED_AT) || new Date().toISOString();
    user.setClaim(CLAIM_TERMS_ACCEPTED_AT, acceptedAt);
  }
}
