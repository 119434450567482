<!-- highlighter -->
<av-tooltip [position]="tooltipPosition" message="Marker">
  <button
    [attr.aria-label]="(markerOn ? '' : 'Enable') + ' marker'"
    avButton
    [disabled]="!room || disabled"
    ofType="icon"
    size="xs"
    [color]="markerOn ? 'accent' : 'basic'"
    (click)="marker()"
    [raised]="true"
    data-tid="sketch-controls/marker"
  >
    <av-icon name="marker"></av-icon>
  </button>
</av-tooltip>

<!-- arrow pointer -->
<av-tooltip [position]="tooltipPosition" message="Arrow">
  <button
    avButton
    [raised]="true"
    [attr.aria-label]="(arrowOn ? '' : 'Enable') + ' arrow line'"
    [disabled]="!room || disabled"
    ofType="icon"
    size="xs"
    [color]="arrowOn ? 'accent' : 'basic'"
    (click)="arrow()"
    data-tid="sketch-controls/arrow"
  >
    <av-icon name="arrow-up-right"></av-icon>
  </button>
</av-tooltip>

<!-- eraser -->
<av-tooltip [position]="tooltipPosition" message="Eraser">
  <button
    avButton
    [raised]="true"
    [attr.aria-label]="(eraserOn ? '' : 'Enable') + ' eraser'"
    [disabled]="!room || disabled"
    ofType="icon"
    size="xs"
    [color]="eraserOn ? 'accent' : 'basic'"
    (click)="eraser()"
    data-tid="sketch-controls/eraser"
  >
    <av-icon name="eraser"></av-icon>
  </button>
</av-tooltip>

<!-- clear -->
<av-confirm
  [open]="confirmClear"
  class="confirm-{{ confirmPosition }}"
  [arrow]="confirmPosition"
  (canceled)="confirmClear = false"
  (confirmed)="clear()"
>
  <av-tooltip class="trash" [position]="tooltipPosition" message="Clear">
    <button
      [raised]="true"
      avButton
      aria-label="Clear annotations"
      [disabled]="!room || disabled"
      ofType="icon"
      size="xs"
      color="basic"
      (click)="confirmClear = !confirmClear"
      data-tid="sketch-controls/clear"
    >
      <av-icon name="trash"></av-icon>
    </button>
  </av-tooltip>
  <span confirmTitle translate>Are you sure you want to continue?</span>
  <div confirmBody translate>This action will remove all annotations.</div>
</av-confirm>
