<av-tooltip
  *ngIf="!isScreenShareLocal"
  message="Share your screen"
  [enabled]="!isScreenShareActive"
>
  <button
    aria-label="Share screen or window"
    avButton
    ofType="icon"
    [size]="size"
    color="primary"
    id="btn-screen-share-start"
    (click)="toggleScreenShare()"
    [disabled]="isDisabled"
    [raised]="true"
    data-tid="display-capture/start"
  >
    <av-icon name="screen"></av-icon>
  </button>
</av-tooltip>

<av-tooltip *ngIf="isScreenShareLocal" message="Stop sharing">
  <button
    aria-label="Stop sharing screen or window"
    avButton
    ofType="icon"
    [size]="size"
    color="danger"
    id="btn-screen-share-stop"
    [disabled]="isOnHold"
    [class.pulse-danger]="!isOnHold"
    (click)="toggleScreenShare()"
    [raised]="true"
    data-tid="display-capture/stop"
  >
    <av-icon name="screen-stop"></av-icon>
  </button>
</av-tooltip>
