import moment, { Duration } from "moment";
import {
  ConversationDestinationEnum,
  ConversationOriginEnum,
  ConversationTypeEnum,
  IInteractionDetails,
  IInteractionMetrics,
  IRecorderInfo,
  RecorderStateEnum,
  OriginModeEnum,
  ConversationChannelEnum,
  InteractionMetricEnum,
} from "../../core-ui";
import {
  TAG_CUSTOMER_CALLBACK_ORIGIN,
  TAG_CUSTOMER_ENDED_AT,
  TAG_CUSTOMER_RECORDING_INSTANCE_ID,
  TAG_CUSTOMER_RECORDING_RECORDER_ID,
  TAG_CUSTOMER_REQUESTED_AT,
  WIDGET_CONVERSATION_TYPE,
  WIDGET_MODE,
  WIDGET_REFERRER_ID,
} from "../app.enums";
import { IGenesysConversation } from "./IGenesysUser";
import { PureCloudService } from "../services";

/**
 * @deprecated
 * parses customer attributes stored by auvious
 */
export class GenesysInteractionMetrics implements IInteractionMetrics {
  private _callOrigin: ConversationOriginEnum;
  private _callType: ConversationTypeEnum;
  private _callOriginMode: OriginModeEnum;
  private _callDuration: Duration;
  private _recorderInfo: IRecorderInfo;
  private _callStartAt: Date;
  private _callEndAt: Date;

  constructor(private _info: IGenesysConversation) {
    const customers = _info.participants.filter(
      (p) => p.purpose === "customer"
    );

    // retrieve auvious attributes from customer attributes
    const customer = customers?.[0];
    const attributes = customer.attributes;
    if (!attributes) {
      return;
    }

    const cTypeKey = `context.${WIDGET_CONVERSATION_TYPE}`;

    this._callType =
      cTypeKey in attributes
        ? (attributes[cTypeKey] as ConversationTypeEnum)
        : customer.callbacks.length > 0
        ? ConversationTypeEnum.callback
        : ConversationTypeEnum.videoCall;

    if (customer.chats.length > 0) {
      this._callOrigin =
        `context.${WIDGET_REFERRER_ID}` in attributes
          ? ConversationOriginEnum.WIDGET
          : TAG_CUSTOMER_CALLBACK_ORIGIN
          ? attributes[TAG_CUSTOMER_CALLBACK_ORIGIN]
          : ConversationOriginEnum.CHAT;
    } else {
      this._callOrigin = ConversationOriginEnum.CHAT;
    }
    // else if (customer.callbacks.length > 0) {
    //     this._callOrigin = ConversationOriginEnum.CALLBACK;
    // }

    this._callStartAt = new Date(attributes[TAG_CUSTOMER_REQUESTED_AT]);
    this._callEndAt = new Date(attributes[TAG_CUSTOMER_ENDED_AT]);
    this._callDuration = moment.duration(
      moment(this._callEndAt).diff(moment(this._callStartAt))
    );

    this._callOriginMode = !!attributes[`context.${WIDGET_MODE}`]
      ? attributes[`context.${WIDGET_MODE}`]
      : OriginModeEnum.website;

    /* @deprecated */
    // this._recordingUrl = attributes[TAG_CUSTOMER_RECORDING_URL] || '-';
    // this._recordingStorageProvider = attributes[TAG_CUSTOMER_RECORDING_STORAGE_PROVIDER] || '-';

    if (attributes[TAG_CUSTOMER_RECORDING_INSTANCE_ID]) {
      this._recorderInfo = {
        instanceId: attributes[TAG_CUSTOMER_RECORDING_INSTANCE_ID],
        recorderId: attributes[TAG_CUSTOMER_RECORDING_RECORDER_ID],
        conversationId: this._info.id,
        state: RecorderStateEnum.stopped,
      };
    }
  }
  // video call metrics
  getId() {
    return this._info.id;
  }
  getCallType() {
    return this._callType;
  }
  getCallOrigin() {
    return this._callOrigin;
  }
  getCallStartAt() {
    return this._callStartAt;
  }
  getCallEndAt() {
    return this._callEndAt;
  }
  getCallDuration() {
    return this._callDuration;
  }
  getCallDestination() {
    return ConversationDestinationEnum.EMBEDDED;
  }
  getCallOriginMode() {
    return this._callOriginMode;
  }
  getCallRecorderInfo() {
    return this._recorderInfo;
  }
  getCobrowseRecorderInfo() {
    return null;
  }
  getOriginUrl(): string {
    return null;
  }
  getCreatedBy(): string {
    return null;
  }
  getCustomerDisplayName(): string {
    return null;
  }
  getCustomerMetadata(): { [key: string]: any } {
    return null;
  }
  getCustomerId(): string {
    return null;
  }
  getTermsAcceptedAt(): Date {
    return null;
  }
  getTermsAcceptedSnapshot(): string {
    return null;
  }
  getIntegrationInteractionAvailable(): boolean {
    return true;
  }
  setMetric(key: InteractionMetricEnum, value: any) {
    switch (key) {
      case InteractionMetricEnum.callEndAt:
        this._callEndAt = value;
        break;
      case InteractionMetricEnum.callStartAt:
        this._callStartAt = value;
        break;
    }
    this._callDuration = moment.duration(
      moment(this._callEndAt).diff(moment(this._callStartAt))
    );
  }

  getMetric(key: InteractionMetricEnum) {
    return null;
  }
}

/**
 * Basic Genesys interaction details as received from their service
 */
export class GenesysInteractionDetails implements IInteractionDetails {
  private _startAt: Date;
  private _endAt: Date;
  private _from: string[];
  private _to: string[];
  private _queueName: string;
  private _duration: Duration;
  private _channel: ConversationChannelEnum;

  constructor(private _info: IGenesysConversation) {
    this._startAt = _info.startTime && new Date(_info.startTime);
    this._endAt = _info.endTime && new Date(_info.endTime);
    const customers = _info.participants.filter(
      (p) => p.purpose === "customer"
    );
    const agents = _info.participants.filter((p) => p.purpose === "agent");
    this._from = customers?.map((c) => c.name);
    this._to = agents?.map((a) => a.name);
    this._queueName = customers?.[0].queueName;
    this._duration =
      this._endAt &&
      this._startAt &&
      moment.duration(moment(this._endAt).diff(moment(this._startAt)));

    const agent = agents[0];
    if (agent.chats?.length > 0) {
      this._channel = ConversationChannelEnum.genesysWebChat;
    } else if (agent.callbacks?.length > 0) {
      this._channel = ConversationChannelEnum.genesysCallback;
    } else if (agent.calls?.length > 0) {
      this._channel = ConversationChannelEnum.genesysCall;
    } else if (agent.messages?.length > 0) {
      this._channel = PureCloudService.getConversationChannel(
        agent.messages[0]
      );
    }
  }
  getId() {
    return this._info.id;
  }
  getDuration() {
    return this._duration;
  }
  getExternalParticipants() {
    return this._from;
  }
  getInternalParticipants() {
    return this._to;
  }
  getStartAt() {
    return this._startAt;
  }
  getEndAt() {
    return this._endAt;
  }
  getQueue() {
    return this._queueName;
  }
  getChannel(): ConversationChannelEnum {
    return this._channel;
  }
  isTranscriptAvailable(): boolean {
    return this._channel !== ConversationChannelEnum.genesysCallback;
  }
}
