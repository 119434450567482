<button
  aria-label="Pick a date range"
  class="date-range-trigger"
  [ngClass]="{
    'date-range-trigger-open': open,
    'date-range-trigger-column': direction === 'column',
    'date-range-trigger-responsive': direction === 'responsive'
  }"
  (click)="open = !open"
>
  <!-- <av-icon name="calendar-time-thin"></av-icon> -->
  <div class="date-range">
    <b>{{
      startDate | amLocale : lang | amDateFormat : "yyyy-MM-DD, hh:mm A"
    }}</b>
  </div>
  <div>-</div>
  <div class="date-range">
    <b>{{
      endDate | amLocale : lang | amDateFormat : "yyyy-MM-DD, hh:mm A"
    }}</b>
  </div>
</button>

<app-date-range-picker-popup
  class="date-range-popup"
  [direction]="direction"
  [startDate]="startDate"
  [endDate]="endDate"
  *ngIf="open"
  (rangeChanged)="rangeChange($event)"
  (closed)="open = false"
  [@slideFromUp]="true"
></app-date-range-picker-popup>

<div
  class="backdrop"
  [class.backdrop-active]="open"
  (click)="open = false"
></div>
