import { ProviderLanguage } from "./ProviderLanguage";
import {
  IConferenceMetadataUpdatedEvent,
  IEndpoint,
  IConferenceMetadata,
  StreamType,
} from "@auvious/rtc";
import {
  ColorEnum,
  ConferenceMetadataKeyEnum,
  SketchToolEnum,
} from "../core-ui.enums";
import { ProviderOrganizationLanguage } from "./ProviderLanguage";

export interface ICustomerMetrics {
  ticketReceivedAt?: Date;
  joinedAt?: Date;
}

export class BaseMetadata implements IConferenceMetadata {
  public key = null;
  public lastModified = new Date().toISOString();

  constructor(protected _sender: IEndpoint) {}

  /**must return a serialized string of all the data to be held */
  get value() {
    return null;
  }
  get userId() {
    return this._sender.username;
  }
  get userEndpointId() {
    return this._sender.endpoint;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): IConferenceMetadata {
    throw new Error("implement");
  }

  public static fromData(data: IConferenceMetadata): IConferenceMetadata {
    throw new Error("implement");
  }
}

export class TransferMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.transfer;

  constructor(_sender: IEndpoint, private _on: boolean) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      on: this._on,
    });
  }

  get on(): boolean {
    return this._on;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): TransferMetadata {
    try {
      const value = JSON.parse(event.value);
      return new TransferMetadata(
        { endpoint: event.userEndpointId, username: event.userId },
        value.on
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): TransferMetadata {
    try {
      const value = JSON.parse(data.value);
      return new TransferMetadata(
        { endpoint: data.userEndpointId, username: data.userId },
        value.on
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

export class CustomerMetricsMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.customerMetrics;

  constructor(_sender: IEndpoint, private _metrics: ICustomerMetrics = {}) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      metrics: this._metrics,
    });
  }

  ticketReceivedAt(): Date {
    return !!this._metrics?.ticketReceivedAt
      ? new Date(this._metrics.ticketReceivedAt)
      : null;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): CustomerMetricsMetadata {
    try {
      const value = JSON.parse(event.value);
      return new CustomerMetricsMetadata(
        { endpoint: event.userEndpointId, username: event.userId },
        value.metrics
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): CustomerMetricsMetadata {
    try {
      const value = JSON.parse(data.value);
      return new CustomerMetricsMetadata(
        { endpoint: data.userEndpointId, username: data.userId },
        value.metrics
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

export class RecorderMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.recorder;

  constructor(_sender: IEndpoint, private _on: boolean) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      on: this._on,
    });
  }

  get on(): boolean {
    return this._on;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): RecorderMetadata {
    try {
      const value = JSON.parse(event.value);
      return new RecorderMetadata(
        { endpoint: event.userEndpointId, username: event.userId },
        value.on
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): RecorderMetadata {
    try {
      const value = JSON.parse(data.value);
      return new RecorderMetadata(
        { endpoint: data.userEndpointId, username: data.userId },
        value.on
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

export class SnapshotMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.snapshot;

  constructor(_sender: IEndpoint, private _target: IEndpoint) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      target: this._target,
    });
  }

  get target(): IEndpoint {
    return this._target;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): SnapshotMetadata {
    try {
      const value = JSON.parse(event.value);
      return new SnapshotMetadata(
        { endpoint: event.userEndpointId, username: event.userId },
        value.target
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): SnapshotMetadata {
    try {
      const value = JSON.parse(data.value);
      return new SnapshotMetadata(
        { endpoint: data.userEndpointId, username: data.userId },
        value.target
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

export class PointerColorMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.arPointerColors;

  constructor(
    _sender: IEndpoint,
    private _colorClaims: { [endpoint: string]: ColorEnum }
  ) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      colorClaims: this._colorClaims,
    });
  }

  get colorClaims(): { [endpoint: string]: ColorEnum } {
    return this._colorClaims;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): PointerColorMetadata {
    try {
      const value = JSON.parse(event.value);
      return new PointerColorMetadata(
        {
          endpoint: event.userEndpointId,
          username: event.userId,
        },
        value.colorClaims
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): PointerColorMetadata {
    try {
      const value = JSON.parse(data.value);
      return new PointerColorMetadata(
        {
          endpoint: data.userEndpointId,
          username: data.userId,
        },
        value.colorClaims
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

export class PointerMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.arPointer;

  constructor(
    _sender: IEndpoint,
    private _target: IEndpoint,
    private _streamType: StreamType
  ) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      target: this._target,
      streamType: this._streamType,
    });
  }

  get streamType(): StreamType | string {
    return this._streamType;
  }

  get target(): IEndpoint {
    return this._target;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): PointerMetadata {
    try {
      const value = JSON.parse(event.value);
      return new PointerMetadata(
        {
          endpoint: event.userEndpointId,
          username: event.userId,
        },
        value.target,
        value.streamType
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): PointerMetadata {
    try {
      const value = JSON.parse(data.value);
      return new PointerMetadata(
        {
          endpoint: data.userEndpointId,
          username: data.userId,
        },
        value.target,
        value.streamType
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

export class SpeechToTextMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.speechToText;
  constructor(
    _sender: IEndpoint,
    private _sourceLanguage: ProviderOrganizationLanguage,
    private _targetLanguage?: ProviderLanguage
  ) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      sourceLanguage: this._sourceLanguage,
      targetLanguage: this._targetLanguage,
    });
  }

  get sourceLanguage(): ProviderOrganizationLanguage {
    return this._sourceLanguage;
  }
  get targetLanguage(): ProviderLanguage {
    return this._targetLanguage;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): SpeechToTextMetadata {
    try {
      const value = JSON.parse(event.value);
      return new SpeechToTextMetadata(
        { endpoint: event.userEndpointId, username: event.userId },
        value.sourceLanguage,
        value.targetLanguage
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): SpeechToTextMetadata {
    try {
      const value = JSON.parse(data.value);
      return new SpeechToTextMetadata(
        { endpoint: data.userEndpointId, username: data.userId },
        value.sourceLanguage,
        value.targetLanguage
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

export class SketchMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.sketch;

  sketchMap: {
    [id: string]: {
      enabled: boolean;
      activeTool: SketchToolEnum;
      target: IEndpoint;
      mediaType: StreamType;
    };
  } = {};

  constructor(_sender: IEndpoint) {
    super(_sender);
  }

  get value() {
    return JSON.stringify(this.sketchMap);
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): SketchMetadata {
    try {
      const value = JSON.parse(event.value);
      const meta = new SketchMetadata({
        endpoint: event.userEndpointId,
        username: event.userId,
      });
      meta.setMap(value);
      return meta;
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): SketchMetadata {
    try {
      const value = JSON.parse(data.value);
      const meta = new SketchMetadata({
        endpoint: data.userEndpointId,
        username: data.userId,
      });
      meta.setMap(value);
      return meta;
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  setMap(map: {
    [id: string]: {
      enabled: boolean;
      activeTool: SketchToolEnum;
      target: IEndpoint;
      mediaType: StreamType;
    };
  }) {
    this.sketchMap = map;
  }

  setEnabled(
    id: string,
    enabled: boolean,
    target?: IEndpoint,
    mediaType?: StreamType
  ) {
    this.sketchMap = {
      [id]: {
        ...this.sketchMap[id],
        enabled,
        target,
        mediaType,
      },
    };
  }

  setActiveTool(id: string, activeTool: SketchToolEnum) {
    this.sketchMap = {
      [id]: {
        ...this.sketchMap[id],
        activeTool,
      },
    };
  }
  isEnabled(id) {
    return this.sketchMap[id].enabled;
  }
  getActiveTool(id) {
    return this.sketchMap[id].activeTool;
  }
  getTarget(id) {
    return this.sketchMap[id].target;
  }
  getMediaType(id) {
    return this.sketchMap[id].mediaType;
  }
}

export class CoBrowseMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.coBrowse;
  constructor(_sender: IEndpoint, private _target: IEndpoint) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      target: this._target,
    });
  }

  get target(): IEndpoint {
    return this._target;
  }

  get originator(): IEndpoint {
    return this._sender;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): CoBrowseMetadata {
    try {
      const value = JSON.parse(event.value);
      return new CoBrowseMetadata(
        { endpoint: event.userEndpointId, username: event.userId },
        value.target
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): CoBrowseMetadata {
    try {
      const value = JSON.parse(data.value);
      return new CoBrowseMetadata(
        { endpoint: data.userEndpointId, username: data.userId },
        value.target
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

export class IntegrationMetadata extends BaseMetadata {
  key = ConferenceMetadataKeyEnum.integration;

  constructor(_sender: IEndpoint, private _interactionId: string) {
    super(_sender);
  }

  get value() {
    return JSON.stringify({
      interactionId: this._interactionId,
    });
  }

  get interactionId() {
    return this._interactionId;
  }

  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): IntegrationMetadata {
    try {
      const value = JSON.parse(event.value);
      return new IntegrationMetadata(
        { endpoint: event.userEndpointId, username: event.userId },
        value.interactionId
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }

  public static fromData(data: IConferenceMetadata): IntegrationMetadata {
    try {
      const value = JSON.parse(data.value);
      return new IntegrationMetadata(
        { endpoint: data.userEndpointId, username: data.userId },
        value.interactionId
      );
    } catch (ex) {
      throw new Error("Invalid metadata");
    }
  }
}

// DO NOT FORGET to update tryNotifyForMetadata(..) whenever a new class is added

export class ConferenceMetadataFactory {
  public static fromEvent(
    event: IConferenceMetadataUpdatedEvent
  ): BaseMetadata {
    switch (event.key as ConferenceMetadataKeyEnum) {
      case ConferenceMetadataKeyEnum.snapshot:
        return SnapshotMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.arPointer:
        return PointerMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.arPointerColors:
        return PointerColorMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.sketch:
        return SketchMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.recorder:
        return RecorderMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.speechToText:
        return SpeechToTextMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.customerMetrics:
        return CustomerMetricsMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.transfer:
        return TransferMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.coBrowse:
        return CoBrowseMetadata.fromEvent(event);
      case ConferenceMetadataKeyEnum.integration:
        return IntegrationMetadata.fromEvent(event);
      default:
        return null;
    }
  }

  public static fromData(
    key: ConferenceMetadataKeyEnum,
    data: IConferenceMetadata
  ): BaseMetadata {
    switch (key) {
      case ConferenceMetadataKeyEnum.snapshot:
        return SnapshotMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.arPointer:
        return PointerMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.arPointerColors:
        return PointerColorMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.sketch:
        return SketchMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.recorder:
        return RecorderMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.speechToText:
        return SpeechToTextMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.customerMetrics:
        return CustomerMetricsMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.transfer:
        return TransferMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.coBrowse:
        return CoBrowseMetadata.fromData(data);
      case ConferenceMetadataKeyEnum.integration:
        return IntegrationMetadata.fromData(data);
      default:
        return null;
    }
  }
}
