<div class="effect" [ngSwitch]="effect.type">
  <ng-container *ngSwitchCase="typeNone">
    <av-icon aria-label="Remove background effect" name="none"></av-icon>
  </ng-container>
  <ng-container *ngSwitchCase="typeBlur">
    <av-icon aria-label="Blur background" name="background-blur"></av-icon>
  </ng-container>
  <div
    *ngSwitchCase="typeImage"
    class="fx-row fx-align-center fx-justify-center full-height"
  >
    <img #image aria-label="Set photo as background" *ngIf="url" [src]="url" (error)="failed()" />
    <av-spinner *ngIf="!url" mode="dark" size="xs"></av-spinner>
  </div>
</div>
