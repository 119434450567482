import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { sessionStore } from "@auvious/utils";
import { ISyncable } from "./ISyncable";
import { InteractionService } from "../../services/interaction.service";
import { KEY_COBROWSE_INTERACTION } from "../../app.enums";
import { Interaction, InteractionFactory } from "../../models/Interaction";
import { NotificationService } from "../../../core-ui/services/notification.service";
import { CobrowseService } from "../../../core-ui/services/cobrowse.service";
import { ActivityIndicatorService } from "../../../core-ui/services/activity-indicator.service";
import {
  KEY_COBROWSE_CONTEXT,
  KEY_COBROWSE_CURRENT_SENDER_ENDPOINT,
  KEY_COBROWSE_OLD_SENDER_ENDPOINTS,
  KEY_COBROWSE_SESSION_ID,
} from "../../../core-ui";

@Injectable({
  providedIn: "root",
})
export class CobrowseGuard  implements ISyncable {
  constructor(
    private router: Router,
    private interactionService: InteractionService,
    private notification: NotificationService,
    private cobrowse: CobrowseService,
    private activity: ActivityIndicatorService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return new Promise(async (resolve) => {
      // try restore a previous interaction
      this.activity.loading(true);
      let interaction = this.interactionService.getActiveInteraction();
      if (!interaction) {
        try {
          const interactionJSON = sessionStore.getItem(
            KEY_COBROWSE_INTERACTION
          );
          interaction = InteractionFactory.fromJSON(interactionJSON);
          this.interactionService.setActiveInteraction(interaction);

          // wait for the participant to join to leave
          this.cobrowse.participantJoined$.subscribe((p) => {
            this.activity.loading(false);
            !p ? this.leave() : resolve(true);
          });

          try {
            // we have a previous interaction, try join the session
            await this.cobrowse.join(interaction.getRoom());
            return resolve(true);
          } catch (e) {
            this.activity.loading(false);
            resolve(this.leave());
          }
        } catch (ex) {
          this.activity.loading(false);
          return resolve(this.leave());
        }
      } else {
        this.activity.loading(false);
        // store interaction
        const str = interaction.toString();
        sessionStore.setItem(KEY_COBROWSE_INTERACTION, str);
        resolve(true);
      }
    });
  }

  private leave(): UrlTree {
    sessionStore.removeItem(KEY_COBROWSE_INTERACTION);
    sessionStore.removeItem(KEY_COBROWSE_SESSION_ID);
    sessionStore.removeItem(KEY_COBROWSE_CONTEXT);
    sessionStore.removeItem(KEY_COBROWSE_CURRENT_SENDER_ENDPOINT);
    sessionStore.removeItem(KEY_COBROWSE_OLD_SENDER_ENDPOINTS);
    // leave, no interaction
    this.notification.error("Co-browse failed", {
      body: "Could not find and restore a previous session",
    });
    sessionStore.removeItem(KEY_COBROWSE_INTERACTION);
    // go back to welcome
    return this.router.createUrlTree(["/", "welcome"], {
      queryParamsHandling: "preserve",
    });
  }
}
