import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRouteSnapshot } from "@angular/router";
import {
  IJwtTokenResponse,
  IOAuth2Token,
} from "@auvious/common";

import { AuviousRtcService } from "../../services/rtc.service";
import { User } from "../User";
import { IUser } from "../IUser";
import { IAuthenticationStrategy } from "./IAuthenticationStrategy";
import { OAuth2TokenHelper } from "../OAuth2TokenHelper";
import { ITokenResponse } from "../ITokenResponse";
import { OAuth2CodeGrantAuthenticationServiceOptions } from "../OAuth2CodeGrantAuthenticationServiceOptions";
import { OAUTH2_STATE, KEY_REDIRECT_URI } from "../../core-ui.enums";
import { AuthState } from "../AuthState";

import { delay } from "../../services/utils";
import { UserRoleEnum } from "../../core-ui.enums";
import { sessionStore } from "@auvious/utils";
import { firstValueFrom } from "rxjs";
import { PublicParam } from "../application-options";
import { PARAM_GUEST } from "../../../app/app.enums";

export class OAuth2CodeGrantAuthenticationStrategy
  implements IAuthenticationStrategy
{
  persistAccessToken: boolean;
  usePopOutAuthentication: boolean;
  constructor(
    private rtcService: AuviousRtcService,
    private httpClient: HttpClient,
    private options: OAuth2CodeGrantAuthenticationServiceOptions
  ) {
    this.persistAccessToken =
      options.config.publicParameters?.[
        PublicParam.ACCESS_TOKEN_AGENT_SESSION_STORE_ENABLED
      ] ?? true;
    this.usePopOutAuthentication =
      options.config.publicParameters?.[
        PublicParam.POPUP_AUTHENTICATION_ENABLED
      ] ?? false;
  }

  private isPopup() {
    return (
      window.opener && window.opener !== window && !window.menubar?.visible
    );
  }

  async authenticate(route: ActivatedRouteSnapshot): Promise<IUser> {
    if (this.isPopup()) {
      // since we are using a 1 second interval for the popup, there is a race condition
      // where the popup was redirected back to auvious and has already used the code to get the token
      // before the next interval has detected that the window.location has changed in order to close the popup
      // add a delay just to wait for the popup to close (and not use the code)
      await delay(5000);
    }
    await this.options.beforeAuthenticate?.(route);
    const queryParams = new URLSearchParams(route.queryParams);

    const code = queryParams.get("code");
    const state = queryParams.get("state");
    const error = queryParams.get("error");
    const errorDescription = queryParams.get("error_description");

    if (error) {
      // avoid redirect-loop by checking error parameter that
      // genesys cloud sends in case of invalid scope
      throw new Error(`${error}: ${errorDescription}`);
    }

    if (!code || !state) {
      return this.usePopOutAuthentication
        ? new Promise(() => this.popOutForCode(queryParams))
        : new Promise(() => this.redirectForCode(queryParams));

      // if (this.options.usePopOutAuthentication) {
      //   return new Promise(() => this.popOutForCode(queryParams));
      //   // const params = await this.popOutForCode(queryParams);
      //   // code = params.get("code");
      //   // state = params.get("state");
      // } else {
      //   return new Promise(() => this.redirectForCode(queryParams));
      // }
    }

    const storedToken = OAuth2TokenHelper.getToken(code);
    let userToken: IOAuth2Token;

    const auviousCommonClient =
      await this.rtcService.createAuviousCommonClient();

    if (!storedToken) {
      if (
        this.persistAccessToken &&
        state !== sessionStore.getItem(OAUTH2_STATE)
      ) {
        sessionStore.removeItem(OAUTH2_STATE);
        throw new Error("state-mismatch");
      }
      sessionStore.removeItem(OAUTH2_STATE);

      userToken = await auviousCommonClient.loginTemplate(async () => {
        try {
          return await this.getAccessToken(code);
        } catch (ex) {
          throw ex;
          // WARN! this causes a redirect loop if the token auth goes wrong
          // probably coming from a refresh where we switched from session token to stateless token
          // return this.usePopOutAuthentication
          //   ? new Promise(() => this.popOutForCode(queryParams))
          //   : new Promise(() => this.redirectForCode(queryParams));
        }
      }, this.refreshTokenFn(code));
    } else if (
      OAuth2TokenHelper.isTokenExpired(storedToken) &&
      (!storedToken.refresh_token ||
        OAuth2TokenHelper.isRefreshTokenExpired(storedToken))
    ) {
      // probably a refresh after a standby/suspend?
      userToken = await auviousCommonClient.loginTemplate(
        async () => this.getAccessToken(code),
        this.refreshTokenFn(code)
      );
    } else {
      // probably a refresh. If we have a refresh token, let's try it now, because if it fails later, we're screwed
      if (
        !!storedToken.refresh_token &&
        !OAuth2TokenHelper.isRefreshTokenExpired(storedToken)
      ) {
        try {
          await this.refreshTokenFn(code)(storedToken.refresh_token);
        } catch (ex) {
          // refresh-token failed so delete cached token (clear sessionStorage) and reload without code,
          // so that we login again.
          try {
            sessionStore.clear();
          } catch {}

          const url = new URL(window.location.toString());
          url.searchParams.delete("code");
          window.location.replace(url.toString());
          await delay(5000);
          throw ex;
        }
        const newToken = OAuth2TokenHelper.getToken(code);
        userToken = await auviousCommonClient.loginTemplate(
          () => Promise.resolve(newToken),
          this.refreshTokenFn(code)
        );
      } else {
        userToken = await auviousCommonClient.loginTemplate(() => {
          this.options.onTokenResponse?.(storedToken);
          return Promise.resolve(storedToken);
        }, this.refreshTokenFn(code));
      }
    }

    await this.options.afterAuthenticate?.();

    const user = new User(userToken);

    // check for guest agent
    if (queryParams.get(PARAM_GUEST) === "true") {
      user.addRole(UserRoleEnum.guestAgent);
    } else if (queryParams.has("state")) {
      const astate = AuthState.fromSerializedState(queryParams.get("state"));
      if (astate.guest) {
        user.addRole(UserRoleEnum.guestAgent);
      }
    }

    return user;
  }

  /**
   * construct a url with all the required authorization params
   */
  private async getAuthorizationUrl(
    queryParams: URLSearchParams
  ): Promise<URL> {
    const state = AuthState.fromQueryParamMap(queryParams).serialize();
    sessionStore.setItem(OAUTH2_STATE, state);

    this.storeRedirectPath();

    const authProvider = await this.options.authProvider();

    const url = new URL(authProvider.authorizationUrl);
    url.searchParams.append("response_type", "code");
    url.searchParams.append("state", state);
    url.searchParams.append("client_id", authProvider.clientId);
    if (authProvider.scope) {
      url.searchParams.append("scope", authProvider.scope);
    }
    url.searchParams.append("redirect_uri", this.getRedirectPath());

    if (!!authProvider.authorizationUrlExtraParameters) {
      for (const [key, value] of Object.entries(
        authProvider.authorizationUrlExtraParameters
      )) {
        url.searchParams.append(key, value);
      }
    }
    return url;
  }

  /**
   * open a window popup to the auth url and wait for the redirect to get the code and close the popup.
   */
  private async popOutForCode(queryParams: URLSearchParams): Promise<void> {
    const authorizationUrl = await this.getAuthorizationUrl(queryParams);

    let popup = window.open(
      authorizationUrl.toString(),
      "authenticate",
      "popup=true,width=400,height=600"
    );

    const popupDetectionTimer = setInterval(async () => {
      if (popup?.closed) {
        clearInterval(popupDetectionTimer);
        popup = undefined;
      } else {
        //  we got redirected back to auvious
        if (!popup.window.location.href.includes(authorizationUrl.host)) {
          popup.close();
          // get the code
          const url = new URL(popup.window.location.href);
          // clear timer
          clearInterval(popupDetectionTimer);
          popup = undefined;
          // replace query params
          const newUrl = new URL(this.options.redirectUri);
          // pass all the redirected to our selves
          url.searchParams.forEach((value, key) =>
            newUrl.searchParams.set(key, value)
          );
          // I could not find a way to use window.history.replaceState within angular so as to avoid the refresh.
          // angular redirected back to the previous url because history.replaceState was happening outside angular.
          window.location.replace(url.toString());
          await delay(5000);
        }
      }
    }, 1000);
  }

  private async redirectForCode(queryParams: URLSearchParams) {
    const url = await this.getAuthorizationUrl(queryParams);
    window.location.replace(url.toString());
    // delay here, so that noone manages to route elsewhere
    await delay(5000);
  }

  private storeRedirectPath() {
    // keep the current page so that when we come back from the redirect, to move to that page
    // this way we always redirect to one specific page and from that page to whatever the target url was
    sessionStore.setItem(KEY_REDIRECT_URI, window.location.pathname);
  }

  private getRedirectPath() {
    // Always redirecto to same page, a guard at that page checks for a sessionStorage key to redirect accordingly
    return this.options.redirectUri;
    // return window.location.origin + window.location.pathname;
  }

  private async getAccessToken(code: string): Promise<ITokenResponse> {
    const params = new URLSearchParams();
    params.set("client_id", this.options.clientId);
    params.set("grant_type", "password");
    params.set("code", code);
    params.set("redirect_uri", this.getRedirectPath());

    this.options.hydratePasswordParams?.(params);

    const options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      ),
    };

    // try {
    const tokenResponse = await firstValueFrom(
      this.httpClient.post<ITokenResponse>(
        "/security/oauth/token",
        params.toString(),
        options
      )
    );

    if (this.persistAccessToken) {
      // Save access token
      OAuth2TokenHelper.saveToken(code, tokenResponse);
    }
    this.options.onTokenResponse?.(tokenResponse);

    return tokenResponse;
  }

  private refreshTokenFn(
    code
  ): (refreshToken: string) => Promise<IJwtTokenResponse> {
    return async (refreshToken: string): Promise<IJwtTokenResponse> => {
      const params = new URLSearchParams();
      params.set("client_id", this.options.clientId);
      params.set("grant_type", "refresh_token");
      params.set("refresh_token", refreshToken);

      const options = {
        headers: new HttpHeaders().set(
          "Content-Type",
          "application/x-www-form-urlencoded"
        ),
      };

      const tokenResponse = await firstValueFrom(
        this.httpClient.post<ITokenResponse>(
          "/security/oauth/token",
          params.toString(),
          options
        )
      );

      if (this.persistAccessToken) {
        // Save access token
        OAuth2TokenHelper.saveToken(code, tokenResponse);
      }
      if (!!this.options.onTokenResponse) {
        this.options.onTokenResponse(tokenResponse);
      }

      return tokenResponse;
    };
  }
}
