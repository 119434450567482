import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { debug } from "./utils";

// eslint-disable-next-line no-shadow
export enum TagEventsEnum {
  PAGE = "page",
  AGENT_ROOM_REQUEST = "agent-room-request",
  JOIN_ROOM = "join-room",
}

declare const dataLayer;

@Injectable()
export class TagManagerService {
  constructor(private router: Router) {}

  public trackPages() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const tag = {
          event: TagEventsEnum.PAGE,
          pageName: item.url,
        };
        this.sendEvent(tag);
      }
    });
  }

  public trackEvent(event: TagEventsEnum, data: string) {
    this.sendEvent({ event, data });
  }

  private sendEvent(tag) {
    // don't track localhost events. Should we not track genesys.dev & genesys.stg as well?
    if ("push" in dataLayer && window.origin.indexOf("localhost") < -1) {
      dataLayer.push(tag);
    }
    // else {
    // debug("tracked event ", tag);
    // }
  }
}
