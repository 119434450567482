import { Injector } from "@angular/core";

import {
  NoAnalyticsStrategy,
  ToastNotificationStrategy,
  NoQueueStrategy,
  NoInvitationStrategy,
  DefaultInteractionStrategy,
  NoIntegrationStrategy,
  NoConversationStrategy,
  GenesysCloudConversationStrategy,
  TalkdeskConversationStrategy,
} from "../strategies";

import {
  IApplication,
  IApplicationDTO,
  IGenesysApplicationConfig,
} from "../../../core-ui/models/IApplication";
import {
  ApplicationTypeEnum,
  MediaFilterTypeEnum,
} from "../../../core-ui/core-ui.enums";
import {
  TicketAuthenticationStrategy,
  IInteractionStrategy,
  IAuthenticationStrategy,
  IAnalyticsStrategy,
  IQueueStrategy,
  IInvitationStrategy,
  INotificationStrategy,
  IIntegrationStrategy,
  IConversationStrategy,
} from "../../../core-ui/models/strategies";
import {
  AppConfigService,
  NotificationService,
  UserService,
} from "../../../core-ui";
import { of } from "rxjs";
import { PureCloudService } from "../../services/purecloud.service";
import { HttpClient } from "@angular/common/http";

export class CustomerApplication implements IApplication {
  private id: string;
  private organizationId: string;
  private createdAt: Date;
  private config: IGenesysApplicationConfig;
  private version: number;
  private pureCloudService: PureCloudService;

  private mAuthenticationStrategy: IAuthenticationStrategy;
  private mInteractionStrategy: IInteractionStrategy;
  private mAnalyticsStrategy: IAnalyticsStrategy;
  private mQueueStrategy: IQueueStrategy;
  private mTicketStrategy: IInvitationStrategy;
  private mNotificationStrategy: INotificationStrategy;
  private mIntegrationStrategy: IIntegrationStrategy;
  private mConversationStrategy: IConversationStrategy;

  private appConfigService: AppConfigService;

  constructor(
    private dto: IApplicationDTO,
    injector: Injector,
    customerClientId: string
  ) {
    this.id = dto.id;
    this.organizationId = dto.organizationId;
    this.createdAt = new Date(dto.createdAt);
    this.config = dto.config as IGenesysApplicationConfig;
    this.version = dto.version;
    this.appConfigService = injector.get(AppConfigService);
    this.appConfigService.setApplicationConfig(dto.config);

    this.pureCloudService = injector.get(PureCloudService);

    const toast = injector.get(NotificationService);

    this.mAuthenticationStrategy = new TicketAuthenticationStrategy(
      injector,
      customerClientId,
      dto?.config?.publicParameters?.accessTokenCustomerSessionStoreEnabled ??
        true
    );

    this.mInteractionStrategy = new DefaultInteractionStrategy();
    this.mAnalyticsStrategy = new NoAnalyticsStrategy();
    this.mQueueStrategy = new NoQueueStrategy();
    this.mTicketStrategy = new NoInvitationStrategy();
    this.mNotificationStrategy = new ToastNotificationStrategy(toast);
    this.mIntegrationStrategy = new NoIntegrationStrategy();
    switch (dto.type) {
      case ApplicationTypeEnum.Genesys:
        this.mConversationStrategy = new GenesysCloudConversationStrategy(
          this.pureCloudService,
          this.appConfigService
        );
        break;
      case ApplicationTypeEnum.TalkdeskOpenID:
        this.mConversationStrategy = new TalkdeskConversationStrategy(
          injector.get(HttpClient),
          injector.get(UserService)
        );
        break;
      default:
        this.mConversationStrategy = new NoConversationStrategy();
    }
  }

  supportsInteractions(): boolean {
    return false;
  }

  supportsInteractionMetrics(): boolean {
    return false;
  }

  canCallWithoutActiveInteraction(): boolean {
    return false;
  }

  isReady(): boolean {
    return true;
  }

  getId(): string {
    return this.id;
  }

  getDto(): IApplicationDTO {
    return this.dto;
  }

  getType(): ApplicationTypeEnum {
    return ApplicationTypeEnum.Customer;
  }

  getOrganizationId(): string {
    return this.organizationId;
  }

  getConfig(): IGenesysApplicationConfig {
    return this.config;
  }

  getCreatedAt(): Date {
    return this.createdAt;
  }

  getFilterBackgrounds() {
    const url = "core-ui/assets/media-filter-backgrounds";
    return [
      {
        id: "open-id-1",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/living-room-1.jpg`),
      },
      {
        id: "open-id-2",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/living-room-4.jpg`),
      },
      {
        id: "open-id-3",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/office-1.jpg`),
      },
    ];
  }

  getVersion(): number {
    return this.version;
  }

  registerLifecycleEventHandlers() {}

  authenticationStrategy(): IAuthenticationStrategy {
    return this.mAuthenticationStrategy;
  }

  interactionStrategy(): IInteractionStrategy {
    return this.mInteractionStrategy;
  }

  analyticsStrategy(): IAnalyticsStrategy {
    return this.mAnalyticsStrategy;
  }

  queueStrategy(): IQueueStrategy {
    return this.mQueueStrategy;
  }

  ticketStrategy(): IInvitationStrategy {
    return this.mTicketStrategy;
  }

  notificationStrategy(): INotificationStrategy {
    return this.mNotificationStrategy;
  }

  integrationStrategy(): IIntegrationStrategy {
    return this.mIntegrationStrategy;
  }

  conversationStrategy(): IConversationStrategy {
    return this.mConversationStrategy;
  }
}
