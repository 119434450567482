<div
  class="fx-row fx-align-start tile-controls"
  [class.hidden]="isHidden"
  *ngIf="isAvailable"
>
  <div>
    <button
      [attr.aria-label]="
        (isMenuOpen() ? 'Close' : 'Open') + ' participant options'
      "
      avButton
      #menuButton
      *ngIf="isMenuTriggerVisible"
      size="xxs"
      ofType="icon"
      [color]="isMenuOpen() ? 'basic' : 'primary'"
      (click)="toggleMenu()"
      class="tile-control-trigger"
      data-tid="tile-controls/open-menu"
    >
      <av-icon *ngIf="isMenuOpen()" mode="dark" name="close-big"></av-icon>
      <av-icon
        *ngIf="!isMenuOpen()"
        mode="light"
        name="more-vertical"
      ></av-icon>
    </button>
  </div>
  <button
    class="tile-tool-light"
    avButton
    aria-label="Stop taking snapshots"
    *ngIf="isSnapshotActive"
    ofType="stroked"
    size="xxs"
    (click)="snapshotTerminate()"
    [themable]="false"
    translate
    data-tid="tile-controls/stop-snapshots"
  >
    Stop snapshots
  </button>

  <button
    class="tile-tool-light"
    avButton
    aria-label="Stop AR pointer"
    *ngIf="isLaserActive"
    ofType="stroked"
    size="xxs"
    (click)="togglePointer()"
    [themable]="false"
    translate
    data-tid="tile-controls/stop-ar-pointer"
  >
    Stop AR pointer
  </button>

  <button
    class="tile-tool-light"
    [disabled]="isCobrowseStopDisabled"
    avButton
    aria-label="Stop co-browsing"
    *ngIf="isCobrowseActive"
    ofType="stroked"
    size="xxs"
    [themable]="false"
    (click)="cobrowseTerminate()"
    translate
    data-tid="tile-controls/stop-cobrowser"
  >
    Stop co-browsing
  </button>
</div>

<av-card
  *ngIf="isMenuOpen()"
  #menu
  class="tile-controls-menu"
  [popup]="true"
  [hAlign]="hAlign"
>
  <av-card-body type="slim">
    <av-menu>
      <ng-container *ngIf="isFileTransferAvailable">
        <av-menu-item
          tabindex="1"
          icon="upload"
          class="tile-control-file"
          data-tid="tile-controls/send-file"
          title="{{ 'Send a file' | translate }}"
        >
          <span translate>Send a file</span>
          <input
            name="file-transfer"
            #fileInput
            (change)="fileAdded($event)"
            type="file"
          />
        </av-menu-item>
      </ng-container>

      <ng-container *ngIf="isLaserAvailable">
        <av-menu-item
          tabindex="2"
          *ngIf="!isLaserActive"
          icon="laser"
          [disabled]="isLaserDisabled()"
          (select)="togglePointer()"
          data-tid="tile-controls/start-at-pointer"
          title="{{ 'Laser pointer' | translate }}"
        >
          <span translate>Laser pointer</span>
        </av-menu-item>
        <av-menu-item
          tabindex="3"
          *ngIf="isLaserActive"
          icon="laser"
          color="danger"
          (select)="togglePointer()"
          data-tid="tile-controls/stop-ar-pointer"
          title="{{ 'Stop AR pointer' | translate }}"
        >
          <span translate>Stop AR pointer</span>
        </av-menu-item>
      </ng-container>

      <ng-container *ngIf="isCobrowseAvailable">
        <av-menu-item
          tabindex="4"
          icon="cobrowse"
          [disabled]="isCobrowseDisabled()"
          *ngIf="!isCobrowseActive"
          (select)="cobrowseRequest()"
          title="{{ 'Start co-browsing' | translate }}"
          data-tid="tile-controls/start-cobrowser"
        >
          {{ "Start co-browsing" | translate }}
        </av-menu-item>
        <av-menu-item
          tabindex="5"
          icon="cobrowse"
          [disabled]="isCobrowseStopDisabled"
          *ngIf="isCobrowseActive"
          color="danger"
          (select)="cobrowseTerminate()"
          title=" {{ 'Stop co-browsing' | translate }}"
          data-tid="tile-controls/stop-cobrowser"
        >
          {{ "Stop co-browsing" | translate }}
        </av-menu-item>
      </ng-container>

      <ng-container *ngIf="isSnapshotAvailable">
        <av-menu-item
          tabindex="6"
          *ngIf="!isSnapshotActive"
          [disabled]="isSnapshotDisabled()"
          icon="camera"
          (select)="snapshotRequest()"
          data-tid="tile-controls/open-snapshots"
          title="{{ 'Take snapshots' | translate }}"
        >
          {{ "Take snapshots" | translate }}
        </av-menu-item>
        <av-menu-item
          tabindex="7"
          *ngIf="isSnapshotActive"
          icon="camera"
          color="danger"
          (select)="snapshotTerminate()"
          data-tid="tile-controls/stop-snapshots"
          title="{{ 'Stop snapshots' | translate }}"
        >
          {{ "Stop snapshots" | translate }}
        </av-menu-item>
      </ng-container>

      <ng-container *ngIf="isDesktopCaptureAvailable">
        <av-menu-item
          tabindex="8"
          *ngIf="isDesktopCaptureActive()"
          icon="screen-stop"
          color="danger"
          (select)="desktopCaptureTerminate()"
          data-tid="tile-controls/stop-customer-desktop-capture"
          title="{{ 'Stop desktop capture' | translate }}"
        >
          {{ "Stop desktop capture" | translate }}
        </av-menu-item>

        <av-menu-item
          tabindex="8"
          *ngIf="!isDesktopCaptureActive()"
          icon="screen-stop"
          (select)="desktopCaptureRequest()"
          [disabled]="isDesktopCaptureDisabled()"
          [loading]="isDesktopCaptureLoading()"
          data-tid="tile-controls/request-customer-desktop-capture"
          title="{{ 'Capture desktop' | translate }}"
        >
          {{ "Capture desktop" | translate }}
        </av-menu-item>
      </ng-container>

      <ng-container *ngIf="isControlActionEffectsSeparatorVisible">
        <av-menu-item type="separator"></av-menu-item>
      </ng-container>

      <ng-container *ngIf="isPictureInPictureAvailable">
        <av-menu-item
          tabindex="9"
          class="menu-item-pop-out"
          icon="pop-out"
          [disabled]="isPictureInPictureDisabled()"
          (select)="pip()"
          *ngIf="!isPictureInPictureOn()"
          data-tid="tile-controls/enable-pip"
          title="{{ 'Pop out' | translate }}"
        >
          {{ "Pop out" | translate }}
        </av-menu-item>
        <av-menu-item
          tabindex="10"
          icon="pop-in"
          (select)="pip()"
          color="danger"
          *ngIf="isPictureInPictureOn()"
          data-tid="tile-controls/disable-pip"
          title="{{ 'Put back in' | translate }}"
        >
          {{ "Put back in" | translate }}
        </av-menu-item>
      </ng-container>

      <ng-container *ngIf="isFullscreenAvailable">
        <av-menu-item
          tabindex="11"
          [icon]="isFullScreen() ? 'collapse' : 'expand'"
          [disabled]="isFullscreenDisabled()"
          (select)="toggleFullScreen()"
          data-tid="tile-controls/toggle-fullscreen"
        >
          <span *ngIf="!isFullScreen()" translate>Open in full screen</span>
          <span *ngIf="isFullScreen()" translate>Exit full screen</span>
        </av-menu-item>
      </ng-container>

      <ng-container *ngIf="isBitratePickerAvailable">
        <av-menu-item
          *ngIf="isBitrateSeparatorVisible"
          type="separator"
        ></av-menu-item>
        <app-bitrate-picker
          [stream]="stream"
          (changed)="bitrateChanged()"
        ></app-bitrate-picker>
      </ng-container>
    </av-menu>
  </av-card-body>
</av-card>
