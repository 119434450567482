import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

import { ISyncable } from "./ISyncable";
import {
  ErrorPageCode,
  PARAM_DISPLAY_NAME,
  ErrorPageSubCode,
} from "../../app.enums";
import { GenericErrorHandler } from "../../services/error-handlers.service";
import { ApplicationFactory } from "../../factories/ApplicationFactory";

import {
  ActivityIndicatorService,
  AppConfigService,
  debugError,
  FEATURES,
  DeviceService,
  IUserDetails,
  PublicTicketService,
  UserCapabilityEnum,
  UserRoleEnum,
  TicketTypeEnum,
} from "../../../core-ui";
import { UserService } from "../../../core-ui/services/user.service";
import { ApplicationService } from "../../../core-ui/services/application.service";
import { AuthenticationService } from "../../../core-ui/services/authentication.service";
import {
  CLAIM_APPOINTMENT_ID,
  CLAIM_CONFERENCE_ID,
  CLAIM_INTERACTION_ID,
  ConversationTypeEnum,
  PARAM_TICKET_ID,
} from "../../../core-ui/core-ui.enums";
import { localStore } from "@auvious/utils";
import { KEY_USER_DISPLAY_NAME } from "../../app.enums";
import { Interaction } from "../../models";
import { InteractionService } from "../../services";

@Injectable()
export class AuthCustomerGuard implements ISyncable {
  constructor(
    private router: Router,
    private errorHandler: GenericErrorHandler,
    private floatingService: ActivityIndicatorService,
    private userService: UserService,
    private applicationService: ApplicationService,
    private authenticationService: AuthenticationService,
    private applicationFactory: ApplicationFactory,
    private config: AppConfigService,
    private publicTicketService: PublicTicketService,
    private interactionService: InteractionService,
    private device: DeviceService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.userService.isAuthenticated()) {
      try {
        this.floatingService.loading(true, "authenticating");
        const user = await this.authenticationService.authenticate(route);

        if (!user.hasRole(UserRoleEnum.customer)) {
          throw new Error("user not a customer");
        }

        if (!DeviceService.isMobile) {
          user.addCapability(UserCapabilityEnum.qrLink);
        }
        if (this.device.isDisplayMediaAvailable) {
          user.addCapability(UserCapabilityEnum.displayCapture);
        }

        if (this.config.featureEnabled(FEATURES.LASER_TOOL)) {
          user.addCapability(UserCapabilityEnum.arPointer);
        }
        user.addCapability(UserCapabilityEnum.snapshot);
        user.addCapability(UserCapabilityEnum.fileTransfer);

        this.userService.setActiveUser(user);

        // try to find name in query params
        let name = route.queryParamMap.get(PARAM_DISPLAY_NAME);
        let ticketData;

        try {
          const ticket = route.paramMap.get(PARAM_TICKET_ID);
          ticketData = await this.publicTicketService.getTicket(ticket);
          if (!name) {
            // try to find name on ticket
            name = ticketData.properties?.customer_name;
          }
          // try to find interaction id
          user.setClaim(
            CLAIM_INTERACTION_ID,
            ticketData.properties.interaction_id
          );

          if (ticketData.properties?.appointmentId) {
            user.setClaim(
              CLAIM_APPOINTMENT_ID,
              ticketData.properties.appointmentId
            );
          }
        } catch (ex) {
          debugError(ex);
        }

        if (!name) {
          name = localStore.getItem(KEY_USER_DISPLAY_NAME);
        }

        // if we found it, use it
        if (name) {
          const details: IUserDetails = { name, id: user.getId() };
          this.userService.setUserDetails(details);
        }

        // refresh application now that we have a jwt token
        this.applicationService.createApiResource();
        const application =
          await this.applicationFactory.createCustomerApplication(
            this.applicationService.getActiveApplication().getId()
          );
        this.applicationService.setActiveApplication(application);

        // since we have the ticket at this point, use it and enrich the interaction data
        if (
          [
            TicketTypeEnum.SingleScheduleTicket,
            TicketTypeEnum.ScheduleTicket,
          ].includes(ticketData.type)
        ) {
          const interaction = new Interaction(
            user.getClaim(CLAIM_CONFERENCE_ID),
            user.getClaim(CLAIM_INTERACTION_ID)
          );
          // interaction.setOrigin(ConversationOriginEnum.CALLBACK);
          interaction.setType(ConversationTypeEnum.callback);
          this.interactionService.setActiveInteraction(interaction);
        }
      } catch (error) {
        if (!!error.status) {
          const isAppointment = route.url?.[0].path === "q";
          switch (error.status) {
            case 401:
              if (!!error.error && !!error.error.error_description) {
                switch (error.error.error_description) {
                  case "SCHEDULED_LATER":
                    // token already used / unauthorized
                    return this.router.createUrlTree([
                      "/wait",
                      route.paramMap.get(PARAM_TICKET_ID),
                    ]);
                  default:
                    let queryParams = {};
                    if (isAppointment) {
                      queryParams = {
                        code: ErrorPageSubCode.APPOINTMENT_TOKEN_EXPIRED,
                      };
                    }
                    // token already used / unauthorized / expired
                    return this.router.createUrlTree(
                      ["/error", ErrorPageCode.SESSION_EXPIRED],
                      { queryParams }
                    );
                }
              } else {
                this.errorHandler.handleError(error);
                return this.router.createUrlTree(
                  ["/error", ErrorPageCode.GENERIC_ERROR],
                  { queryParamsHandling: "preserve" }
                );
              }
            case 404:
              return this.router.createUrlTree(
                ["/error", ErrorPageCode.NOT_FOUND],
                { queryParamsHandling: "preserve" }
              );
            default:
              this.errorHandler.handleError(error);
              return this.router.createUrlTree(
                ["/error", ErrorPageCode.GENERIC_ERROR],
                { queryParamsHandling: "preserve" }
              );
          }
        } else {
          this.errorHandler.handleError(error);
          return this.router.createUrlTree(
            ["/error", ErrorPageCode.GENERIC_ERROR],
            { queryParamsHandling: "preserve" }
          );
        }
      } finally {
        this.floatingService.loading(false);
      }
    }

    return true;
  }
}
