import { Component, OnInit, OnDestroy, signal } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ConferenceMode, IEndpoint } from "@auvious/rtc";
import { debug } from "../../app.utils";
import {
  ActivityIndicatorService,
  AppConfigService,
  IConversationEventHandlers,
  IConversationBase,
  ConversationOriginEnum,
  FEATURES,
  fadeInOut,
  slideIn,
  slideInOut,
  isDebugBetaOn,
  IApplication,
  AuviousRtcService,
  debugError,
  AgentParam,
  ConversationDestinationEnum,
  LocalMediaService,
  GenericErrorHandler,
} from "../../../core-ui";
import {
  ApplicationTypeEnum,
  ConversationTypeEnum,
  UserRoleEnum,
} from "../../../core-ui/core-ui.enums";
import { IInteraction } from "../../../core-ui/models/IInteraction";
import { ApplicationService } from "../../../core-ui/services/application.service";
import { UserService } from "../../../core-ui/services/user.service";
import { IUserDetails, IUser } from "../../../core-ui/models/IUser";
import { NotificationService } from "../../../core-ui/services/notification.service";
import { AnalyticsService } from "../../../core-ui/services/analytics.service";
import { IntegrationService } from "../../services";
import { DeviceService } from "../../../core-ui/services/device.service";
import { InteractionService } from "../../services/interaction.service";
import { InvitationService } from "../../services/invitation.service";
import { Interaction } from "../../models/Interaction";
import { ScheduleTypeEnum } from "../../../core-ui/core-ui.enums";
import { WindowService } from "../../../core-ui/services/window.service";
import {
  ErrorPageCode,
  PARAM_ROOM_ID,
  PARAM_CONVERSATION_ID,
  PARAM_GUEST,
} from "../../app.enums";
import { Subscription } from "rxjs";

// eslint-disable-next-line no-shadow
enum actionsEnum {
  callback = "callback",
  cobrowse = "cobrowse",
}

const POP_OUT_TITLE = "Auvious";

@Component({
  selector: "app-page-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
  animations: [fadeInOut, slideIn, slideInOut],
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  _loading: boolean;

  user: IUser;
  userDetails: IUserDetails;
  interaction = signal<IInteraction>(undefined);
  conv: IConversationBase;
  activeAction: actionsEnum;
  transferMap: Map<string, boolean> = new Map();
  isReloadAvailable = false;
  isConnectionDown = false;
  subscription = new Subscription();
  isConversationEndingReceived = false;

  private handlers: IConversationEventHandlers;
  private childPopupWindow: Window;
  private childPopupDetectionTimer;

  constructor(
    private router: Router,
    private rtcService: AuviousRtcService,
    private applicationService: ApplicationService,
    private interactionService: InteractionService,
    private activity: ActivityIndicatorService,
    private coreConfig: AppConfigService,
    private route: ActivatedRoute,
    private userService: UserService,
    private config: AppConfigService,
    private notificationService: NotificationService,
    private integration: IntegrationService,
    private device: DeviceService,
    private invitation: InvitationService,
    private analyticsService: AnalyticsService,
    // early initialization
    private mediaService: LocalMediaService,
    private windowService: WindowService,
    private errorHandler: GenericErrorHandler
  ) {
    this.loading = false;
    this.activeAction = null;
    this.route.data.subscribe((data) => {
      this.interaction.set(data.interaction);
    });
    if (this.device.isPopUp) {
      const parent = window.self;
      parent.opener = window.self;
      parent.close();
    }
    this.isReloadAvailable =
      this.application.getType() === ApplicationTypeEnum.TalkdeskOpenID &&
      this.application.supportsInteractions();
  }

  async ngOnInit() {
    requestAnimationFrame(() => {
      (
        document.querySelector(
          "[data-tid='welcome/launch-conference']"
        ) as HTMLButtonElement
      )?.focus();
    });

    this.subscription.add(
      this.errorHandler.connectionError$.subscribe((_) => {
        this.isConnectionDown = true;
      })
    );
    this.subscription.add(
      this.rtcService.connectionRegistered$.subscribe((_) => {
        this.isConnectionDown = false;
      })
    );
    try {
      // this.childPopupWindow = null;
      this.user = this.userService.getActiveUser();
      this.userDetails = this.userService.getUserDetails();
      if (
        this.applicationService.getActiveApplication().supportsInteractions() &&
        (this.isVideoCallAvailable || this.isCobrowseAvailable)
      ) {
        this.subscribeToConversationEvents();
      }

      if (!this.interaction()) {
        return;
      }

      this.setConversation(this.interaction());

      // discard for callbacks if not enabled
      if (
        (this.interaction().getType() === ConversationTypeEnum.callback &&
          !this.isSchedulingAvailable) ||
        !this.isVideoCallAvailable
      ) {
        return;
      }

      if (this.windowService.isWindowPopedOut) {
        this.childPopupWindow = window.open("", POP_OUT_TITLE);
        this.detectPopOutClose();
      } else {
        this.tryAutoStartCall();
      }
    } catch (ex) {
      // console.log(ex);
    }
  }

  ngOnDestroy() {
    this.handlers = null;
    this.subscription.unsubscribe();
    if (this.childPopupDetectionTimer) {
      clearInterval(this.childPopupDetectionTimer);
    }
  }

  private tryAutoStartCall() {
    if (
      // this will lead to a 'room-not-found' for the agent
      // most probably the agent ended the call and the conversation has not yet transitioned to wrap-up code
      this.interaction().getOrigin() === ConversationOriginEnum.APPOINTMENT &&
      !this.interaction().hasRoom()
    ) {
      return;
    }
    if (
      this.config.agentParam(AgentParam.VIDEO_CALL_ENABLED) &&
      this.config.agentParam(AgentParam.AUTO_ANSWER)
    ) {
      this.startCall();
    }
  }

  private setConversation(interaction: IInteraction) {
    // create base object, same as if we got a started event.
    this.conv = {
      conversationId: interaction.getId(),
      origin: interaction.getOrigin(),
      type: interaction.getType(),
      room: interaction.getRoom(),
      channel: interaction.getChannel(),
    };
  }

  private subscribeToConversationEvents() {
    this.handlers = {
      alerted: (interactionId: string) => {
        debug("RING! new interaction");
      },
      agentJoined: (name: string) => {
        if (!!name) {
          this.userService.setUserDetails({
            ...this.userDetails,
            displayName: name,
          });
        }
      },
      started: async (conversation: IConversationBase) => {
        this.isConversationEndingReceived = false;
        debug("conversation started ", conversation);
        // discard event if we don't have callback handling enabled
        if (
          (!this.isSchedulingAvailable &&
            !!conversation &&
            conversation.type === ConversationTypeEnum.callback) ||
          this.isCallPopedOut ||
          // we have joined and ended the room of the appointment without closing the conversation
          (this.interaction()?.getOrigin() ===
            ConversationOriginEnum.APPOINTMENT &&
            !this.interaction().hasRoom())
        ) {
          return;
        }

        try {
          const interaction =
            await this.interactionService.retrieveInteractionData(
              conversation.conversationId,
              conversation.channel
            );
          // clumpsy way to do it but we cannot find another way to see if the message we receive is for a queue that is
          // being transfered or not
          if (!interaction) {
            // || this.transferMap.get(interaction.getId())
            return;
          }
          const existingInteraction =
            this.interactionService.getActiveInteraction();

          // we have already opened a the co-browse launcher and have a room to join
          if (
            !!existingInteraction &&
            existingInteraction.getType() === ConversationTypeEnum.cobrowse &&
            interaction.getType() === ConversationTypeEnum.chat &&
            !interaction.hasRoom()
          ) {
            interaction.setRoom(existingInteraction.getRoom());
          }

          this.interactionService.setActiveInteraction(interaction);
          this.setConversation(interaction);
          this.interaction.set(interaction);

          // track in analytics
          if (this.interaction().getType() === ConversationTypeEnum.chat) {
            // if we persist to integration (for genesys cloud), it sends a 'started' event again
            // thus resulting into a loop
            this.analyticsService.trackCallRequested(this.interaction(), false);
          }

          this.tryAutoStartCall();
        } catch (ex) {
          this.notifyEmptyInteraction();
        }
      },
      ending: (interactionId: string) => {
        this.transferMap.set(interactionId, false);

        this.isConversationEndingReceived = true;

        if (this.interaction()?.getType() === ConversationTypeEnum.chat) {
          this.analyticsService.trackCallEnded(this.interaction(), false);
        }
        // todo: cannot end a conference if not joined, we need to join first
        // if (
        //   this.interaction()?.getType() === ConversationTypeEnum.callback &&
        //   this.interaction().hasRoom()
        // ) {
        //   this.rtcService.endConference(this.interaction().getRoom());
        // }

        this.reset(interactionId);
      },
      agentHeld: (interactionId: string) => {
        // this.transferMap.set(interactionId, false);
        this.reset(interactionId);
      },
      ended: (interactionId: string) => {
        // this.transferMap.set(interactionId, false);

        // for digital-connect we dont get 'ending' event if the agent ends the conv
        if (
          this.interaction()?.getType() === ConversationTypeEnum.chat &&
          !this.isConversationEndingReceived
        ) {
          this.analyticsService.trackCallEnded(this.interaction(), false);
          this.isConversationEndingReceived = true;
        }
        this.reset(interactionId);
      },
      transferring: (interactionId: string) => {
        // this.transferMap.set(interactionId, true);
        this.reset(interactionId);
      },
      transferred: (interactionId: string) => {
        // this.transferMap.set(interactionId, false);
        this.reset(interactionId);
      },
    };
    this.interactionService.registerConversationEventHandlers(this.handlers);
  }

  private reset(interactionId?: string) {
    this.conv = null;
    this.interaction.set(null);
    if (
      this.interactionService.getActiveInteraction()?.getId() === interactionId
    ) {
      this.interactionService.clearActiveInteraction();
    }
  }

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private notifyEmptyInteraction() {
    this.notificationService.warn("Auvious", {
      body: "Could not retrieve interaction details. Try refreshing your page.",
    });
  }

  async startCall() {
    if (!this.isCallEnabled || this.isCallPopedOut) {
      return;
    }
    try {
      if (this.interactionService.isActiveInteractionAvailable()) {
        await this.joinRoomWithInteraction(
          this.interactionService.getActiveInteraction()
        );
      } else if (this.application.canCallWithoutActiveInteraction()) {
        await this.joinRoomWithoutInteraction();
      }
    } catch (error) {
      debugError(error);
      this.notificationService.error(error.message || error);
    }
  }

  async joinRoomWithoutInteraction(): Promise<void> {
    try {
      const conference = await this.rtcService.createConference({
        mode: ConferenceMode.ROUTER,
      });
      if (conference) {
        await this.joinRoom(conference.name);
      } else {
        throw new Error("Could not create conference.");
      }
    } catch (ex) {
      this.notificationService.error("Error", {
        body: ex.body?.message || ex.message || ex,
      });
      if (ex.response?.status === 401) {
        this.router.navigate(["/error", ErrorPageCode.GENERIC_ERROR]);
      }
      debugError(ex);
    }
  }

  async joinRoomWithInteraction(interaction: IInteraction): Promise<void> {
    try {
      if (interaction.hasRoom()) {
        await this.joinRoom(interaction.getRoom(), interaction);
      } else {
        const conference = await this.rtcService.createConference({
          mode: ConferenceMode.ROUTER,
        });
        interaction.setRoom(conference.name);
        // update the active interaction with the new room name;
        this.interactionService.setActiveInteraction(interaction);
        await this.joinRoom(interaction.getRoom(), interaction);
      }
    } catch (ex) {
      this.notificationService.error("Error", {
        body: ex.body?.message || ex.message || ex,
      });
      if (ex.response?.status === 401) {
        this.router.navigate(["/error", ErrorPageCode.GENERIC_ERROR]);
      }
      debugError(ex);
    }
  }

  toggleCallback() {
    switch (this.config.agentParam(AgentParam.SCHEDULE_CHANNEL)) {
      case ScheduleTypeEnum.genesysCallback:
        this.activeAction =
          this.activeAction === actionsEnum.callback
            ? null
            : actionsEnum.callback;
        break;
      case ScheduleTypeEnum.appointment:
        this.router.navigate(["/schedule"], { queryParamsHandling: "merge" });
        break;
    }
  }

  toggleCobrowse() {
    this.activeAction =
      this.activeAction === actionsEnum.cobrowse ? null : actionsEnum.cobrowse;
  }

  cobrowseCanceled() {
    this.toggleCobrowse();
    // clear interaction
    if (
      this.interactionService.getActiveInteraction()?.getType() ===
      ConversationTypeEnum.cobrowse
    ) {
      this.interactionService.clearActiveInteraction();
    }
  }

  cobrowseStarted(payload: {
    participant: IEndpoint;
    interaction: IInteraction;
  }) {
    this.activeAction = null;
    // this.activity.loading(true, 'Waiting for approval');
    // this.interaction = payload.interaction;
    // this.remoteCobrowseParticipant = payload.participant;
    this.router.navigate(["/", "cobrowse"], {
      queryParamsHandling: "preserve",
    });
  }

  joinRoom(room: string, interaction?: IInteraction) {
    if (this.config.agentParamEnabled(AgentParam.POP_OUT_CALL)) {
      // if (this.childPopupWindow) { return; }
      // open as a popup
      try {
        if (!interaction) {
          interaction = new Interaction(room);
        }
        const urlString = this.invitation.prepareAgentInvitation(interaction);
        const url = new URL(urlString);
        url.searchParams.delete(PARAM_GUEST);

        this.childPopupWindow = window.open(
          url.toString(),
          POP_OUT_TITLE,
          "height=600,width=900"
        );
        this.windowService.isWindowPopedOut = true;
        //  detect window close
        this.detectPopOutClose();
      } catch (ex) {
        this.notificationService.error("Bad URL", {
          body: "Could not create meeting url",
        });
      }
    } else {
      // open in same page
      this.router.navigate(["/a"], {
        queryParams: {
          [PARAM_ROOM_ID]: room,
          [PARAM_CONVERSATION_ID]: interaction?.getId(),
        },
        queryParamsHandling: "merge",
      });
    }
  }

  private detectPopOutClose() {
    this.childPopupDetectionTimer = setInterval(async () => {
      if (this.childPopupWindow?.closed) {
        clearInterval(this.childPopupDetectionTimer);
        this.windowService.isWindowPopedOut = false;
        this.childPopupWindow = null;
        // todo: do the same for appointment but only if the customer has joined the room (otherwise the agent leaves)
        if (
          this.interaction() &&
          this.interaction().getType() !== ConversationTypeEnum.callback
        ) {
          await this.analyticsService.trackCallEnding(this.interaction());
          this.interaction.set(
            await this.interactionService.retrieveInteractionData(
              this.interaction().getId()
            )
          );
          this.interactionService.setActiveInteraction(this.interaction());
        }
      }
    }, 1000);
  }

  async getInteractions() {
    this.activity.loading(true);
    this.interaction.set(
      await this.interactionService.discoverActiveInteraction()
    );

    !!this.interaction()
      ? this.setConversation(this.interaction())
      : (this.conv = undefined);
    this.activity.loading(false);
  }

  get videoCallRequiresActiveInteraction() {
    return this.config.agentParamEnabled(
      AgentParam.VIDEO_CALL_REQUIRES_INTERACTION
    );
  }

  get name() {
    return (this.userDetails && this.userDetails.name) || "";
  }

  get isDebugBeta() {
    return isDebugBetaOn();
  }

  get isConversationAudio() {
    return this.conv?.type === ConversationTypeEnum.voiceCall;
  }

  get isConversationVideo() {
    return this.conv?.type === ConversationTypeEnum.videoCall;
  }

  get isConversationChat() {
    return this.conv?.type === ConversationTypeEnum.chat;
  }

  get isOriginWidget() {
    return this.conv?.origin === ConversationOriginEnum.WIDGET;
  }

  get isOriginAppointment() {
    return this.conv?.origin === ConversationOriginEnum.APPOINTMENT;
  }

  get isOriginCall() {
    return this.conv?.origin === ConversationOriginEnum.PHONE;
  }

  get isOriginChat() {
    return (
      !this.coreConfig.featureEnabled(FEATURES.WIDGET_ENABLED) ||
      (this.conv && this.conv.origin === ConversationOriginEnum.CHAT)
    );
  }

  // get isDestinationInteractionWidget() { return this.interaction?.getDestination() === ConversationDestinationEnum.INTERACTION_WIDGET; }

  get isConversationCallback() {
    return this.conv && this.conv.type === ConversationTypeEnum.callback;
  }

  get isActiveActionEmpty() {
    return this.activeAction === null;
  }

  get isSchedulingAvailable() {
    return (
      (this.integration.supportsInteractionOrigin(
        ConversationOriginEnum.CALLBACK
      ) ||
        this.integration.supportsInteractionOrigin(
          ConversationOriginEnum.APPOINTMENT
        )) &&
      this.config.agentParamEnabled(AgentParam.SCHEDULE_ENABLED)
    );
  }

  get isSchedulingDisabled() {
    return this.isConnectionDown;
  }

  get isCallbackVisible() {
    return this.activeAction === actionsEnum.callback;
  }

  get isCobrowseVisible() {
    return this.activeAction === actionsEnum.cobrowse;
  }

  get isCobrowseAvailable() {
    return this.config.agentParamEnabled(AgentParam.COBROWSE_ENABLED);
  }

  get isCobrowseDisabled() {
    // disable cobrowse if we have an active interaction that is not chat
    return (
      this.isConnectionDown ||
      // [
      //   ConversationOriginEnum.CALLBACK,
      // ].includes(this.activeInteraction?.getOrigin())
      // ||
      [ConversationTypeEnum.videoCall, ConversationTypeEnum.voiceCall].includes(
        this.interaction()?.getType()
      )
    );
  }

  get isActionEnabled() {
    return !this.isCobrowseVisible && !this.isCallbackVisible;
  }

  get isInteractionAvailable() {
    return (
      this.isOriginCall ||
      this.isOriginWidget ||
      this.isOriginChat ||
      this.isConversationCallback ||
      this.isOriginAppointment
    );
  }

  get isInitialized() {
    return !!this.application && this.application.isReady();
  }

  get isCallEnabled(): boolean {
    if (this.isConnectionDown) {
      return false;
    }
    if (!!this.application) {
      if (this.application.supportsInteractions()) {
        return this.videoCallRequiresActiveInteraction
          ? this.isInteractionAvailable
          : true;
      } else {
        return true;
      }
    }
  }

  get isApplicationConfigured() {
    return !!this.application.getId();
  }

  get isConfigEnabled() {
    return this.user.hasRole(UserRoleEnum.admin);
  }

  get isRecordingEnabled() {
    return this.user.hasRole(UserRoleEnum.supervisor);
  }

  public get loading(): boolean {
    return this._loading;
  }

  public set loading(value: boolean) {
    this._loading = value;
    this.activity.loading(value);
  }

  get isVideoCallAvailable() {
    return (
      this.config.agentParamEnabled(AgentParam.VIDEO_CALL_ENABLED) &&
      this.integration.hasLicense("video")
    );
  }

  get isHelpVisible() {
    return (
      !this.isVideoCallAvailable &&
      !this.isCobrowseAvailable &&
      !this.isSchedulingAvailable
    );
  }

  get isCallPopedOut() {
    return this.windowService.isWindowPopedOut;
  }
}
