import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { IEndpoint, StreamTypes } from "@auvious/rtc";
import { firstValueFrom, merge, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import {
  COLOR,
  ColorEnum,
  SketchToolEnum,
  VideoFacingModeEnum,
} from "../../../core-ui.enums";
import {
  ConversationNotification,
  IArea,
  INotificationEvent,
} from "../../../models";
import { SketchMetadata } from "../../../models/Metadata";
import {
  ArPointerActivatedEvent,
  ArPointerDeactivatedEvent,
  AuviousRtcService,
  ConferenceService,
  debug,
  debugError,
  NotificationService,
  PointerService,
  SketchAvailabilityChangedEvent,
  SketchService,
  StreamState,
} from "../../../services";
import { CobrowseService } from "../../../services/cobrowse.service";
import { CobrowseFrameComponent } from "../../cobrowse-frame/cobrowse-frame.component";
import { TileComponent } from "../../tile/tile.component";
import { Util } from "@auvious/common";

@Component({
  selector: "app-sketch-area",
  templateUrl: "./sketch-area.component.html",
  styleUrls: ["./sketch-area.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SketchAreaComponent implements OnInit, OnDestroy {
  @Input()
  set viewSize(value: IArea) {
    this._viewSize = value;

    if (this._id) {
      this.sketchService.resize(value, this._id);
    } else if (value.width > 0 && value.height > 0) {
      this.connectOnViewSizeAvailable();
    }
  }

  get viewSize() {
    return this._viewSize;
  }

  @Input()
  set scaleRatio(value: number) {
    this._scaleRatio = value;

    if (this._id) {
      this.sketchService.scale(value, this._id);
    }
  }

  get scaleRatio() {
    return this._scaleRatio;
  }

  @Input() endpoint: IEndpoint;
  @Input() stream: StreamState;

  /**
   * syns view size & coordinates to a base view size that is common for both local and remotes,
   * in case local and remotes have different window sizes.
   * In co-browse it is not needed. scale and actual width/height does the trick.
   * In tiles, we do not have the same area dimensions, so we need to sync.
   */
  @Input() sync = false;

  @Output() connected: EventEmitter<string> = new EventEmitter();
  @Output() ready: EventEmitter<HTMLCanvasElement> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() ended: EventEmitter<string> = new EventEmitter();

  @ViewChild("canvas") canvasRef: ElementRef<HTMLCanvasElement>;

  subscriptions: Subscription = new Subscription();
  activeTool: SketchToolEnum;

  private _id: string;
  private _viewSize: IArea;
  private _scaleRatio = 1;
  private _cobrowseOn = false;
  private _pointColors: { [endpoint: string]: ColorEnum } = {};
  private _isSettingUpExistingSketch = false;
  private _pendingConnectionId: string;
  private _notification: INotificationEvent;

  constructor(
    private sketchService: SketchService,
    private host: ElementRef<HTMLDivElement>,
    private cd: ChangeDetectorRef,
    private rtcService: AuviousRtcService,
    private pointerService: PointerService,
    private conferenceService: ConferenceService,
    private cobrowseService: CobrowseService,
    private renderer: Renderer2,
    private notification: NotificationService,
    @Optional() private tile: TileComponent,
    @Optional() private cobrowseFrame: CobrowseFrameComponent
  ) {}

  @HostBinding("class") get class() {
    return {
      "sketch-tool-marker": this.activeTool === SketchToolEnum.marker,
      "sketch-tool-arrow": this.activeTool === SketchToolEnum.arrow,
      "sketch-tool-eraser": this.activeTool === SketchToolEnum.eraser,
      // 'sketch-mirror': !this._isRequester
    };
  }

  @HostListener("mousewheel", ["$event"])
  private onScroll(event: Event): void {
    if (!this.cobrowseFrame) {
      return;
    }
    if (!this._notification) {
      this._notification = this.notification.info("Scrolling is disabled", {
        body: "You cannot scroll while you use one of the annotation controls. Stop annotating to resume scrolling.",
        ttl: 3000,
      });
      setTimeout(() => {
        this._notification = undefined;
      }, 3000);
      // this._notification = new ConversationNotification(
      //   "Scrolling is disabled",
      //   "You cannot scroll while you use one of the annotation controls. Stop annotating to resume scrolling.",
      //   Util.uuidgen()
      // );
      // (this._notification as ConversationNotification).onClicked(() => {
      //   this.notification.dismiss(this._notification);
      //   this._notification = null;
      // });
      // (this._notification as ConversationNotification).onDismissed(() => {
      //   this._notification = null;
      // });
      // this.notification.notify(this._notification);
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.cobrowseFrame?.viewSizeChanged.subscribe((value) => {
        this.renderer.setStyle(
          this.host.nativeElement,
          "width",
          value.sketch.width
        );
        this.renderer.setStyle(
          this.host.nativeElement,
          "height",
          value.sketch.height
        );
      })
    );
    this.subscriptions.add(
      this.tile?.viewSizeChanged.subscribe((value) => {
        this.renderer.setStyle(this.host.nativeElement, "width", value.width);
        this.renderer.setStyle(this.host.nativeElement, "height", value.height);
        // debug('tile viewSize changed: ', value);
        this.viewSize = value;
      })
    );
    this.subscriptions.add(
      this.sketchService.toolSelected$.subscribe((tool) => {
        this.activeTool = tool;
        this.cd.detectChanges();
      })
    );
    this.subscriptions.add(
      this.cobrowseService.started$.subscribe(async (data) => {
        this._cobrowseOn = true;
      })
    );
    this.subscriptions.add(
      this.cobrowseService.endRequest$
        .pipe(filter((_) => !this.tile))
        .subscribe(() => {
          debug("sketch-area:cobrowseService:endRequest$");
          this.updateCobrowseConferenceMetadata(false, null);
          this.notifyCobrowseParticipant(false);
          this._cobrowseOn = false;
        })
    );

    this.subscriptions.add(
      merge(
        this.conferenceService.conferenceMetadataSet$,
        this.cobrowseService.conferenceMetadataSet$
      )
        .pipe(filter((meta) => meta instanceof SketchMetadata))
        .subscribe((meta: SketchMetadata) => {
          debug(
            "sketch-area:conferenceMetadataSet$",
            meta,
            this.cobrowseService.target
          );
          // try to find active sketch
          const connectionId = Object.keys(meta.sketchMap).find((key) =>
            this.isOnCobrowse
              ? true // on a co-browse session the endpoint is the remote sender
              : // todo: be aware of race condition: endpoint joined may come after metadataSet
                // meta.sketchMap[key].target?.endpoint ===
                // this.cobrowseService.target.endpoint

                // on a ar session the endpoint is the stream originator
                meta.sketchMap[key].target?.endpoint ===
                  this.endpoint?.endpoint &&
                meta.sketchMap[key].mediaType === this.stream?.type
          );

          if (!connectionId) {
            return;
          }

          if (meta.sketchMap[connectionId].enabled && !this._id) {
            this.setupExistingSketch(connectionId);
          } else if (
            connectionId === this._id &&
            !meta.sketchMap[connectionId].enabled
          ) {
            this.sketchService.terminate(connectionId);
            this.cleanUp();
          }
        })
    );

    this.subscriptions.add(
      this.pointerService.pointAreaChange$
        .pipe(
          filter(
            (event) =>
              // I am the requester and this is the stream requested
              event.senderUserEndpointId === this.rtcService.endpoint() &&
              event.targetUserEndpointId === this.endpoint?.endpoint &&
              this.tile?.stream.type === event.targetStreamType
          )
        )
        .subscribe(async (event) => {
          let target;
          switch (event.type) {
            case ArPointerActivatedEvent.type:
              try {
                // if we started an AR Session, start a sketch session as well
                await this.connectToNewSketch();
                this.mirrorCanvas(this._id);
                target = {
                  endpoint: event.targetUserEndpointId,
                  username: event.targetUserId,
                };
                this.updateConferenceMetadata(true, target);
                // no need to notify participants. rely on conference metadata set
                // this.notifyConferenceParticipants(true, target);
              } catch (ex) {
                debugError(ex);
              }
              break;
            case ArPointerDeactivatedEvent.type:
              target = {
                endpoint: event.targetUserEndpointId,
                username: event.targetUserId,
              };
              this.updateConferenceMetadata(false, target);
              // this.notifyConferenceParticipants(false, target);
              this.sketchService.selectTool(null);
              this.sketchService.terminate(this._id);
              // deactivate sketch tools
              this.cleanUp();
              break;
          }
          this.cd.detectChanges();
        })
    );

    this.subscriptions.add(
      this.pointerService.pointColorsChange$.subscribe((colors) => {
        this._pointColors = colors;
        this.updateToolsColor(this._id);
      })
    );

    // listen to events for remote sketch initiation
    firstValueFrom(this.rtcService.getEventObservableAvailable()).then(
      (eventObservable) => {
        this.subscriptions.add(
          eventObservable
            .pipe(
              filter(
                (data) =>
                  SketchAvailabilityChangedEvent.type === data?.payload?.type &&
                  (data.payload as SketchAvailabilityChangedEvent)
                    .senderUserId !== this.rtcService.identity() &&
                  (data.payload as SketchAvailabilityChangedEvent)
                    .targetEndpointId === this.endpoint?.endpoint &&
                  (data.payload as SketchAvailabilityChangedEvent)
                    .targetMediaType === this.stream?.type
              )
            )
            .subscribe(async (data) => {
              debug(
                "sketch-area:SketchAvailabilityChangedEvent",
                data.payload,
                this.endpoint
              );
              const payload = data.payload as SketchAvailabilityChangedEvent;
              if (payload.available) {
                this.setupExistingSketch(payload.connectionId);
              } else {
                this.sketchService.terminate(payload.connectionId);
                this.cleanUp();
              }
            })
        );
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this._pendingConnectionId = null;
  }

  private async setupExistingSketch(connectionId: string) {
    try {
      if (this._isSettingUpExistingSketch) {
        return;
      }
      // wait for a viewSize to be set to connect, otherwise the canvas is of zero size
      if (
        !this._viewSize ||
        this._viewSize.height === 0 ||
        this._viewSize.width === 0
      ) {
        this._pendingConnectionId = connectionId;
        return;
      }

      this._isSettingUpExistingSketch = true;
      await this.connectToExistingSketch(connectionId);

      // only mirror for tile parent
      if (!this.isOnCobrowse) {
        this.mirrorCanvas(connectionId);
      } else {
        this.sketchService.clear(connectionId);
      }
    } catch (ex) {
      debugError(ex);
      this.sketchService.terminate(connectionId);
      this.cleanUp();
    } finally {
      this._isSettingUpExistingSketch = false;
    }
  }

  private async connectOnViewSizeAvailable() {
    if (this._pendingConnectionId) {
      debug("sketch-area:connect existing on resize", this._viewSize);
      this.setupExistingSketch(this._pendingConnectionId);
      this._pendingConnectionId = null;
    }
    // used to be on cobrowse.resized$ observable
    else if (
      this._cobrowseOn &&
      !this._id &&
      !this._isSettingUpExistingSketch &&
      !this.tile
    ) {
      debug("sketch-area:connect new on resize", this._viewSize);
      await this.connectToNewSketch();
      this.updateCobrowseConferenceMetadata(true, this.cobrowseService.target);
      this.notifyCobrowseParticipant(true);
    }
    // when coming from a refresh and we find a existing AR-pointer session
    else if (
      this.pointerService.isTarget(this.endpoint.endpoint, this.stream.type) &&
      !this._id &&
      !this._isSettingUpExistingSketch
    ) {
      debug("sketch-area:connect existing on ar-pointer refresh");

      const meta = this.sketchService.getConferenceMetadata();
      let activeSketchId;
      Object.keys(meta.sketchMap).forEach((sketchId) => {
        const sketch = meta.sketchMap[sketchId];
        if (
          sketch.enabled &&
          sketch.target.endpoint === this.endpoint.endpoint &&
          sketch.mediaType === this.stream.type
        ) {
          activeSketchId = sketchId;
        }
      });
      if (activeSketchId) {
        this.setupExistingSketch(activeSketchId);
      }
    }
  }

  private async connectToExistingSketch(id: string) {
    this._id = await this.sketchService.connect(
      this.canvasRef.nativeElement,
      this.sync,
      this.endpoint?.endpoint,
      id
    );
    debug("sketch-area: connected to existing sketch ", id);
    this.prepareCanvas(id);
    this.connected.emit(this._id);
    this.cd.markForCheck();
  }

  private async connectToNewSketch() {
    this._id = await this.sketchService.connect(
      this.canvasRef.nativeElement,
      this.sync,
      this.endpoint?.endpoint
    );
    debug("sketch-area: connected to new sketch ", this._id);
    this.prepareCanvas(this._id);
    this.connected.emit(this._id);
    this.cd.markForCheck();
  }

  private prepareCanvas(id) {
    if (this.viewSize) {
      this.sketchService.resize(this.viewSize, id);
    }
    if (!!this.scaleRatio && !this.sync) {
      this.sketchService.scale(this.scaleRatio, id);
    }
    debug("prepare canvas point color change");
    // change colors to sketch
    this.updateToolsColor(id);
  }

  private updateToolsColor(id: string) {
    if (!id) {
      return;
    }
    const myColor = this._pointColors[this.rtcService.endpoint()];
    if (!myColor) {
      return;
    }
    const myColorHex = COLOR[myColor.toUpperCase()];
    this.sketchService.setMarkerColor(myColorHex, id);
    this.sketchService.setArrowColor(myColorHex, id);
  }

  private mirrorCanvas(id) {
    // if the target is not me (my stream is mirrored) or the target is me and my stream is environment
    // mirror the canvas
    const isMe = this.endpoint?.endpoint === this.rtcService.endpoint();
    const isScreen = this.stream?.type === StreamTypes.SCREEN;
    const myStreams = this.conferenceService.getStreamsForEndpoint(
      this.endpoint?.endpoint
    );
    const myStream = myStreams[StreamTypes.CAM] || myStreams[StreamTypes.VIDEO];
    if (!myStream) {
      return;
    }
    const facingMode = this.stream?.video.facingMode;

    if (
      !isScreen &&
      (!isMe || (isMe && facingMode === VideoFacingModeEnum.Environment))
    ) {
      // if (
      //   (!isMe && !isScreen) ||
      //   (isMe && !isScreen && facingMode === VideoFacingModeEnum.Environment)
      // )
      this.sketchService.mirror(id);
    }
  }

  private updateConferenceMetadata(enabled: boolean, target: IEndpoint) {
    const meta = this.sketchService.getConferenceMetadata();
    meta.setEnabled(this._id, enabled, target, this.stream?.type);
    this.sketchService.setConferenceMetadata(meta);

    // update session state
    this.conferenceService.setConferenceMetadata(meta);
  }

  private updateCobrowseConferenceMetadata(
    enabled: boolean,
    target: IEndpoint
  ) {
    const metadata = this.sketchService.getConferenceMetadata();
    metadata.setEnabled(this._id, enabled, target, "unknown");
    this.sketchService.setConferenceMetadata(metadata);

    // update session state
    this.cobrowseService.context === "conference"
      ? this.conferenceService.setConferenceMetadata(metadata)
      : this.cobrowseService.setConferenceMetadata(metadata);
  }

  private notifyCobrowseParticipant(enabled: boolean) {
    if (this.cobrowseService.target) {
      this.sketchService.notifyAvailabilityChanged(
        this.cobrowseService.target,
        this.cobrowseService.target,
        this._id,
        enabled,
        this.stream?.type
      );
    }
  }

  private notifyConferenceParticipants(
    enabled: boolean,
    sketchTarget: IEndpoint
  ) {
    // notify remote participants
    const participants = this.conferenceService.getParticipants();
    Object.keys(participants).forEach((key) => {
      this.sketchService.notifyAvailabilityChanged(
        participants[key],
        sketchTarget,
        this._id,
        enabled,
        this.stream?.type
      );
    });
  }

  private cleanUp() {
    this._id = null;
    this.ended.emit();
  }

  get container(): HTMLDivElement {
    return this.host.nativeElement;
  }

  private get isOnCobrowse() {
    return !!this.cobrowseFrame;
  }
}
