import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IInteraction, NotificationService } from "../../../../core-ui";
import { InteractionService, QRService } from "../../../services";
import { GenericErrorHandler } from "../../../services/error-handlers.service";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsService } from "../../../../core-ui/services/analytics.service";
import { IShareLinkAction } from "../share-link.component";

@Component({
  selector: "app-share-link-qr",
  templateUrl: "./qr.component.html",
  styleUrls: ["./qr.component.scss"],
})
export class ShareLinkQrComponent implements OnInit, IShareLinkAction {
  @Input() interaction: IInteraction;
  @Input() link: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private interactionService: InteractionService,
    private analyticsService: AnalyticsService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService,
    private notification: NotificationService,
    private qrService: QRService
  ) {}

  ngOnInit(): void {}

  shareLink() {
    if (!this.thereIsACustomerWhichSupportsQRCodeLink) {
      return;
    }
    this.qrService.sendQRLinkEvent(this.link);
    this.notification.show("auvious", {
      body: this.translate.instant(
        "The customer link has been sent as a QR code."
      ),
    });
    // this.shareOpen = false;
    this.openChange.emit(false);

    // this.renewCustomerLink();
    this.completed.emit();
  }

  get thereIsACustomerWhichSupportsQRCodeLink(): boolean {
    return this.qrService.isThereACustomerWhichSupportsQRCodeLink();
  }
}
