import { Component, OnInit, Renderer2 } from "@angular/core";

import {
  TagManagerService,
  EventService,
  ThemeService,
  KeyboardService,
  KeyCodes,
} from "../core-ui/services";
import { LocaleService } from "./services/locale.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    gtmService: TagManagerService,
    eventService: EventService,
    localization: LocaleService,
    renderer: Renderer2,
    theme: ThemeService,
    private keyboard: KeyboardService
  ) {
    eventService.start();
    localization.init();

    // Google Tag Manager setup
    gtmService.trackPages();

    // cannot inject renderer to service
    theme.setRenderer(renderer);
  }

  ngOnInit() {
    this.keyboard.listen(KeyCodes.Enter, this.onEnterKey);
  }

  private onEnterKey = () => {
    if (
      document.activeElement &&
      document.activeElement.hasAttribute("tabindex") &&
      document.activeElement.tagName === "AV-MENU-ITEM"
    ) {
      document.activeElement.dispatchEvent(new MouseEvent("click"));
    }
  };
}
