<div class="container text-center" *ngIf="isNotFound">
  <h1 translate>Video room not found</h1>
  <p translate *ngIf="!isIFrame && !isAgent">
    The call was ended by the agent. Please request for a new video call.
  </p>
  <p translate *ngIf="isIFrame || isAgent" class="text-center">
    <a (click)="back()" avButton color="link"
      >&larr; {{ "Back" | translate }}</a
    >
  </p>
</div>

<div class="container text-center" *ngIf="isScheduleNotFound">
  <h1 translate>Video room not found</h1>
  <p translate>Please contact our team to schedule a new video call.</p>
</div>

<div class="container text-center" *ngIf="isParticipantLimitReached">
  <h1 translate>Maximum participants reached</h1>
  <p translate>
    Please notify the agent that provided the link that you cannot join.
  </p>
</div>

<div class="container text-center" *ngIf="isScheduledLater">
  <h1 translate>Video room is not available at this time</h1>
  <p translate>
    This call has been scheduled for a later time. You can join 5 minutes before
    the scheduled start time.
  </p>
</div>

<div class="container text-center" *ngIf="isNotSupported">
  <h1 translate>{{ errorTitle }}</h1>
  <p translate>
    {{ error }}
  </p>

  <div *ngIf="isMobileChromeRequired">
    <img
      class="browser-icon"
      src="assets/images/chrome-icon.svg"
      alt="Google Chrome"
    />
    <a
      class="btn"
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.android.chrome"
      >Download</a
    >
  </div>
  <div *ngIf="isMobileSafariRequired">
    <img
      class="browser-icon"
      src="assets/images/safari-icon.svg"
      alt="Apple Safari"
    />
  </div>
  <div *ngIf="isDesktopChromeRequired">
    <img
      class="browser-icon"
      src="assets/images/chrome-icon.svg"
      alt="Google Chrome"
    />
    <a class="btn" target="_blank" href="https://www.google.com/chrome/"
      >Download</a
    >
  </div>
</div>

<div class="container text-center" *ngIf="isSessionExpired">
  <h1 translate>Session Expired</h1>
  <p translate>Please request a new link from the available agent.</p>
  <div class="text-muted" *ngIf="isAppointmentTokenExpired">
    <p translate>
      You are getting this message because this is not your first time accessing
      this link.
    </p>
    <p translate>
      If the first time you could not join our call, most probably the meeting
      was cancelled and our notification hasn't reached you yet. We are sorry
      for any inconvenience created.
    </p>
  </div>
</div>

<div class="container text-center" *ngIf="isAccessDenied">
  <h1 translate>Access Denied</h1>
  <p translate>Oups! You shouldn't be here.</p>
</div>

<div class="container text-center" *ngIf="isPageNotFound">
  <h1 translate>404</h1>
  <p translate>Oups! Page not found.</p>
</div>

<div class="container text-center" *ngIf="isIFrame && isCookiesDisabledError">
  <h1 translate>Cookies Disabled</h1>
  <p translate>Please enable third party cookies and try again.</p>
</div>

<div class="container text-center" *ngIf="!isIFrame && isCookiesDisabledError">
  <h1 translate>Cookies Disabled</h1>
  <p translate>Please enable cookies and try again.</p>
</div>

<div class="container text-center" *ngIf="isPurecloudInitError">
  <p translate>Could not initialize application.</p>
</div>

<div class="container text-center" *ngIf="isGenericError">
  <h1 translate>Oups! Something unexpected happened</h1>
  <p translate>could not connect</p>
 <p>{{ error }}</p>
</div>

<div class="container text-center" *ngIf="isAuthenticationError">
  <h1 translate>Authentication failed</h1>
  <p translate>{{ error }}</p>
</div>

<div class="container text-center" *ngIf="isRTCConnectionError">
  <h1 translate>Connection to RTC failed</h1>
  <p translate>
    Please make sure you have opened all the required ports and that the service
    is up and running
  </p>
</div>

<div class="container text-center" *ngIf="isAppointmentCompleted">
  <h1 translate>Appointment has been completed</h1>
  <p translate>Please request a new appointment</p>
</div>

<div class="container text-center" *ngIf="isLicenseRequired">
  <h1 translate>License required</h1>
  <p translate>You don't have the proper license to access this page</p>
  <p translate class="text-center">
    <a (click)="back()" avButton color="link"
      >&larr; {{ "Back" | translate }}</a
    >
  </p>
</div>

<div class="container text-center" *ngIf="isAppointmentNotFound">
  <h1 translate>Appointment not found</h1>
  <p translate>Please request a new appointment</p>
</div>

<div class="container text-center" *ngIf="isConversationFailed">
  <h1 translate>Could not estabish a connection</h1>
  <p translate>Please follow the appointment link to try again</p>
</div>

<div class="container text-center" *ngIf="isTicketNotFound">
  <h1 translate>Ticket has expired or is invalid</h1>
  <p translate>Please request a new one</p>
</div>

<div class="copy-container" *ngIf="!!redirectUrl">
  <h2 class="copy-container-title" translate>Room Link</h2>
  <div class="textarea-wrapper">
    <div class="textarea-container">
      <textarea rows="2" disabled>{{ redirectUrl }}</textarea>
    </div>
    <div>
      <button avButton color="accent" (click)="copy()" translate>
        Copy to clipboard
      </button>
    </div>
  </div>
</div>
