import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
  signal,
  computed,
} from "@angular/core";
import { UserService } from "../../../../../core-ui/services/user.service";
import { IContact } from "../../../../../core-ui/models/Appointment";
import { NotificationService } from "../../../../../core-ui/services/notification.service";
import {
  PageableRequestBuilder,
  IPagedResponse,
} from "../../../../../core-ui/models/Pageable";
import { IFilterSelection } from "../../../../../core-ui/models/interfaces";

@Component({
  selector: "app-agent-filter",
  templateUrl: "./agent-filter.component.html",
  styleUrls: ["./agent-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentFilterComponent implements OnInit {
  @Input()
  set selection(value: string[]) {
    if (value) {
      this.selectedContacts.set(value);
    }
  }

  @Input() type: "radio" | "checkbox" = "checkbox";

  @Output() selectionChange: EventEmitter<string[]> = new EventEmitter();

  contacts = signal<IContact[]>([]);
  contactsFiltered = signal<IContact[]>([]);
  loading = signal(false);
  contactNames = signal<{ [id: string]: string }>({});
  selectedContacts = signal<string[]>([]);

  isLastPage = signal(true);
  isEmptySearch = signal(false);

  page = 0;
  constructor(
    private user: UserService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.nextPage();
  }

  async nextPage(page: number = 1) {
    try {
      this.loading.set(true);
      if (page === 1) {
        this.contacts.set([]);
      }
      this.page = page;
      const query = new PageableRequestBuilder()
        .pageNumber(page)
        .pageSize(100)
        .build();
      const pager = await this.user.queryContacts(query);

      //
      this.isLastPage.set(pager?.pageCount === pager?.pageNumber);
      this.contacts.update((c) => [...c, ...pager.entities]);
      this.contactsFiltered.set(this.contacts());
      this.contactNames.set(
        this.contacts().reduce(
          (map, c) => ({ ...map, [c.id]: this.getName(c) }),
          {}
        )
      );
    } catch (ex) {
      this.notification.error("Could not query contacts", {
        body: "Please review your access rights",
      });
    } finally {
      this.loading.set(false);
    }
  }

  more() {
    this.nextPage(this.page + 1);
  }

  getName(member: IContact) {
    return member.name?.firstName || member.name?.lastName
      ? [member.name?.firstName, member.name?.lastName].join(" ").trim()
      : member.contact.primaryEmail;
  }

  async queryChange(query: string) {
    const qr = query.toLowerCase();
    const filtered = this.contacts().filter((c) =>
      this.getName(c).toLowerCase().includes(qr)
    );
    if (filtered.length > 0) {
      this.contactsFiltered.set(filtered);
    } else if (!this.isLastPage()) {
      await this.nextPage(this.page + 1);
      return this.queryChange(query);
    }
    this.isEmptySearch.set(
      query?.length > 0 && this.contactsFiltered().length === 0
    );
  }

  selectionChanged(selection: IFilterSelection<IContact>) {
    switch (this.type) {
      case "checkbox":
        this.selectedContacts.update((sc) =>
          selection.checked
            ? [...sc, selection.value.id]
            : sc.filter((c) => c !== selection.value.id)
        );
        break;
      case "radio":
        this.selectedContacts.set(
          selection.checked ? [selection.value.id] : []
        );
        break;
    }
    this.selectionChange.emit(this.selectedContacts());
  }

  isChecked(id: string) {
    return computed(() => this.selectedContacts().includes(id));
  }
}
