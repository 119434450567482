import { ApiResource } from "@auvious/common";
import {
  ConversationOriginEnum,
  IApplicationConfig,
  INiceApplicationConfig,
  IPagedResponse,
  IUser,
  IUserDetails,
  UserService,
} from "../../../../core-ui";
import {
  AppointmentChannelEnum,
  CLAIM_OPEN_ID,
  ConversationDestinationEnum,
} from "../../../../core-ui/core-ui.enums";
import { IIntegrationStrategy } from "../../../../core-ui/models/strategies";
import { NiceService } from "../../../services/nice.service";
import { debugError } from "../../../../app/app.utils";

export class NiceIntegrationStrategy implements IIntegrationStrategy {
  securityService: ApiResource;
  refreshTokenRetries = 0;

  constructor(
    private niceservice: NiceService,
    private userService: UserService
  ) {}

  installIntegration(integration, origin) {
    return null;
  }

  async getIntegrations() {
    return [];
  }

  getIntegrationURL() {
    return null;
  }

  deleteIntegration() {
    return null;
  }

  getIntegrationId() {
    return null;
  }

  getOauthClientId() {
    return null;
  }

  isInstalled() {
    return true;
  }

  supportsInteractionDestination(
    destination: ConversationDestinationEnum
  ): boolean {
    return [ConversationDestinationEnum.STANDALONE].includes(destination);
  }

  supportsInteractionOrigin(origin: ConversationOriginEnum): boolean {
    return [
      ConversationOriginEnum.CHAT,
      ConversationOriginEnum.WIDGET,
    ].includes(origin);
  }

  supportsAppointmentChannel(channel: AppointmentChannelEnum): boolean {
    return false;
  }

  supportsMemberRoles(): boolean {
    return true;
  }

  getIntegrationIdentifier() {
    return null;
  }

  getIntegrationUserId(user: IUser): string {
    return user.getClaim(CLAIM_OPEN_ID);
  }

  getMember(id: string): Promise<IUserDetails> {
    // todo: implement
    return null;
  }

  async getMembers(
    page: number,
    size: number
  ): Promise<IPagedResponse<IUserDetails>> {
    const response = await this.niceservice.getUsers(page - 1, size);
    const entities: IUserDetails[] = response.users.map((u) => ({
      id: `user:${u.id}`,
      name: u.fullName || [u.firstName, u.lastName].join(" ").trim(),
      email: u.emailAddress,
      displayName: u.fullName || [u.firstName, u.lastName].join(" ").trim(),
    }));
    const r: IPagedResponse<IUserDetails> = {
      pageCount: Math.ceil(response.totalRecords / response.top),
      pageNumber: Math.ceil(response.skip + 1 / response.top),
      pageSize: response.top,
      total: response.totalRecords,
      entities,
    };
    return r;
  }

  hasLicense(key: "freetrial" | "cobrowse" | "video"): boolean {
    return this.userService.getActiveUser().hasLicense(key);
  }

  getExportableConfig(config: INiceApplicationConfig): IApplicationConfig {
    try {
      const deepCopy = JSON.stringify(config);
      const cf: INiceApplicationConfig = JSON.parse(deepCopy);

      // remove params that are application specific
      delete cf.openidParameters;
      return cf;
    } catch (ex) {
      return config;
    }
  }
}
