import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { init, captureException } from "@sentry/angular-ivy";

import { AppConfigService } from "./app.config.service";
import { debugError } from "./utils";
import { AUVIOUS_PURECLOUD_VERSION } from "../core-ui.enums";
import { localStore } from "@auvious/utils";
import { Subject } from "rxjs";

const isSentryEnabled = () =>
  location.hostname !== "localhost" &&
  !localStore.getItem("auvious.sentry.disabled");

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
    if (isSentryEnabled()) {
      const config = this.injector.get(AppConfigService);
      init({
        dsn: config.sentryDSN,
        environment: config.sentryEnvironment,
        release: window[AUVIOUS_PURECLOUD_VERSION] || "NO-VERSION",
        beforeBreadcrumb(event, hint) {
          try {
            const target = hint?.event?.target;
            if (target?.tagName) {
              event.message = `${event.message} (${
                target.hasAttribute("data-tid")
                  ? target.getAttribute("data-tid")
                  : target.parentElement.hasAttribute("data-tid")
                  ? target.parentElement.getAttribute("data-tid")
                  : target.innerText.trim().slice(0, 100)
              })`;
            }
          } catch {}

          return event;
        },
      });
    }
  }

  handleError(error) {
    captureException(error.originalError || error);
    console.error(error);
  }
}

@Injectable()
export class GenericErrorHandler implements ErrorHandler {
  private _connectionError = new Subject();
  public connectionError$ = this._connectionError.asObservable();

  constructor(private sentry: SentryErrorHandler) {}

  public notifyConnectionError(error) {
    this._connectionError.next(error);
  }

  public handleNotAuthenticatedError(error) {
    if (error.message.includes("non authenticated client")) {
      this.notifyConnectionError(error);
    } else {
      throw error;
    }
  }

  public handleError(error) {
    if (isSentryEnabled()) {
      return this.sentry.handleError(error);
    } else {
      debugError(error);
    }
  }
}

class LocalErrorHandler implements ErrorHandler {
  handleError(error) {
    debugError(error);
  }
}

export function provideErrorHandler(injector: Injector): ErrorHandler {
  if (isSentryEnabled()) {
    return new SentryErrorHandler(injector);
  } else {
    return new LocalErrorHandler();
  }
}
