<ng-container *ngFor="let filter of filters; trackBy: trackByFn">
  <app-background-effect
    role="button"
    [effect]="filter"
    [active]="filter.id === activeFilter?.id"
    [activeEffect]="activeFilter"
    (error)="effectError($event)"
    data-tid="background-effects/select-effect"
    tabindex="0"
  ></app-background-effect>
</ng-container>
<av-spinner *ngIf="filters.length === 0" size="md"></av-spinner>
