import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import {
  ApplicationTypeEnum,
  AUVIOUS_PURECLOUD_VERSION,
} from "../../core-ui.enums";
import {
  ThemeService,
  DeviceService,
  ThemeVariableEnum,
  ThemeSupportFlagEnum,
} from "../../services";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: "app-theme-base",
  templateUrl: "./theme-base.component.html",
  styleUrls: ["./theme-base.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeBaseComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  style: { [property: string]: any };
  version: string;
  isVersionVisible = true;
  // supportsBackdropBlur = true;

  constructor(
    private themeService: ThemeService,
    private application: ApplicationService,
    private cd: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute
  ) {
    this.subscriptions = new Subscription();

    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        const path = route.children?.[0]?.snapshot.url?.[0].path;
        this.isVersionVisible = !["a", "t"].includes(path);
        this.cd.detectChanges();
      });

    // load version
    this.version = window[AUVIOUS_PURECLOUD_VERSION] || "1.0.0";
    const chunks = this.version.split("-");
    if (chunks.length > 1) {
      chunks.shift();
      this.version = chunks.join("-");
    }
    // this.themeService.setSupportFlag(
    //   ThemeSupportFlagEnum.backdropBlur,
    //   !DeviceService.isFirefox
    // );
  }

  @HostBinding("class") get class() {
    return {
      "theme-talkdesk": this.isTalkdeskApp,
    };
  }

  ngOnInit() {
    this.subscriptions.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        if (theme?.customThemeCSS) {
          this.themeService.addStylesheetRules(theme.customThemeCSS);
        } else {
          const defaultBkg = this.themeService.getBackgroundColorForApplication(
            this.application.getActiveApplication()
          );

          const background = !theme?.backgroundColor
            ? defaultBkg
            : theme.backgroundColor;

          const variables = {
            [ThemeVariableEnum.backgroundColor]: background,
          };

          if (theme?.color) {
            variables[ThemeVariableEnum.fontColor] = theme.color;
          }

          if (theme?.buttonPrimaryBackgroundColor) {
            variables[ThemeVariableEnum.accentBackgroundColor] =
              theme.buttonPrimaryBackgroundColor;
          }

          const variablesToString = Object.keys(variables)
            .map((key) => `${key}: ${variables[key]}`)
            .join(";");

          const rules = `:root { ${variablesToString} }`;

          this.themeService.addStylesheetRules(rules);
        }

        this.themeService.setDarkMode(theme?.darkMode);

        this.cd.detectChanges();
      })
    );
  }

  get isMobile() {
    return DeviceService.isMobile;
  }

  get isTalkdeskApp() {
    return this.application.isTalkdeskApp;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
