import { Injectable } from "@angular/core";
import { IApplication } from "../models/IApplication";
import { IInteraction } from "../models/IInteraction";
import { ApplicationService } from "./application.service";
import { IAnalyticsStrategy } from "../models/strategies";
import { IRecorderInfo, IRecorderSessionInfo } from "../models/IRecorder";
import { InteractionService as PerfomanceInteractionService } from "@auvious/interaction";
import { PagedCollection } from "@auvious/common";
import { InteractionMetrics } from "../models/InteractionMetrics";
import { InteractionMetric, InteractionMetricEnum } from "../core-ui.enums";
import { IEndpointMetadata, PublicParam } from "../models";
import { IEndpoint } from "@auvious/rtc";
import { ConversationTypeEnum } from "@auvious/integrations";
import { AppConfigService } from "./app.config.service";
import { MediaRulesService } from "./media.rules.service";

@Injectable()
export class AnalyticsService implements IAnalyticsStrategy {
  constructor(
    private applicationService: ApplicationService,
    private interactionService: PerfomanceInteractionService,
    // we should use mediaRules but we have a circular dependency
    private config: AppConfigService
  ) {}

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private get impl(): IAnalyticsStrategy {
    return this.application.analyticsStrategy();
  }

  trackCallRequested(
    interaction: IInteraction,
    persistOnIntegration: boolean = true
  ): Promise<string> {
    if (persistOnIntegration) {
      this.impl.trackCallRequested(interaction);
    }
    return this.createInteractionMetrics(interaction);
  }

  trackCobrowseRequested(
    interaction: IInteraction,
    endpoint: IEndpoint<IEndpointMetadata>
  ): void {
    this.updateInteractionMetrics(interaction, {
      [InteractionMetricEnum.cobrowseViewRequesteddAt]:
        new Date().toISOString(),
      [InteractionMetricEnum.roomName]: interaction.getRoom(),
      [InteractionMetricEnum.originUrl]: endpoint.metadata?.originUrl,
      [InteractionMetricEnum.customerDisplayName]: endpoint.metadata?.name,
      [InteractionMetricEnum.customerMetadata]:
        endpoint.metadata?.customerMetadata,
    });
  }

  trackInvitationSent(interaction: IInteraction): void {
    this.impl.trackInvitationSent(interaction);
    this.updateInteractionMetrics(interaction, {
      [InteractionMetricEnum.ticketSentAt]: new Date().toISOString(),
      [InteractionMetricEnum.roomName]: interaction.getRoom(),
    });
  }

  trackCallEnding(interaction: IInteraction): Promise<void> {
    return this.impl.trackCallEnding(interaction);
  }

  trackCallTransferred(interaction: IInteraction): Promise<void> {
    this.impl.trackCallTransferred(interaction);
    return this.updateInteractionMetrics(interaction, {
      [InteractionMetricEnum.callTransferredAt]: new Date().toISOString(),
    });
  }

  async trackCallEnded(
    interaction: IInteraction,
    persistOnIntegration: boolean = true
  ): Promise<void> {
    if (persistOnIntegration) {
      await this.impl.trackCallEnded(interaction);
    }
    return this.updateInteractionMetrics(interaction, {
      [InteractionMetricEnum.callEndAt]: new Date().toISOString(),
    });
  }

  async trackConferenceRoomEnded(interaction: IInteraction): Promise<void> {
    return this.impl.trackConferenceRoomEnded(interaction);
  }

  trackRecordingStarted(
    recorder: IRecorderInfo,
    interaction: IInteraction,
    type: "media" | "co-browse" = "media"
  ): void {
    this.impl.trackRecordingStarted(recorder, interaction, type);
    let metrics: InteractionMetric;
    switch (type) {
      case "media":
        metrics = {
          [InteractionMetricEnum.recorderId]: recorder.recorderId,
          [InteractionMetricEnum.recorderInstanceId]: recorder.instanceId,
        };
        break;
      case "co-browse":
        metrics = {
          [InteractionMetricEnum.cobrowseRecorderId]: recorder.recorderId,
          [InteractionMetricEnum.cobrowseRecorderInstanceId]:
            recorder.instanceId,
        };
        break;
    }
    this.updateInteractionMetrics(interaction, metrics);
  }

  trackRecordingStopped(
    session: IRecorderSessionInfo,
    interaction: IInteraction,
    logToImplementation = true
  ): void {
    if (logToImplementation) {
      this.impl.trackRecordingStopped(session, interaction);
    }
    this.updateInteractionMetrics(interaction, {
      [InteractionMetricEnum.recordingUrl]: session.url,
      [InteractionMetricEnum.recorderStorageProvider]: session.provider,
    });
  }

  async updateInteractionMetrics(
    interaction: IInteraction,
    data: InteractionMetric
  ): Promise<void> {
    try {
      const metric = await this.getInteractionMetrics(interaction.getId());
      for (const key in data) {
        if (key in data) {
          if (key === InteractionMetricEnum.customerMetadata) {
            if (
              this.config.publicParam(PublicParam.CUSTOMER_METADATA_ENABLED)
            ) {
              metric.setMetric(
                key as InteractionMetricEnum,
                MediaRulesService.filterCustomerMetadata(
                  this.config.publicParam(
                    PublicParam.CUSTOMER_METADATA_BLACKLIST
                  ),
                  { ...metric.getCustomerMetadata(), ...data[key] }
                )
              );
            }
          } else {
            metric.setMetric(key as InteractionMetricEnum, data[key]);
          }
        }
      }
      return this.interactionService.update(
        metric.getId(),
        metric.getCallType(),
        metric.getMetrics()
      );
    } catch (ex) {
      // don't interrupt flow
    }
  }

  async createInteractionMetrics(interaction: IInteraction) {
    try {
      const exists = await this.interactionService.get(interaction.getId());
      if (exists) {
        return;
      }
    } catch (ex) {
      // do nothing
    }
    return this.interactionService.create(
      interaction.getId(),
      interaction.getType(),
      {
        [InteractionMetricEnum.integrationInteractionAvailable]:
          interaction.isIntegrationInteractionAvailable(),
        [InteractionMetricEnum.callStartAt]: new Date().toISOString(),
        [InteractionMetricEnum.callOrigin]: interaction.getOrigin(),
        [InteractionMetricEnum.callOriginMode]: interaction.getOriginMode(),
        [InteractionMetricEnum.callDestination]: interaction.getDestination(),
        [InteractionMetricEnum.roomName]:
          interaction.getType() === ConversationTypeEnum.callback
            ? interaction.getRoom()
            : null,
        [InteractionMetricEnum.customerId]: interaction.getCustomerId(),
        [InteractionMetricEnum.customerMetadata]: this.config.publicParam(
          PublicParam.CUSTOMER_METADATA_ENABLED
        )
          ? MediaRulesService.filterCustomerMetadata(
              this.config.publicParam(PublicParam.CUSTOMER_METADATA_BLACKLIST),
              interaction.getMetadata()
            )
          : undefined,
      }
    );
  }

  async queryInteractionMetrics(
    query: {
      startAt?: Date;
      endAt?: Date;
      userIds?: string[];
      recording?: ("media" | "cobrowse")[];
      type?: ("video" | "audio" | "chat" | "cobrowse")[];
    },
    page: number,
    pageSize: number = 20
  ): Promise<PagedCollection<InteractionMetrics>> {
    try {
      const data = await this.interactionService.getPage(
        query.startAt,
        query.endAt,
        page,
        pageSize,
        "createdAt,desc",
        {
          userIds: query.userIds,
          recording: query.recording,
          type: query.type,
        }
      );
      const content = data.content.map((m) => new InteractionMetrics(m));
      return {
        ...data,
        content,
      };
    } catch (ex) {
      throw ex;
    }
  }

  async getInteractionMetrics(
    interactionId: string
  ): Promise<InteractionMetrics> {
    try {
      const data = await this.interactionService.get(interactionId);
      return new InteractionMetrics(data);
    } catch (ex) {
      throw ex;
    }
  }
}
