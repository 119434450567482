import { Injectable } from "@angular/core";

import { IIntegrationStrategy } from "../../core-ui/models/strategies";
import {
  IApplication,
  ConversationOriginEnum,
  ConversationDestinationEnum,
  IUserDetails,
  IPagedResponse,
  PageableRequest,
  IUser,
  AppointmentChannelEnum,
  IApplicationConfig,
  AgentParam,
  CustomerParam,
  PublicParam,
} from "../../core-ui";
import { ApplicationService } from "../../core-ui/services/application.service";
import { IIntegration } from "../models";

@Injectable()
export class IntegrationService implements IIntegrationStrategy {
  constructor(private applicationService: ApplicationService) {}

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private get impl(): IIntegrationStrategy {
    return this.application.integrationStrategy();
  }

  async installIntegration(
    integration: IIntegration,
    destination?: ConversationDestinationEnum
  ) {
    return this.impl.installIntegration(integration, destination);
  }

  isInstalled(integration: IIntegration) {
    return this.impl.isInstalled(integration);
  }

  getIntegrations(): Promise<IIntegration[]> {
    return this.impl.getIntegrations();
  }

  getIntegrationId() {
    return this.impl.getIntegrationId();
  }

  deleteIntegration(id: string): Promise<void> {
    return this.impl.deleteIntegration(id);
  }

  getIntegrationIdentifier() {
    return this.impl.getIntegrationIdentifier();
  }

  getIntegrationURL(destination: ConversationDestinationEnum) {
    return this.impl.getIntegrationURL(destination);
  }

  getOauthClientId(env?: string) {
    return this.impl.getOauthClientId(env);
  }

  supportsInteractionDestination(destination: ConversationDestinationEnum) {
    return this.impl.supportsInteractionDestination(destination);
  }

  supportsInteractionOrigin(origin: ConversationOriginEnum): boolean {
    return this.impl.supportsInteractionOrigin(origin);
  }

  supportsAppointmentChannel(channel: AppointmentChannelEnum): boolean {
    return this.impl.supportsAppointmentChannel(channel);
  }

  supportsMemberRoles(): boolean {
    return this.impl.supportsMemberRoles();
  }

  getMember(id: string): Promise<IUserDetails> {
    return this.impl.getMember(id);
  }

  getIntegrationUserId(user: IUser): string {
    return this.impl.getIntegrationUserId(user);
  }

  getMembers(
    page: number,
    size: number
  ): Promise<IPagedResponse<IUserDetails>> {
    return this.impl.getMembers(page, size);
  }

  hasLicense(key: "freetrial" | "cobrowse" | "video"): boolean {
    return this.impl.hasLicense(key);
  }

  getExportableConfig(config: IApplicationConfig): IApplicationConfig {
    const integrationConfig = this.impl.getExportableConfig(config);
    // also clear common properties

    const agentParamsToDelete = [
      AgentParam.RECORDER_CONFIGURED,
      AgentParam.RECORDER_AUTO_START,
      AgentParam.COBROWSE_RECORD,
      AgentParam.DEFAULT_BACKGROUND_EFFECT_ID,
    ];
    const customerParamsToDelete = [CustomerParam.FILE_TRANSFER_ENABLED];
    const publicParamsToDelete = [
      PublicParam.ASR_AUTO_START,
      PublicParam.ASR_ENABLED,
      PublicParam.ASR_DEFAULT_LANGUAGE,
      PublicParam.ASR_PROVIDERS_CONFIGURED,
      PublicParam.CUSTOM_DOMAIN_URL,
    ];

    agentParamsToDelete.forEach(
      (param) => delete integrationConfig.agentParameters[param]
    );
    customerParamsToDelete.forEach(
      (param) => delete integrationConfig.customerParameters[param]
    );
    publicParamsToDelete.forEach(
      (param) => delete integrationConfig.publicParameters[param]
    );
    delete integrationConfig.serviceParameters;
    delete integrationConfig.supervisors;
    delete integrationConfig.admins;

    return integrationConfig;
  }

  mergeConfigs(
    oldValue: IApplicationConfig,
    newValue: IApplicationConfig
  ): IApplicationConfig {
    //remove again unnecessary properties in case the use has manually made changes to config
    const nuVal = this.getExportableConfig(newValue);

    const integrationConfig = {
      agentParameters: {
        ...oldValue.agentParameters,
        ...nuVal.agentParameters,
      },
      customerParameters: {
        ...oldValue.customerParameters,
        ...nuVal.customerParameters,
      },
      publicParameters: {
        ...oldValue.publicParameters,
        ...nuVal.publicParameters,
      },
    };

    return integrationConfig;
  }
}
