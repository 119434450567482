<!-- snapshot -->
<av-tooltip
  [position]="tooltipPosition"
  message="Snapshot"
  *ngIf="isSnapshotAvailable"
>
  <button
    aria-label="Take snapshot"
    avButton
    [raised]="true"
    ofType="icon"
    size="xs"
    color="basic"
    (click)="snapshot()"
    data-tid="point-controls/snapshot"
  >
    <av-icon name="camera"></av-icon>
  </button>
</av-tooltip>

<av-tooltip
  [position]="tooltipPosition"
  message="Freeze video"
  *ngIf="isFreezeFrameAvailable"
>
  <button
    aria-label="Freeze video"
    avButton
    [raised]="true"
    ofType="icon"
    size="xs"
    [color]="isFrameFrozen ? 'accent' : 'basic'"
    (click)="freezeFrame()"
    data-tid="point-controls/freeze-frame"
  >
    <av-icon name="snowflake"></av-icon>
  </button>
</av-tooltip>

<av-tooltip
  *ngIf="isFreezeFrameAvailable"
  [position]="tooltipPosition"
  message="Zoom in"
>
  <button
    avButton
    [raised]="true"
    aria-label="Zoom in"
    [disabled]="!isFrameFrozen"
    ofType="icon"
    size="xs"
    [color]="isZoomOn ? 'accent' : 'basic'"
    (click)="zoom()"
    data-tid="point-controls/zoom"
  >
    <av-icon name="zoom-in"></av-icon>
  </button>
</av-tooltip>

<!-- pointer -->
<av-tooltip [position]="tooltipPosition" message="Pointer">
  <button
    [attr.aria-label]="(isPointerActive ? '' : 'Enable') + ' AR pointer'"
    avButton
    [raised]="true"
    ofType="icon"
    size="xs"
    [color]="isPointerActive ? 'accent' : 'basic'"
    (click)="pointer()"
    data-tid="point-controls/pointer"
  >
    <av-icon name="laser"></av-icon>
  </button>
</av-tooltip>
