import {
  AgentParam,
  AuviousRtcService,
  ConversationOriginEnum,
  IApplicationConfig,
  IOpenIDConnectApplicationConfig,
  IPagedResponse,
  ITalkdeskApplicationConfig,
  IUser,
  IUserDetails,
  PublicParam,
  TalkdeskRequestBuilder,
  UserService,
  isDebugOn,
} from "../../../../core-ui";
import { IIntegrationStrategy } from "../../../../core-ui/models/strategies";
import {
  AppointmentChannelEnum,
  ConversationDestinationEnum,
} from "../../../../core-ui/core-ui.enums";
import { HttpClient } from "@angular/common/http";
import { TalkdeskUser, ITalkdeskUsersPagedResponse } from "../../ITalkdeskUser";
import { firstValueFrom } from "rxjs";
import { ApiResource } from "@auvious/common";
import {
  CLAIM_TALKDESK_ACCESS_TOKEN,
  CLAIM_TALKDESK_REFRESH_TOKEN,
  TALKDESK_API_URL,
} from "../../../app.enums";

interface ITalkdeskTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  scopes: string;
  refresh_token: string;
  sid: string;
  id_token: string;
}

export class TalkdeskIntegrationStrategy implements IIntegrationStrategy {
  securityService: ApiResource;
  refreshTokenRetries = 0;

  constructor(
    private userService: UserService,
    private httpClient: HttpClient,
    private config: IOpenIDConnectApplicationConfig,
    private rtcService: AuviousRtcService
  ) {
    this.rtcService.common$.subscribe((common) => {
      this.securityService = common.apiResourceFactory("security/talkdesk");
    });
  }

  installIntegration(integration, origin) {
    return null;
  }

  async getIntegrations() {
    return [];
  }

  getIntegrationURL() {
    return null;
  }

  deleteIntegration() {
    return null;
  }

  getIntegrationId() {
    return null;
  }

  getOauthClientId() {
    return null;
  }

  isInstalled() {
    return true;
  }

  supportsInteractionDestination(
    destination: ConversationDestinationEnum
  ): boolean {
    return [ConversationDestinationEnum.STANDALONE].includes(destination);
  }

  supportsInteractionOrigin(origin: ConversationOriginEnum): boolean {
    switch (origin) {
      // enable appointments only if we have a video license
      case ConversationOriginEnum.APPOINTMENT:
        return this.hasLicense("video");
      default:
        return [
          ConversationOriginEnum.CHAT,
          ConversationOriginEnum.WIDGET,
        ].includes(origin);
    }
  }

  supportsAppointmentChannel(channel: AppointmentChannelEnum): boolean {
    return [AppointmentChannelEnum.WEBHOOK].includes(channel);
  }

  supportsMemberRoles(): boolean {
    return true;
  }

  getIntegrationIdentifier() {
    return null;
  }

  getIntegrationUserId(user: IUser): string {
    return user.getClaim("talkdesk_user_id");
  }

  getMember(id: string): Promise<IUserDetails> {
    // todo: implement
    return null;
  }

  async getMembers(
    page: number,
    size: number
  ): Promise<IPagedResponse<IUserDetails>> {
    try {
      const request = new TalkdeskRequestBuilder()
        .page(page)
        .size(size)
        .build();

      const response = await firstValueFrom(
        this.httpClient.get<ITalkdeskUsersPagedResponse>(
          `${TALKDESK_API_URL}/users`,
          {
            headers: {
              Authorization: `Bearer ${this.userService
                .getActiveUser()
                .getClaim(CLAIM_TALKDESK_ACCESS_TOKEN)}`,
              "Content-Type": "application/json",
            },
            params: request.getParams(),
          }
        )
      );

      return {
        entities: response._embedded.users.map((m) => new TalkdeskUser(m)),
        pageCount: response.total_pages,
        pageNumber: response.page,
        pageSize: response.per_page,
        total: response.total,
      };
    } catch (ex) {
      // retry only once
      if (ex.status === 401 && this.refreshTokenRetries < 1) {
        try {
          await this.refreshToken();
          return this.getMembers(page, size);
        } catch (err) {
          throw err;
        }
      }
      throw ex;
    }
  }

  async refreshToken() {
    try {
      this.refreshTokenRetries += 1;
      const tokenResponse: ITalkdeskTokenResponse =
        await this.securityService.create(
          {
            refresh_token: this.userService
              .getActiveUser()
              .getClaim(CLAIM_TALKDESK_REFRESH_TOKEN),
          },
          {
            urlPostfix: "refresh_token",
          }
        );

      const user = this.userService.getActiveUser();
      user.setClaim(CLAIM_TALKDESK_REFRESH_TOKEN, tokenResponse.refresh_token);
      user.setClaim(CLAIM_TALKDESK_ACCESS_TOKEN, tokenResponse.access_token);
      this.userService.setActiveUser(user);
    } catch (ex) {
      throw ex;
    }
  }

  hasLicense(key: "freetrial" | "cobrowse" | "video"): boolean {
    return this.userService.getActiveUser().hasLicense(key);
  }

  getExportableConfig(config: ITalkdeskApplicationConfig): IApplicationConfig {
    try {
      const deepCopy = JSON.stringify(config);
      const cf: ITalkdeskApplicationConfig = JSON.parse(deepCopy);

      // remove params that are application specific
      delete cf.publicParameters[PublicParam.TALKDESK];
      delete cf.agentParameters[AgentParam.DIGITAL_CONNECT_ENABLED];
      delete cf.openidParameters;
      return cf;
    } catch (ex) {
      return config;
    }
  }
}
